<template>
  <section class="loader" v-show="$props.show">
      <div class="content">
        <b-spinner label="Loading..." style="width: 3rem; height: 3rem;" variant="light"></b-spinner>
        <div><label class="message">Please wait ...</label></div>
      </div>
  </section>
</template>

<script>
export default {
  name: 'LoaderComponent',
  components: {},
  props: {
    show: false,
  }
}
</script>
<style lang="scss" scoped>
  .loader{
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0,0,0,0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 9999;
    .content{
      margin:auto;
      background: transparent;
      border-radius: 10px;
      width: 150px;
      height: 80px;
      .message{
        color: #fefefe;
      }
      .spinner{
        opacity: 1;
      }
    }

  }

</style>