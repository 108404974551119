<template>
  <main class="privacy">
    <HeaderSystemico :menuActive="true" country="COLOMBIA" :lang="$props.lang" class="header_nav__active p-0" />
    <navbarSection />
    <section class="content">
      <b-container fluid="md">
        <b-row>
          <b-col>
            <h2 class="privacy__title">Términos de Uso</h2>
            <div class="privacy__line"></div>
          </b-col>
        </b-row>
        <b-row>
          <b-col>
            <h3 class="privacy__subtitle">ACEPTACIÓN DE LOS TÉRMINOS DEL CONTRATO.</h3>
            <p class="privacy__paragraph">Los siguiente son los términos de un convenio legal celebrado entre tú y SYSTEMICO. Al accesar, hojear y/o usar nuestros productos y servicios admites haber leído y entendido estos términos y estas de acuerdo en sujetarte a ellos y cumplir con todas las leyes y reglamentos aplicables. En caso que no estés de acuerdo con estos términos, por favor no uses nuestros productos o servicios. El material suministrado en este sitio está protegido por la ley, incluyendo, más no limitado a, las leyes sobre derechos de autor de Colombia. Este sitio es controlado y operado por SYSTEMICO desde sus oficinas ubicadas en Colombia. SYSTEMICO no se responsabiliza de que el material en nuestros productos o servicios sea apropiado o esté disponible para su uso en otros lugares, estando prohibido su acceso desde territorios donde su contenido sea ilegal. Aquellos que decidan accesar este sitio desde otros lugares lo harán bajo su propia iniciativa y es tu responsabilidad sujetarte a las leyes locales que sean aplicables.</p>
            <p class="privacy__paragraph privacy__paragraph--end">Cualquier reclamo con relación a, y al uso de nuestros productos o servicios y el material en ellos contenido está regulado por las leyes colombianas.</p>

            <h3 class="privacy__subtitle">RESTRICCIONES DE USO</h3>
            <p class="privacy__paragraph privacy__paragraph--end">Los derechos de autor de todo el material suministrado en este sitio son propiedad de SYSTEMICO. Exceptuando lo expresamente estipulado en este documento, nada de este material puede ser copiado, reproducido, distribuido, vuelto a publicar, desplegado, anunciado o transmitido de ninguna manera o por ningún medio, incluyendo, más no limitado a, medios electrónicos, mecánicos, de fotocopiado, de grabación o de cualquier otra índole, sin el permiso previo por escrito de SYSTEMICO. Se otorga permiso para desplegar en pantalla, copiar y distribuir el material de nuestros productos o servicios únicamente para uso personal no comercial, con la condición de que no modifiques el material y que conserves todas las leyendas de derechos de autor y de otro tipo de propiedad contenidas en este material. Este permiso termina automáticamente si violas cualquiera de estos términos y condiciones. A tu terminación, destruirás de manera inmediata cualquier material que hayas reproducido o impreso. Tampoco puedes, sin el permiso de SYSTEMICO, hacer réplicas en otro servidor de cualquier material contenido en este sitio. Cualquier uso no autorizado de cualquier material contenido en nuestros productos o servicios puede constituir una violación de las leyes de derechos de autor, de las leyes de marcas comerciales, de las leyes de privacidad y publicidad y de las leyes y los reglamentos de comunicaciones.</p>

            <h3 class="privacy__subtitle">MARCAS COMERCIALES</h3>
            <p class="privacy__paragraph privacy__paragraph--end">Las marcas comerciales, las marcas de productos y servicio, slogans y los logos usados y desplegados en nuestros productos o servicios son Marcas Comerciales registradas y no registradas de SYSTEMICO y otros. SYSTEMICO hace cumplir de manera enérgica sus derechos de propiedad intelectual en toda la extensión de la ley. El nombre de SYSTEMICO o los logos propiedad de SYSTEMICO no puede ser usados de manera alguna, incluyendo anuncios o publicidad relacionados con la distribución del material en nuestros productos o servicios, sin permiso previo por escrito. SYSTEMICO prohíbe el uso de los logos propiedad de SYSTEMICO como un enlace a cualquiera de los sitios de SYSTEMICO, a menos que el establecimiento de un enlace de tal naturaleza sea aprobado por SYSTEMICO previamente por escrito.</p>

            <h3 class="privacy__subtitle">DESCONOCIMIENTO DE GARANTÍA</h3>
            <p class="privacy__paragraph privacy__paragraph--end">SYSTEMICO no garantiza ni hace representación alguna en relación al uso, validez, precisión o confiabilidad de, o los resultados del uso de, o cualquier otra situación al respecto de, el material en nuestros productos o servicios o cualquier producto o servicio enlazado con nuestros productos o servicios.</p>

            <h3 class="privacy__subtitle">RESPONSABILIDAD LIMITADA</h3>
            <p class="privacy__paragraph privacy__paragraph--end">Bajo ninguna circunstancia, incluyendo, pero no limitada a, negligencia, será SYSTEMICO responsable por cualquier daño directo, indirecto, especial, incidental, consecuente, incluyendo, mas no limitado a, pérdida de información o utilidades, resultado del uso, o la incapacidad para usar el material en nuestros productos o servicios, aún en el caso de que SYSTEMICO o un representante autorizado de SYSTEMICO haya sido puesto al tanto de la posibilidad de tal daño. En caso de que tu uso del material de nuestros productos o servicios de como resultado la necesidad de dar servicio a, reparar o corregir equipo o información, asumes cualquier costo derivado por ello. Algunos países no permiten la exclusión o limitación de daños incidentales o consecuentes, así es que las limitaciones o exclusiones arriba mencionadas pueden no aplicar en tu caso.</p>

            <h3 class="privacy__subtitle">NUESTRAS TRANSMISIONES</h3>
            <p class="privacy__paragraph privacy__paragraph--end">Se prohíbe poner en, o transmitir a, o desde nuestros productos o servicios cualquier material ilegal, amenazador, calumniador, difamatorio, obsceno, escandaloso, pornográfico o profano, o cualquier otro material que pudiera dar lugar a cualquier responsabilidad civil o penal en los términos de la ley.</p>

            <h3 class="privacy__subtitle">REVISIONES DE LOS TÉRMINOS DE USO</h3>
            <p class="privacy__paragraph privacy__paragraph--end">SYSTEMICO puede en cualquier momento revisar estos Términos de Uso por medio de la actualización de este anuncio. Al usar nuestros productos o servicios, tu convienes en darte por obligado por cualquiera de tales revisiones, debiendo entonces visitar periódicamente esta página para determinar los términos de uso vigentes en ese momento a los cuales estás obligado.</p>
          </b-col>
        </b-row>
      </b-container>
    </section>
    <footerC/>
  </main>
</template>

<script>
import footerC from '../../../../systemico.ui/components/footers/FooterSystemicoComponent.vue'
import navbarSection from '@/components/basic/navbarSection.vue'
import HeaderSystemico from "../../../../systemico.ui/components/headers/HeaderSystemicoComponent.vue";

export default {
  name: 'terms',
  props: {
  },
  components: {
    HeaderSystemico,
    footerC,
    navbarSection
  },
  metaInfo: {
    title: 'Términos de Uso.'
  },
  props: {
    lang: String
  },
  data: function () {
    return {
    }
  },
  methods: {
  }
}
</script>

<style lang="scss" scoped>
@import '../../../../systemico.ui/variables.scss';
  .privacy {
    font-size: 1rem;
    margin-top: 3rem;
    .content{
      padding: 20px;
    }
    &__title {
      margin: 0 0 2rem;
      font-family: $font-mulish;
      font-size: 2rem;
      font-weight: 600;
      color: $color-black;
      text-align: center;
      position: relative;
      z-index: 5;
    }
    &__subtitle {
      margin: 0 0 2rem;
      font-size: 2rem;
      font-weight: 600;
      color: $color-black;
      position: relative;
      z-index: 5;
    }
    &__paragraph {
      margin: 0 0 2rem;
      font-size: 1rem;
      font-weight: normal;
      color: $color-black;
      text-align: justify;
      position: relative;
      z-index: 5;
      &--end {
        margin-bottom: 6rem;
      }
    }
    &__line {
      width: 9rem;
      height: 0.4rem;
      margin: 0 auto 6rem;
      border-radius: 0.4rem;
      background-color: $color-gray;
      display: block;
    }
  }
</style>
