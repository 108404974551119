<template>
  <div class="home pt-5">
    <BannerMultiProductComponent :items="banner_items" width country="COLOMBIA" lang="ES" />
    <products id="products" :title="productsTitle" background="#f8f8f8" textColor="#444444"  lang="es" currency="cop" country="colombia" alone="products"/>
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 320"><path fill="#f8f8f8" fill-opacity="1" d="M0,224L480,192L960,96L1440,288L1440,0L960,0L480,0L0,0Z"></path></svg>
    <products id="services" :title="servicesTitle" :description="productsDescription" background="transparent" textColor="#222222" lang="ES" currency="cop" country="colombia" alone="services" style="margin-top: -100px"/>
    <svg style="margin-top:-200px" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 320"><path fill="#f8f8f8" fill-opacity="1" d="M0,224L480,192L960,96L1440,288L1440,320L960,320L480,320L0,320Z"></path></svg>
    <BannerBenefitsComponent
          title="<b>Soñamos en grande</b>, para que nuestro país sea mejor."
          description="Hola "
          :items="benefits"
          background="#f8f8f8"
          titleColor="#222222"
          titleItemColor="#444444"
          textColor="#666666"
    />
    <rocket/>
    <clients/>
    <!--<software/>
    <ceo/>-->
    <methodology/>
    <team/>
    <allies/>
    <news />
    <testimonials lang="es"/>
    <!-- MODALS -->
    <!--<modalTranslate @changeLanguage="changeLanguage"/>-->
    <modalPopup />
  </div>
</template>

<script>
import products from '@/components/landing/products.vue'
import software from '@/components/landing/software.vue'
import services from '@/components/landing/services.vue'
import methodology from '@/components/landing/methodology.vue'
import rocket from '@/components/landing/rocket.vue'
import team from '@/components/landing/team.vue'
import BannerMultiProductComponent from '@/../../systemico.ui/components/banners/BannerMultiProductComponent.vue'
import BannerTipComponent from '@/../../systemico.ui/components/banners/BannerTipComponent.vue'
import BannerBenefitsComponent from '@/../../systemico.ui/components/banners/BannerBenefitsComponent.vue'
import ceo from '@/components/landing/ceo.vue'
import clients from '@/components/landing/clients.vue'
import news from '@/components/landing/news.vue'
import testimonials from '@/components/landing/testimonials.vue'
import allies from '@/components/landing/allies.vue'
import contact from '@/components/landing/contact.vue'
import FooterSystemicoComponent from '@/../../systemico.ui/components/footers/FooterSystemicoComponent.vue'
import modalPopup from '@/components/modals/modalPopup.vue'
import social from '@/../../systemico.ui/components/social.vue'
import HeaderSystemicoComponent from "../../../systemico.ui/components/headers/HeaderSystemicoComponent.vue";

export default {
  name: 'Home_ES_CO',
  data: function () {
    return {
      headerFixed: false,
      banner_items: [
        {
          id: 1,
          title: '<span style="color: #222222;">Soluciones que impulsan tu negocio</span>',
          subtitle: 'Chatbots con <strong>WhatsApp, Telegram, Messenger y SMS</strong> para automatizar tus procesos de negocio.',
          note: 'El futuro de tu negocio empieza con el <strong>software correcto</strong>. <em>¡Atrévete a mejorar!</em>',
          image: 'https://cdn.systemico.net/systemico/web/headers/images/header_image_8.svg',
          backgroundImage: '',
          buttons: [
            { id: 1, text: 'Catálogo de Productos', variant: 'orange', link: '#products', icon:'patch-check-fill' }
          ],
          show: true
        },
        {
          id: 2,
          title: '<span style="color: #222222;">Software de PQRS <br />para tus clientes</span>',
          subtitle: '<strong><b>¡Empieza Hoy!</b>, <em>simplifica tu operación</em> con <i> software para asegurar las solicitudes de tus clientes.</i>.',
          note: 'El futuro de tu negocio empieza con el <strong>aliado correcto</strong>. <em>¡Atrévete a mejorar!</em>',
          image: 'https://cdn.systemico.net/systemico/web/headers/images/header_image_4.svg',
          backgroundImage: '',
          buttons: [
            { id: 1, text: 'Prueba gratuita', variant: 'warning', link: '/products/suppmail', icon:'arrow-right', iconPosition: 'end' },
          ],
          show: true
        },
        {
          id: 3,
          title: '<span style="color: #222222;">Bots a la medida de tus necesidades</span>',
          subtitle: '<em>Transforma tu operación</em> con <strong>software diseñado para simplificar la operación de tu pyme</strong>.',
          note: 'El futuro de tu negocio empieza con el <strong>aliado correcto</strong>. <em>¡Atrévete a mejorar!</em>',
          image: 'https://cdn.systemico.net/systemico/web/headers/images/header_image_11.svg',
          backgroundImage: '',
          buttons: [
            { id: 1, text: 'Desarrollo de Bots', variant: 'orange', link: '/development/bots', icon:'code-square' }
          ],
          show: true
        },
        {
          id: 4,
          title: '<span style="color: #222222;">Integra tus aplicaciones y consolida tus datos</span>',
          subtitle: 'Simplifica la integración de datos implementando servicios <b>API-REST</b>. ¡Genera fluidez en tu negocio!.',
          note: 'El futuro de tu negocio empieza con el <strong>aliado correcto</strong>. <em>¡Atrévete a mejorar!</em>',
          image: 'https://cdn.systemico.net/systemico/web/headers/images/header_image_1.svg',
          backgroundImage: '',
          buttons: [
            { id: 1, text: 'Dashboards a tu medida', variant: 'orange', link: '/development/api', icon:'code-square' }
          ],
          show: true
        },
        {
          id: 5,
          title: '<span style="color: #222222;">Data en tiempo, mejores decisiones</span>',
          subtitle: '<strong><b>¡Usa tus datos!</b>, <em>define dashboards</em> con la información clave para tu negocio y mejora tus resultados.',
          note: 'El futuro de tu negocio empieza con el <strong>aliado correcto</strong>. <em>¡Atrévete a mejorar!</em>',
          image: 'https://cdn.systemico.net/systemico/web/headers/images/header_image_1.svg',
          backgroundImage: '',
          buttons: [
            { id: 1, text: 'Dashboards a tu medida', variant: 'orange', link: '/development/dashboards', icon:'code-square' }
          ],
          show: true
        }
      ],
      productsTitle: {
        ES: 'Catálogo de Productos',
        EN: 'Products Portfolio'
      },
      productsDescription: {
        ES: 'Ofrecemos paquetes por horas para desarrollo gradual de tus productos o servicios basados en software.',
        EN: 'We offer hourly packages for the gradual development of your software-based products or services.'
      },
      servicesTitle: {
        ES: 'Servicios de Desarrollo a Medida',
        EN: 'Custom Development Services'
      },
      benefits: [
        { id: 1, title: 'Soporte Permanente', description: 'Equipo experto que garantiza el cumplimiento de las metas del servicio.', icon: 'https://cdn.systemico.net/systemico/icons/icon--soporte.svg' },
        { id: 2, title: 'Experiencia', description: '17 años en el mercado con aprendizajes que fortalecieron nuestro enfoque, resiliencia y visión.', icon: 'https://cdn.systemico.net/systemico/icons/icon-code.svg' },
        { id: 3, title: 'Empatía', description: 'Orgullosamente Colombianos que vive y enfrentan los retos de nuestro entorno y economía.', icon: 'https://cdn.systemico.net/systemico/icons/icon--cool.svg' },
        { id: 4, title: 'Confianza', description: 'Clientes y aliados que garantizan nuestra experiencia y motivación.', icon: 'https://cdn.systemico.net/systemico/icons/icon-check--orange.svg' }
      ]
    }
  },
  components: {
    HeaderSystemicoComponent,
    BannerBenefitsComponent,
    BannerMultiProductComponent,
    products,
    software,
    methodology,
    rocket,
    team,
    ceo,
    clients,
    news,
    testimonials,
    allies,
    contact,
    FooterSystemicoComponent,
    BannerTipComponent,
    modalPopup,
    social
  },
  beforeMount () {
    this.$emit('changeLang', 'ES')
    this.$emit('changeCountry', 'COLOMBIA')
    this.$emit('showCountry', true)
  },
  methods: {
    headerReact: function () {
      /** const header = document.getElementsByClassName('headerUi')
      if (event.target.scrollTop > 0 && this.headerFixed === false) {
        header[0].classList.remove('headerUi--show')
        header[0].classList.add('headerUi--active')
        this.headerFixed = true
      } else if (this.headerFixed === true && event.target.scrollTop === 0) {
        header[0].classList.remove('headerUi--active')
        header[0].classList.add('headerUi--show')
        this.headerFixed = false
      }**/

      // TODO: let stateLocalStorage = localStorage.getItem("modalWePower")

      // console.log(event.target.scrollTop)

      // if (event.target.scrollTop > 2620 && window.screen.width > 768 && stateLocalStorage == null) {
      //   this.$store.commit("setModalWePower", { state: true });
      // }

      // if (event.target.scrollTop > 5200 && window.screen.width < 768 && stateLocalStorage == null) {
      //   this.$store.commit("setModalWePower", { state: true });
      // }
    },
    typing: function () {
      //this.$refs.banner.traduccion()
    },
    changeLanguage: function (idioma) {
      //this.$refs.header.changeLanguage(idioma)
    }
  }
}
</script>
<style>
@import "../../../systemico.ui/basic.scss";
</style>
