<template>
  <b-container fluid :class="'deck variant-'+$props.variant">
    <b-container class="content pt-3">
      <h1 class="title" v-html="$props.title"></h1>
      <h4 class="description" v-html="$props.description"></h4>
      <b-row>
        <b-col class="p-2" md="3" v-for="item in $props.items" :key="item.id">
          <b-card
            :title="item.title"
            :img-src="item.image"
            img-alt="Image"
            img-top
            tag="article"
            class="item m-2"
          >
            <b-card-text v-html="item.description"></b-card-text>
            <div class="pt-3 pb-3">
              <ButtonComponent :text="item.button"
                               :href="item.link"
                               :variant="buttonVariant"
                               :icon="item.buttonIcon"
                               iconPosition="end"
                               size="md"
                               class="font-weight-bold"
              />
            </div>
          </b-card>
        </b-col>
      </b-row>
    </b-container>
  </b-container>
</template>
<script>
import ButtonComponent from '../buttons/ButtonComponent.vue'

export default {
  name: 'DeckComponent',
  components: { ButtonComponent },
  props: {
    title: String,
    textColor: {
      type: String,
      default: '#fefefe'
    },
    description: String,
    items: Array,
    variant: String,
    buttonIcon: String,
    buttonVariant: {
      type: String,
      default: 'secondary'
    }
  }
}
</script>
<style lang="scss" scoped>
  @import "../../basic.scss";
  .variant-dark{
      background:$color-black;
  }
  .variant-blue{
    background:$color-blue--dark;
  }
  .deck{
    font-size: 2px;
    .content{
      max-width:1280px;
      .title{
        color: v-bind(textColor);
        font-size:2rem;
        text-align: center;
        margin-top:1rem;
        margin-bottom:1rem;
      }
      .description{
        color: v-bind(textColor);
        font-size:1.5rem;
        text-align: center;
        font-weight:lighter;
      }
      .item{
        border-radius: 0.5rem;
        overflow: hidden;
        .card-title{
          font-size:1.2rem;
          color:$color-blue--dark;
          margin-bottom:0.5rem;
        }
        .card-body{
          padding:0.8rem;
          font-size:0.9rem;
        }
        .card-text{
          min-height: 5rem;
        }
      }
    }
  }
</style>
