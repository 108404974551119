<template>
  <main class="section">
    <!-- seccion 1 -->
    <section class="requirements requirements--dark mt-5 pt-5">
      <b-container fluid="lg">
        <b-row>
          <b-col>
            <titleSection :content="content" />
          </b-col>
        </b-row>
        <b-row>
          <b-col cols="12" md=""></b-col>
          <b-col cols="12" md="12">
            <div class="requirements__title requirements__title--white align-items-end">
              <img :src="$cdn + 'icons/target.svg'" alt="icon card" />
              <h2>SMS - Mensajes de Texto.</h2>
            </div>
            <p class="requirements__subtitle">
              Remite mensajes de texto de manera sencilla y organizada para incrementar el impacto en tus metas de negocio.
            </p>
          </b-col>
          <b-col cols="12" md=""></b-col>
        </b-row>
        <b-row>
          <b-col>
            <div class="requirements__description">
              <b>Acelera tu negocio utilizando los mensajes de textos masivos</b> como una técnica apropiada de marketing y fidelización de clientes.
              Notifica oportunidades, descuentos, citas, códigos de acceso, accesos a plataformas y mucho más.
            </div>
          </b-col>
        </b-row>
      </b-container>
    </section>
    <!-- index -->
    <div class="content__index">
      <a href="#Description">Descripción</a>
      <a href="#Price">Precios</a>
      <a href="#Characteristic">Características</a>
    </div>
    <!-- seccion 2 -->
    <div class="requirements pb-0 requirements--grey" id="Description">
      <b-container fluid="lg">
        <b-row>
          <b-col cols="12" md="6" lg="4">
            <div class="requirements__card">
              <h3>Plataforma en la nube</h3>
              <p>
                <b>Systemico</b> te suministra un servicio de alta calidad para enviar mensajes de texto masivos e inmediatos a todo destino,
                que pueden ser utilizados para realizar campañas de marketing o notificar de novedades a tus interesados.
                El servicio detalla los costos, el consumo y el estado de los mensajes para dar claridad y transparencia de su uso.
              </p>
              <a href="#Technologies" target="_blank" rel="noopener noreferrer"
                >Abrir mi cuenta ></a
              >
            </div>
            <div class="requirements__card">
              <h3>Actualizaciones permanentes</h3>
              <p>
                Cada 15 días se realizan actualizaciones del servicio para mejorar la cobertura,
                solucionar bugs y brindarte siempre el mejor servicio.
              </p>
            </div>
          </b-col>
          <b-col cols="12" md="6" lg="4">
            <div class="requirements__card">
              <h3>Soporte personalizado</h3>
              <p>
                Puedes solicitar apoyo cuando lo necesites y hacer comentarios a través del botón <b>Recomendaciones</b>.
                El soporte es sorprendentemente ágil y tus propuestas de mejora serán tenidas en cuenta en cada
                iteración quincenal del producto.
              </p>
            </div>
            <div class="requirements__slogan">
              <p>
                Productos y servicios innovadores enfocados en simplificar tu gestión empresarial.
              </p>
            </div>
          </b-col>
          <b-col cols="12" md="6" lg="4">
            <div class="requirements__card">
              <h3>Contactos Actualizados</h3>
              <p>
                La plataforma te permite administrar de manera optima tus contactos para mantener la información actualizada en todo momento.
                Remite mensajes de texto directos o en grupos según tu necesidad.
              </p>
              <a
                href="https://wabot.systemico.net"
                target="_blank"
                rel="noopener noreferrer"
                >Iniciar una prueba ></a
              >
            </div>
            <div class="requirements__card">
              <h3>API para integraciones</h3>
              <p>
                Puedes integrar tus productos actuales de manera directa a un API, para acelerar la remisión de notificaciones y controlar tu gasto.
              </p>
            </div>
            <div class="requirements__card">
              <h3>Presupuesto</h3>
              <p>
                Al alcance de tu bolsillo, acumulable y recargable según tu necesidad.
                Establece un presupuesto claro y detallado, alineado a tu bolsillo.
              </p>
            </div>
          </b-col>
        </b-row>
      </b-container>
    </div>
    <!-- seccion 3 -->
    <section class="content content--gray" id="Price">
      <b-container fluid="lg">
        <b-row>
          <b-col>
            <b-table-simple class="content__simple__table" responsive>
              <b-thead>
                <b-tr>
                  <b-th>País</b-th>
                  <b-th class="text-center">Valor por SMS (Mensaje de Texto)</b-th>
                  <b-th class="text-center">Uso</b-th>
                </b-tr>
              </b-thead>
              <b-tbody>
                <b-tr>
                  <b-td>
                    <div>
                      <h3>Colombia</h3>
                      <p>
                        Remite notificaciones masivas en Colombia sin importar el operador.
                      </p>
                    </div>
                  </b-td>
                  <b-td>
                    <p class="text-center">22 COP</p>
                  </b-td>
                  <b-td>
                    <p class="text-center">Marketing / Trasaccional</p>
                  </b-td>
                </b-tr>
                <b-tr>
                  <b-td>
                    <div>
                      <h3>Estados Unidos</h3>
                    </div>
                  </b-td>
                  <b-td>
                    <p class="text-center">0.0090 USD</p>
                  </b-td>
                  <b-td>
                    <p class="text-center">Marketing / Transaccional</p>
                  </b-td>
                </b-tr>
                <b-tr>
                  <b-td>
                    <div>
                      <h3>Canadá</h3>
                    </div>
                  </b-td>
                  <b-td>
                    <p class="text-center">0.0090 USD</p>
                  </b-td>
                  <b-td>
                    <p class="text-center">Marketing / Transaccional</p>
                  </b-td>
                </b-tr>
              </b-tbody>
            </b-table-simple>

            <buttonPrimry
              class="mx-auto margin-top-5 margin-bottom-5"
              :configButton="configButton2"
              @funcionBtn="funcioRedirect('/login')"
            />
          </b-col>
        </b-row>
        <b-row>
          <b-col cols="12" md="6">
            <div class="content__card content__card--white">
              <p>
                Si necesitas apoyo inicial o acompañamiento especializado puedes
                detallar tus necesidades claramente en nuestro módulo de *Definición Requerimientos*.
              </p>
              <a href="/requirements/" rel="noopener noreferrer"
                >Definir necesidades ></a
              >
            </div>
          </b-col>
          <b-col cols="12" md="6">
            <div class="content__card content__card--white">
              <p>
              Recibe acompañamiento permanente para sacar el máximo provecho para tu negocio.
              </p>
              <a href="/service_support" rel="noopener noreferrer"
                >Soporte por demanda ></a
              >
            </div>
          </b-col>
        </b-row>
      </b-container>

    </section>
    <!-- seccion 4 -->
    <section class="support" id="Characteristic">
      <b-container fluid="lg">
        <b-row>
          <b-col cols="12">
            <div class="support__title w-100 justify-content-center">
              <h2>Características</h2>
            </div>
          </b-col>
        </b-row>
        <b-row>
          <b-col cols="12" md="4">
            <div class="support__card mt-5">
              <div class="icon">
                <img :src="$cdn + 'icons/icon-rocket--orange.svg'" alt="icon card" />
              </div>
              <h3 class="text-center">Capacidad de envio</h3>
              <p>
                Te permitimos enviar <b>hasta 100.000 SMS</b> en cada campaña.
              </p>
            </div>
          </b-col>
          <b-col cols="12" md="4">
            <div class="support__card mt-5">
              <div class="icon">
                <img :src="$cdn + 'icons/icon-check--orange.svg'" alt="icon card" />
              </div>
              <h3 class="text-center">Verificación de entrega</h3>
              <p>
                Evidencia la recepción de los mensajes para entender su impacto.
              </p>
            </div>
          </b-col>
          <b-col cols="12" md="4">
            <div class="support__card mt-5">
              <div class="icon">
                <img :src="$cdn + 'icons/icon-person.svg'" alt="icon card" />
              </div>
              <h3 class="text-center">Diseño Intuitivo</h3>
              <p>
                Interfaz agradable, clara y fácil de utilizar.
              </p>
            </div>
          </b-col>
          <b-col cols="12" md="4">
            <div class="support__card mt-5">
              <div class="icon">
                <img :src="$cdn + 'icons/icon__line-4.svg'" alt="icon card" />
              </div>
              <h3 class="text-center">Ahorra tiempo y dinero</h3>
              <p>
                Implementa desarrollos tecnológicos, disminuyendo hasta un 40%
                los gastos de tu empresa.
              </p>
            </div>
          </b-col>
          <b-col cols="12" md="4">
            <div class="support__card mt-5">
              <div class="icon">
                <img :src="$cdn + 'icons/icon__line-5.svg'" alt="icon card" />
              </div>
              <h3 class="text-center">Soluciona cuellos de botella</h3>
              <p>
                Simplifica las comunicaciones con tus clientes y genera fidelización a partir de SMS.
              </p>
            </div>
          </b-col>
          <b-col cols="12" md="4">
            <div class="support__card mt-5">
              <div class="icon">
                <img :src="$cdn + 'icons/icon__line-2.svg'" alt="icon card" />
              </div>
              <h3 class="text-center">Vanguardia tecnológica</h3>
              <p>
                Mejoramos procesos, implementando nuevas herramientas dentro de
                la compañía.
              </p>
            </div>
          </b-col>
        </b-row>
      </b-container>
    </section>
    <!-- seccion 5 -->
    <section class="content p-0">
      <div class="content__slogan">
        <b-container>
          <b-row>
            <b-col>
              <p class="m-0">
                Systemico cuenta con un equipo que evalúa, analiza y cuida cada
                detalle del servicio, con actualizaciones permanentes enfocadas en
                mejorar y asegurar tu experiencia.
              </p>
            </b-col>
          </b-row>
        </b-container>
      </div>
    </section>

    <!-- seccion 7 -->
    <section class="requirements requirements--dark">
      <b-container fluid="lg">
        <b-row>
          <b-col cols="12" md=""></b-col>
          <b-col cols="12" md="10" lg="8">
            <div class="requirements__develop">
              <h2>Programar el envio!</h2>
              <p>
                Establece fechas para el envio de los mensajes, permitiendo programar alertas, notificaciones y
                recordatorios acorde a tu necesidad. Inicia hoy y recibe apoyo grtuito para la integración de tu sistema.
              </p>
              <div class="opcions">
                <template v-for="(item, index) in buttons">
                  <buttonPrimry
                    :configButton="item"
                    :key="index"
                    @funcionBtn="funcioRedirect(item.link)"
                  />
                </template>
              </div>
            </div>
          </b-col>
          <b-col cols="12" md=""></b-col>
        </b-row>
      </b-container>
    </section>
  </main>
</template>

<script>
import headerC from '../../../../../../systemico.ui/components/header.vue'
import titleSection from "@/components/ui/titleSection";
import buttonPrimry from "@/components/basic/buttonBasic.vue";
import footerC from '../../../../../../systemico.ui/components/footer.vue'

import technologies from "@/components/landing/technologies.vue";

export default {
  name: "sms",
  components: {
    titleSection,
    buttonPrimry
  },
  metaInfo: {
    title: "SMS: Mensajes de texto masivos para transacciones o marketing.",
  },
  data: function () {
    return {
      content: {
        title: "Servicios",
        subtitle: "/ SMS - Mensajes de texto masivos",
      },
      buttons: [
        {
          text: "Empezar hoy",
          class: "button--secundary",
          link: "https://wabot.systemico.net",
        }
      ],
      configButton2: {
        text: "Abrir Cuenta",
        class: "button--secundary",
      },
    };
  },
  methods: {
    funcioRedirect: function (link) {
      window.location = link;
    },
  },
};
</script>
