<template>
  <main class="section">
    <!-- SECCIÓN 1 -->
    <section class="content mt-5 pt-5">
      <titleSection :content='content'/>
			<b-container fluid='lg' class="mt-5">
				<b-row>
					<b-col cols='12' md=''>
                        <h2 class="content__title content__title--celeste mb-5">Juntos lo hacemos posible</h2>
						<p class="content__subtitle">Somos profesionales trabajando en completa sinergia desde diferentes disciplinas tales como: Ingenieros de Sistemas, Desarrolladores, Diseñadores UI/UX, Líderes de proyecto, Marketing y Ventas.</p>
					</b-col>
					<b-col cols='12' md=''>
						<div class="content__img">
							<img class="ml-auto mr-5" :src="$cdn + 'images/systemico-figure.svg'" alt="systemico figure">
						</div>
					</b-col>
				</b-row>
			</b-container>
		</section>
		<!-- SECCIÓN 2 -->
    <section class="content content--gray">
			<b-container fluid=lg>
				<b-row>
					<b-col>
						<p class="content__description text-center mt-5">Conoce nuestro Equipo</p>
					</b-col>
				</b-row>
				<b-row class="mt-5">
					<template v-for='(item, index) in persons'>
						<b-col cols='12' md='3' :key='index'>
							<div class="content__persons">
								<div class="image">
									<img v-if="item.img !== 'Img-equipo-woman.svg'" :src="$cdn + 'team/' + item.img" alt="picture person">
									<img v-else class="icon-default" :src="$cdn + 'team/' + item.img" alt="picture person">
								</div>
								<div class="name">
									<h3>{{ item.name }}</h3>
									<h4>{{ item.rol }}</h4>
								</div>
							</div>
						</b-col>
					</template>
				</b-row>
			</b-container>
		</section>
		<!-- SECCIÓN 3 -->
    <section class="content p-0">
			<p class="content__slogan">Nos encantan los retos y estamos dispuestos a aceptarlos.</p>
		</section>
		<!-- SECCIÓN 4 -->
		<section class="content content--blue">
			<b-container fluid=lg>
				<b-row>
					<b-col cols='12' md=''></b-col>
					<b-col cols='12' md='10' lg='8'>
						<div class="content__develop">
							<h2>Desarrollamos el crecimiento de tu empresa!</h2>
							<p>Systemico es un aliado estratégico enfocado en hacer las cosas simples y efectivas, que a cada paso se agregue valor y que la inversión se ejecute lo más eficientemente posible.</p>
							<div class="opcions">
								<template v-for='(item, index) in buttons'>
									<buttonPrimry :configButton='item' :key='index' @funcionBtn="funcionBtn(item.click)"/>
								</template>
							</div>
						</div>
					</b-col>
					<b-col cols='12' md=''></b-col>
				</b-row>
			</b-container>
		</section>
	</main>
</template>

<script>
import titleSection from '@/components/ui/titleSection'
import buttonPrimry from '@/components/basic/buttonBasic.vue'

export default {
    name: "team",
    components: {
      titleSection,
      buttonPrimry
	},
	metaInfo: {
		title: 'Systemico: Equipo de trabajo.'
	},
  props: {
    lang: String
  },
	data: function () {
		return {
			content: {
				title: 'Compañía',
				subtitle: '/ Conoce nuestro Equipo'
			},
			buttons: [
				{ text: 'Cotizar proyecto', class: 'button--secundary', click: 'sinContenido' },
				{ text: 'Solicitar servicio', class: 'button--secundary', click: 'sinContenido' },
				{ text: 'Contáctanos', class: 'button--secundary', click: 'sinContenido' }
			],
			persons: [
				{ img: 'Img-equipo-edwin-ariza.png', name: 'Edwin Ariza', rol: 'CEO & CIO & Co-Founder - Chief Executive Office', description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, a sed do eiusmod temporedin incididunt ut labore et dolore magna aliqua.', link: 'https://www.linkedin.com/in/edwinariza/' },
				{ img: 'Img-equipo-jaime-gonzalez.png', name: 'Jaime Gonzalez', rol: 'CTO & COO & Co-Founder - Chief Technological Officer', description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, a sed do eiusmod temporedin incididunt ut labore et dolore magna aliqua.', link: 'https://www.linkedin.com/in/jaimeaga/' },
				{ img: 'Img-equipo-claudia-jimenez.png', name: 'Claudia Jiménez', rol: 'CAO - Chief Executive Officer', description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, a sed do eiusmod temporedin incididunt ut labore et dolore magna aliqua.', link: 'https://www.linkedin.com/in/claudia-patricia-jimenez-jaramillo/' },
				{ img: 'Img-equipo-woman.svg', name: 'Sandra Orozco', rol: 'CCO', description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, a sed do eiusmod temporedin incididunt ut labore et dolore magna aliqua.', link: 'https://www.linkedin.com/in/sandra-orozco-03231157/' },
				{ img: 'Img-equipo-manuel-malagon.png', name: 'Manuel Malagon', rol: 'Design UI & UX Leader', description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, a sed do eiusmod temporedin incididunt ut labore et dolore magna aliqua.', link: 'https://www.linkedin.com/in/manuelmalagon/' },
				{ img: 'Img-equipo-fabian-leon.png', name: 'Fabian León', rol: 'Program Manager', description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, a sed do eiusmod temporedin incididunt ut labore et dolore magna aliqua.', link: 'https://www.linkedin.com/in/fabianleoni/' },
				{ img: 'Img-equipo-jose-nadin.png', name: 'Jose Herrara', rol: 'Back-end Developer', description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, a sed do eiusmod temporedin incididunt ut labore et dolore magna aliqua.', link: 'linkedin.com/in/jose-nadin-herrera-60b182127/' },
				{ img: 'Img-equipo-manuel-medrano.png', name: 'Manuel Medrano', rol: 'Help & Support', description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, a sed do eiusmod temporedin incididunt ut labore et dolore magna aliqua.', link: 'https://www.linkedin.com/in/manuel-medrano-garcia-a7a98222a/' },
				{ img: 'Img-equipo-carlos-rueda.png', name: 'Carlos Rueda', rol: 'Front-end Developer', description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, a sed do eiusmod temporedin incididunt ut labore et dolore magna aliqua.', link: 'https://www.linkedin.com/in/carlosruedam/' },
				{ img: 'Img-equipo-marlon-garcia.png', name: 'Marlon Garcia', rol: 'Front-end Developer', description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, a sed do eiusmod temporedin incididunt ut labore et dolore magna aliqua.', link: 'https://www.linkedin.com/in/marlon-fabian-garc%C3%ADa-mendoza-280449218/' }
			]
		}
	},
	methods: {
	}
};
</script>
