<template>
  <section class="popup" v-if="state">
    <div class="content">
      <div class="container-fluid">
        <button class="btn-close"  v-on:click="cerrarModal()">
          <b-icon icon="x"></b-icon>
        </button>
        <h2 v-html="title" style="font-weight: bold;"></h2>
        <p v-html="message"></p>
        <div class="options">
          <div class="container-fluid w-100"><ButtonComponent icon="plus" :text="labelButton" variant="secondary" :href="url_target"/></div>
          <div class="container-fluid mt-2 text-muted note">Automatic popup with <a href="https://gogoals.systemico.net">Gogoals</a> simple and automatic marketing.</div>
        </div>
      </div>
    </div>

  </section>
</template>

<script>
import buttonPrimry from '@/components/basic/buttonBasic.vue'
import axios from "axios";
import ButtonComponent from '../../../../systemico.ui/components/buttons/ButtonComponent.vue';

export default {
  name: "modalPopup",
  components: {
    buttonPrimry,
    ButtonComponent
  },

  data: function () {
    return {
      title: '¿Listo para iniciar tu Startup?',
      message: 'Te ayudamos a desarrollar o sostener tu producto de software.',
      labelButton: '',
      sespop:'',
      idioma:'',
      idiomaM:'',
      url_target: '/service_developing#Price'
    };
  },

  mounted() {
    if (localStorage.getItem('consent') === "true") {
      setTimeout(() => {
      let date = new Date()
      this.sespop = localStorage.getItem('idnoty')
      this.idioma = localStorage.getItem('userLanguage').toUpperCase()
      if (this.sespop != null) {
        this.cargarmsg()
      } else {
        this.cargarmsg()
      }
      /*if (localStorage.getItem('timePopup')) {
          let timePopup = new Date(localStorage.getItem('timePopup'));

          if (date.getTime() > timePopup.getTime()) {
            date.setHours(date.getHours() + 24);
            localStorage.setItem('timePopup', date);
            this.showPopup();
          } else {
            this.cerrarModal();
          }
        } else {
          date.setHours(date.getHours() + 1);
          localStorage.setItem('timePopup', date);
          this.showPopup();
        }*/
      /*if(sespop!=null && sespop==true){
        this.cerrarModal()
      }else{

      }*/
    }, 180000)
    }
  },

  computed: {
    state(){
      let data = this.$store.getters.getPopup;
      return data.state;
    }
  },

  methods: {
    cerrarModal: function () {
      localStorage.setItem('cerradoPopup', true)
      this.$store.commit("setPopup", { state: false })
    },
    cargarmsg: function () {
      axios.post('/../marketing/marketing_get_notificacion_pub',{tipo: 5}).then(response => {
        let data = response.data
        this.title = data.DATA.titulo
        this.message = data.DATA.contenido
        this.idiomaM = data.DATA.idioma
        this.url_target = data.DATA.url_target
        this.labelButton = data.DATA.label_button

        if(this.sespop != "popup_"+this.idioma+"_"+data.DATA.id && this.idioma == this.idiomaM ){
          this.showPopup()
          localStorage.setItem('idnoty',"popup_"+this.idioma+"_"+data.DATA.id)
        }
      })
    },

    showPopup: function () {
      this.$store.commit("setPopup", { state: true });
    },

    funcioRedirect: function (link) {
      window.location=link
    },
    //comentario final
  }
};
</script>
<style lang="scss">
@import "../../../../systemico.ui/basic.scss";
.popup {
    width: 100%;
    display: flex;
    justify-content: left;
    position: fixed;
    bottom: 0;
    z-index: 1010;
    pointer-events: none;
    margin:2rem;

    .content {
        width: 30rem;
        max-width: 30rem;
        height: 16rem;
        padding: 0.5rem;
        border-radius: 0.8rem;
        background-color: #ffffff;
        box-shadow: 10px 10px 20px #4D576F26;
        display: flex;
        align-items: center;
        justify-content: center;
        position: relative;
        pointer-events: all;
        border:2px $color-gray--light solid;

        h2 {
            margin: 1rem;
            font-family: $font-quicksand;
            font-size: 1.3rem;
            color: $color-celeste;
            font-weight: normal;
            text-align: center;
        }

        p {
            margin: 1.2rem;
            font-family: $font-quicksand;
            font-size: 1.2rem;
            color: $color-black--light;
            font-weight: normal;
            text-align: center;
        }
        .note{
          font-size: 0.6rem;
          text-align: center;;
          margin-top: 1rem;
          line-height: 1.5rem;
        }
        button {
            min-width: 17rem;
            margin: 0;
        }

        .btn-close {
            width: 3rem;
            min-width: 3rem;
            height: 3rem;
            border: 0;
            border-radius: 50%;
            background: transparent;
            display: flex;
            align-items: center;
            justify-content: center;
            position: absolute;
            top: 1rem;
            right: 1rem;

            svg {
                font-size: 2rem;
                color: $color-celeste;
            }
        }

        .options {
            text-align:center;
            align-items: center;
            justify-content: center;
        }
    }

     // responsive para dispositivos de tablets en adelante
     @media (max-width: 768px) {
        .content {
            .rocket__icon {
                display: none;
            }

            .options {
                display: block;

                button {
                    margin: 0 auto 2rem;
                }
            }
        }
    }
}
</style>
