<template>
    <section class="rocket" id="rocket">
        <b-container fluid='xl'>
            <b-row>
                <b-col>
                    <img class="rocket__icon" :src="$cdn + 'icons/icon-rocket--white.svg'" alt="icon rocket">
                    <p class="rocket__description">
                        {{ description[$props.lang] }}
                    </p>
                </b-col>
            </b-row>
            <b-row class="rocket__counters">
                <template v-for="(item, index) in items">
                    <b-col cols='12' md='6' lg='3' :key="index">
                        <div class="rocket__counter">
                            <span>
                                {{ item.number }}
                            </span>
                            <h3>
                                {{ item.text[$props.lang] }}
                            </h3>
                        </div>
                    </b-col>
                </template>
            </b-row>
        </b-container>
    </section>
</template>

<script>

export default {
  name: 'rocket',
  props: {
    lang: String,
    background: {
      type: String,
      default: '#222222'
    }
  },
  beforeMount () {
    if(this.lang === undefined){
      this.lang='es'
    }
  },
  data: function () {
    return {
      description: {
        es: '¡Deja todo en nuestras manos mientras sigues enfocándote en tu negocio!',
        en: 'Share your dreams with our team to start to work and grow with your business, sales and clients!'
      },

      items: [
        { number: '+200', text: {es: 'Proyectos éxitosos', en: 'Successful projects'} },
        { number: '+15', text: {es: 'Años de experiencia', en:'Years of experience'} },
        { number: '+10M', text: {es: 'Horas de programación', en: 'Coding hours' } },
        { number: '+12', text: {es: 'Bots atendiendo', en:'Running bots' } }
      ],
    }
  }
}
</script>
<style lang="scss">
@import '../../assets/styles/variables.scss';
.rocket {
    padding: 2rem 0;
    background-color: v-bind(background);

    &__icon {
        width: 3rem;
        margin: 0 auto 2rem;
        display: block;
        animation: rocket 0.5s ease infinite;
    }

    &__description {
        margin: 0;
        font-size: 1.5rem;
        font-weight: 500;
        font-style: italic;
        color: $color-white;
        line-height: 2.8rem;
        text-align: center;
    }

    &__counters {
        margin-top: 2rem;
    }

    &__counter {
        width: 100%;
        height: calc(100% - 2rem);
        margin-bottom: 1rem;
        padding: 1rem 3rem;
        border-radius: 0 1rem;
        background-color: $color-white;
        display: flex;
        align-items: center;
        justify-content: center;

        span {
            margin: 0 1.5rem 0 0;
            font-family: $font-quicksand;
            font-size: 2.4rem;
            font-weight: bold;
            color: $color-orange;
            line-height: 3rem;
            text-align: left;
        }

        h3 {
            margin: 0;
            font-size: 1.2rem;
            font-weight: 500;
            color: $color-black;
            line-height: 1.4rem;
            text-align: left;
        }
    }

    // responsive para dispositivos de tablets en adelante
    @media (min-width: $breakpoint-tablet) {
        padding: 3rem 0 3rem;
    }
}

@keyframes rocket
{
    0%,100%
    {
        transform: translateY(-0.2rem);
    }
    50%
    {
        transform: translateY(0.2rem);
    }
}
</style>
