<template>
  <div class="home">
    <BannerMultiProductComponent
      :items="banner_items[$props.lang]"
      width
      country="COLOMBIA"
      lang="ES"
      class="mt-5"
    />
    <div class="intro">
      <h1 class="sublogo">
         {{ title[$props.lang] }}
      </h1>
      <!--<ContactComponent :lang="$props.lang"/>-->
    </div>
    <b-row class="contact_block mb-5">
      <b-col md="4" class="text-center">
        <h3 class="header">Colombia</h3>
        <!-- CONTACT INFORMATION LIST -->
         <p class="information">
           Email: <a href="mailto:hello@systemico.net">hola@systemico.co</a>
         </p>
        <ButtonContactComponent :text="contact_button[$props.lang]" icon="chat-fill" variant="warning" />
      </b-col>
      <b-col md="4" class="text-center">
         <h3 class="header">United States</h3>
        <!-- CONTACT INFORMATION LIST -->
         <p class="information">
           Email: <a href="mailto:hello@systemico.net">hello@systemico.net</a>
         </p>
        <ButtonContactComponent :text="contact_button[$props.lang]" icon="chat-fill" variant="primary" />
      </b-col>
      <b-col md="4" class="text-center">
         <h3 class="header">Canada</h3>
         <!-- CONTACT INFORMATION LIST -->
         <p class="information">
           Email: <a href="mailto:hello@systemico.net">hello@systemico.net</a>
         </p>
        <ButtonContactComponent :text="contact_button[$props.lang]" icon="chat-fill" variant="danger" />
      </b-col>
    </b-row>
  </div>
</template>

<script>
import products from '@/components/landing/products.vue'
import ContactComponent from '@/../../systemico.ui/components/contacts/ContactComponent.vue'
import BannerMultiProductComponent from "../../../systemico.ui/components/banners/BannerMultiProductComponent.vue";
import ButtonContactComponent from "../../../systemico.ui/components/buttons/ButtonContactComponent.vue";

export default {
  name: 'Contact',
  props: {
    lang: String
  },
  data: function () {
    return {
      title: {
        ES: 'Utiliza nuestro canales de atención.',
        EN: 'Available channels for your happiness.',
      },
      availableLangs: [
        {id:1, lang: 'EN', name: 'English', path:'./'},
        {id:2, lang: 'ES', name: 'Español', path:'./es'},
        {id:3, lang: 'FR', name: 'Francais', path:'./fr'}
      ],
      contact_button: {
        ES: 'Iniciar Contacto',
        EN: 'Start Contact',
        FR: 'Démarrer le contact'
      },
      banner_items: {
        ES: [
          {
            id: 1,
            title: '<span style="color: #222222;">Apoyo permanente para asegurar tus servicios</span>',
            subtitle: 'Diferentes <strong>canales para responder a tiempo a tus necesidades.</strong>',
            note: 'El cambio inicia con el primer contacto. <em>¡Atrévete a mejorar!</em>',
            image: 'https://cdn.systemico.net/systemico/web/headers/images/header_image_11.svg',
            backgroundImage: '',
            buttons: [],
            show: true
          },
          {
            id: 2,
            title: '<span style="color: #222222;">Siempre nos centramos en nuestros usuarios</span>',
            subtitle: '<strong><b>¡Inicia con un Hola!</b>, <i> nuestros expertos te apoyarán.</i>',
            note: 'Nuevas experiencias para impulsar tu negocio. <em>¡Atrévete a dar el primer paso!</em>',
            image: 'https://cdn.systemico.net/systemico/web/headers/images/header_image_9.svg',
            backgroundImage: '',
            buttons: [],
            show: true
          }
        ],
        EN: [
          {
            id: 1,
            title: '<span style="color: #222222;">Permanent support to ensure your services</span>',
            subtitle: 'Different <strong>channels to respond to your needs on time.</strong>',
            note: 'The change starts with the first contact. <em>Dare to improve!</em>',
            image: 'https://cdn.systemico.net/systemico/web/headers/images/header_image_11.svg',
            backgroundImage: '',
            buttons: [],
            show: true
          },
          {
            id: 2,
            title: '<span style="color: #222222;">We always focus on our users</span>',
            subtitle: '<strong><b>Start with a Hello!</b>, <i>our experts will support you.</i>',
            note: 'New experiences to boost your business. <em>Dare to take the first step!</em>',
            image: 'https://cdn.systemico.net/systemico/web/headers/images/header_image_9.svg',
            backgroundImage: '',
            buttons: [],
            show: true
          }
        ],
        FR: [
          {
            id: 1,
            title: '<span style="color: #222222;">Un support permanent pour garantir vos services</span>',
            subtitle: 'Différents canaux pour répondre à vos besoins dans les délais.</strong>',
            note: "Le changement commence dès le premier contact. Osez l'amélioration !</em>",
            image: 'https://cdn.systemico.net/systemico/web/headers/images/header_image_11.svg',
            backgroundImage: '',
            buttons: [],
            show: true
          },
          {
            id: 2,
            title: '<span style="color: #222222;">Nos utilisateurs sont toujours au centre de nos préoccupations</span>',
            subtitle: '<strong><b>Commencez par un bonjour !</b>, <i>nos experts vous accompagneront.</i>',
            note: 'De nouvelles expériences pour booster votre activité. Osez faire le premier pas !',
            image : 'https://cdn.systemico.net/systemico/web/headers/images/header_image_9.svg',
            backgroundImage : '',
            buttons : [],
            show : true
          }
        ]
      }
    }
  },
  components: {
    BannerMultiProductComponent,
    products,
    ContactComponent,
    ButtonContactComponent
  },
  methods: {
    // Nothing
  },
  beforeMount () {
    if (this.lang === undefined || this.lang === '{{LANG}}') {
      this.lang = 'en'
    }
    this.$emit('showCountry', false)
    this.$emit('showLang', true)
    this.$emit('changeLang', this.lang)
    this.$emit('setAvailableLanguages', this.availableLangs )
  }
}
</script>
<style lang="scss" scoped>
@import "../../../systemico.ui/variables.scss";
  .home{
    min-height: 100%;
    width: 100%;
  }
  .intro{
    margin:auto;
    max-width: $page-max-width;
    background:$color-white;
    text-align:center;
    border-radius:3rem;
    color:$color-black;
  }
  .logo{
    margin-top:10em;
    width: 20%;
    min-width:300px;
  }
  .sublogo{
    font-size: 3rem;
    color:$color-orange;
    padding-top:3.5rem;
    max-width:80%;
    font-weight:bold;
    margin:auto;
  }
  .title{
    margin-bottom:1em;
    font-size:1.5em;
    color:#222222;
    padding:1em;
    margin-top:1em;
    font-weight: bold;
  }
  .selector{
    text-align: center;
    margin:auto;
    padding-left:2em;
    padding-right:2em;
  }
  .continent{
    font-size:2em;
    color:#f8f8f8;
  }
  .country{
    font-size:1.2rem;
    background: $color-white;
    padding:0.5rem;
    margin-top:0.5rem;
    list-style:none;
    border-radius:1.5em;
    a{
      color:#444444;
      text-decoration:none;
    }
    a:hover{
      color:#222222;
      font-weight:bold;
      text-decoration:none;
    }
  }
  country:hover{
    background: #fefefe;
    color:#444444;
  }
  .contact_block{
    margin:auto;
    width: $page-max-width;
    .header{
      font-size:2rem;
      color:#222222;
      font-weight:bold;
      margin-top:2rem;
      margin-bottom:1rem;
      text-align:center;
    }
    .information {
      font-size:1.5rem;
      color:#222222;
      margin-top:1rem;
      text-align:center;
    }
  }
</style>
