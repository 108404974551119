import { render, staticRenderFns } from "./BannerMultiProductComponent.vue?vue&type=template&id=797b03ab&scoped=true"
import script from "./BannerMultiProductComponent.vue?vue&type=script&lang=js"
export * from "./BannerMultiProductComponent.vue?vue&type=script&lang=js"
import style0 from "./BannerMultiProductComponent.vue?vue&type=style&index=0&id=797b03ab&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../../../systemico.web/src/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "797b03ab",
  null
  
)

export default component.exports