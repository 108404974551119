<template>
  <div class="page"  @scroll="headerReact()">
    <section class="pt-3 text-center">
        <div class="w-50 p-4" style="margin: auto;">
            <img class="w-10 mt-4" src="https://cdn.systemico.net/systemico/web/headers/images/header_image_3.svg" alt="Not Found">
            <h4 class="text-center p-1 pt-4 pb-4">
              {{ message[lang] }}
            </h4>
            <ButtonComponent variant="orange" :text="button[$props.lang]" @click="home" />
        </div>
    </section>
  </div>
</template>

<script>
import ButtonComponent from '../../../../systemico.ui/components/buttons/ButtonComponent.vue'

export default {
  name: 'enConstruccion',
  components: {
    ButtonComponent
  },
  props: {
    lang: {
      type: String,
      default: 'es'
    }
  },
  metaInfo: {
    title: 'Página en construcción.'
  },
  data: function () {
    return {
      title: 'Systemico',
      configButton: { text: 'Ir a Inicio', class: 'button--secundary' },
      message: {
        ES: 'Lo siento, no hemos podido encontrar la dirección que solicitas, hemos tomado nota para que sea revisado por nuestro equipo.',
        EN: 'Sorry, we could not find the address you requested, we have taken note for it to be reviewed by our team.'
      },
      button: {
        ES: 'Ir al Inicio',
        EN: 'Go Home'
      }
    }
  },
  methods: {
    home: function () {
      window.location = '/'
    }
  }
}
</script>
