<template>
  <main class="home">
    <BannerMultiProductComponent
        :items="banner_items[$props.lang]"
        :lang="$props.lang" class="mt-5"
        schema="SoftwareApplication"

    />
    <BannerTipComponent :message="tip[$props.lang]" background="#FF8028" textColor="#ffffff"/>
    <BannerBenefitsComponent title="¿Cómo funciona el sistema de prepago de EMAIL?" :items="benefits[$props.lang]" />
    <PricingCalculator />
    <BannerTipComponent :message="tip_plans[$props.lang]" background="#222222" textColor="#ffffff"/>
    <!--<WavesComponent :type="2" color="#ff8028" background="transparent" />-->
    <PricingPlansComponent
        :productId="4"
        paymentsHost="https://systemico.net/pay/"
        currency="USD"
        :lang="$props.lang"
        action="redirect"
        urlButton="https://ducksites.systemico.net/signup"
        :trialLimit="7"
        :title="pricingTitle[$props.lang]"
        :subtitle="pricingSubTitle[$props.lang]"
    >
    </PricingPlansComponent>
  </main>
</template>

<script>
import BannerMultiProductComponent from '../packages/systemico.ui/components/banners/BannerMultiProductComponent.vue'
import PricingPlansComponent from '../packages/systemico.ui/components/pricing/PricingPlansComponent.vue'
import BannerTipComponent from '../packages/systemico.ui/components/banners/BannerTipComponent.vue'
import PricingCalculator from "../packages/systemico.ui/components/pricing/PricingCalculator.vue";
import BannerBenefitsComponent from "../packages/systemico.ui/components/banners/BannerBenefitsComponent.vue";
export default {
  name: 'Home',
  components: {
    BannerBenefitsComponent,
    PricingCalculator,
    BannerTipComponent,
    PricingPlansComponent,
    BannerMultiProductComponent
  },
  props: {
    lang: {
      type: String,
      default: 'EN'
    }
  },
  beforeMount () {
    this.$emit('changeLang', this.$props.lang)
  },
  data () {
    return {
      banner_items: {
        EN:
        [
          {
            id: 1,
            title: "<b style='color:#222222;'>Turn your emails into revenue\n</b> <span style='color:#fc791e;'>with a mass email</span>",
            subtitle: 'Create, schedule, customize, and send impactful campaigns using email.',
            image: 'https://cdn.systemico.net/systemico/web/headers/images/header_image_30.svg',
            backgroundImage: '',
            buttons: [
              { id: 1, text: 'Start for free', variant: 'warning', link: 'https://gogoals.systemico.net/signup', icon: 'gift-fill' },
              { id: 2, text: 'Sign In', variant: 'secondary', link: 'https://gogoals.systemico.net/login', icon: 'person-circle' }
            ],
            note: '<b>7 free days</b>. Enjoy <b>continuous improvements</b> and new features designed to simplify your experience.',
            show: true
          },
          {
            id: 2,
            title: "<b style='color:#222222;'>The Email API,</b> <span style='color:#fc791e;'> for optimal inbox placement</span>",
            subtitle: 'Send marketing and transactional email through <b>Systemico</b>.',
            image: 'https://cdn.systemico.net/systemico/web/headers/images/header_image_7.svg',
            backgroundImage: '',
            buttons: [
              { id: 1, text: 'Get Started Free', variant: 'warning', link: 'https://gogoals.systemico.net/signup', icon: 'gift-fill' },
              { id: 2, text: 'Sign In', variant: 'secondary', link: 'https://gogoals.systemico.net/login', icon: 'person-circle' }
            ],
            note: 'Get all your email delivered in seconds and without interruption.',
            show: false
          }
        ],
        ES: [
          {
            id: 1,
            title: "<b style='color:#222222;'>Convierte tus email en ingresos\n</b> <span style='color:#fc791e;'>con envios masivos</span>",
            subtitle: 'Crea, agenda, personaliza y envía campañas impactantes usando el email.',
            image: 'https://cdn.systemico.net/systemico/web/headers/images/header_image_30.svg',
            backgroundImage: '',
            buttons: [
              { id: 1, text: 'Empieza gratis', variant: 'warning', link: 'https://gogoals.systemico.net/signup', icon: 'gift-fill' },
              { id: 2, text: 'Iniciar sesión', variant: 'secondary', link: 'https://gogoals.systemico.net/login', icon: 'person-circle' }
            ],
            note: '<b>Tus primeros 1000 correos son gratis</b>. Inicia sin preocupaciones y prueba.',
            show: true
          },
          {
            id: 2,
            title: "<b style='color:#222222;'>API de email,</b> <span style='color:#fc791e;'> para llegar a la bandeja de entrada de tus clientes</span>",
            subtitle: 'Envía correos electrónicos de marketing y transaccionales a través de <b>Systemico</b>.',
            image: 'https://cdn.systemico.net/systemico/web/headers/images/header_image_31.svg',
            backgroundImage: '',
            buttons: [
              { id: 1, text: 'Empieza gratis', variant: 'warning', link: 'https://gogoals.systemico.net/signup', icon: 'gift-fill' },
              { id: 2, text: 'Iniciar sesión', variant: 'secondary', link: 'https://gogoals.systemico.net/login', icon: 'person-circle' }
            ],
            note: '<b>Asegura tus campañas de email</b>. Recibe todos tus correos electrónicos en segundos y sin interrupciones.',
            show: false
          }
        ],
        FR: [
            {
              id: 1,
              title: "<b style='color:#222222;'>Transformez vos e-mails en revenus\n</b> <span style='color:#fc791e;'>avec un e-mail de masse</span>",
              subtitle: 'Créez, planifiez, personnalisez et envoyez des campagnes percutantes par e-mail.',
              image: 'https://cdn.systemico.net/systemico/web/headers/images/header_image_30.svg',
              backgroundImage: '',
              buttons: [
                { id: 1, text: 'Commencez gratuitement', variant: 'warning', link: 'https://gogoals.systemico.net/signup', icon: 'gift-fill' },
                { id: 2, text: 'Se connecter', variant: 'secondary', link: 'https://gogoals.systemico.net/login', icon: 'person-circle' }
              ],
              note: '<b>7 jours gratuits</b>. Profitez de <b>améliorations continues</b> et de nouvelles fonctionnalités conçues pour simplifier votre expérience.',
              show: true
            },
            {
              id: 2,
              title: "<b style='color:#222222;'>L'API de messagerie,</b> <span style='color:#fc791e;'> pour une livraison optimale dans la boîte de réception</span>",
              subtitle: 'Envoyez des e-mails marketing et transactionnels via <b>Systemico</b>.',
              image: 'https://cdn.systemico.net/systemico/web/headers/images/header_image_31.svg',
              backgroundImage: '',
              buttons: [
                { id: 1, text: 'Commencez gratuitement', variant: 'warning', link: 'https://gogoals.systemico.net/signup', icon: 'gift-fill' },
                { id: 2, text: 'Se connecter', variant: 'secondary', link: 'https://gogoals.systemico.net/login', icon: 'person-circle' }
              ],
              note: 'Recevez tous vos e-mails en quelques secondes et sans interruption.',
              show: false
            }
        ]
      },
      tip: {
        ES: 'Te ofrecemos los mejores precios para comprar Email para tus envíos de <strong>Email masivos</strong>. Utiliza nuestra plataforma de envío de Email y todas sus funcionalidades pagando solo por los Email que envíes.',
        EN: 'We offer you the best prices to buy Email for your bulk Email shipments. Use our Email sending platform and all its features by paying only for the Email you send.',
        FR: 'Nous vous proposons les meilleurs prix pour l\'achat de Email et l\'envoi de vos Email en masse. Utilisez notre plateforme d\'envoi de Email et toutes ses fonctionnalités en payant uniquement pour les Email envoyés.'
      },
      tip_plans: {
        ES: 'Si tus envios son recurrentes, y ya sabes la cantidad, un plan puede ser la mejor opción.',
        EN: 'If you have recurring desires, there is a cantidad, a plan can be the best option.',
        FR: 'Si vous envoyez des messages récurrents, et vous savez la quantité, un plan peut être la meilleure option.'
      },
      pricingTitle: {
        EN: 'Pricing & Plans',
        ES: 'Precios y Planes',
        FR: 'Tarification & Plans'
      },
      pricingSubTitle: {
        EN: 'One year of service and updates with one payment.',
        ES: 'Un año de servicio y actualizaciones con un solo pago.',
        FR: 'Un an de service et de mises à jour avec un seul paiement.'
      },
      benefits: {
        ES: [
          {
            icon: 'https://cdn.systemico.net/abc/icons/black/icon-1.svg',
            title: 'Email Marketing.',
            description: 'El email marketing permite llegar directamente a tu audiencia, aumentar la conversión, fidelizar clientes y medir el rendimiento de campañas. '
          },
          {
            icon: 'https://cdn.systemico.net/abc/icons/black/icon-2.svg',
            title: 'Sin vencimiento.',
            description: 'Recarga una vez y utiliza cuando lo requieras, tu saldo siempre estará disponible para enviar campañas. '
          },
          {
            icon: 'https://cdn.systemico.net/abc/icons/black/icon-3.svg',
            title: 'Adjuntos.',
            description: 'Documentos, imagenes indivuduales o conjuntas adjuntas o enlazadas a cada correo según tus reglas.'
          },
          {
            icon: 'https://cdn.systemico.net/abc/icons/black/icon-4.svg',
            title: 'Importación multiple.',
            description: 'Importación de contactos desde multiples origenes.'
          },
          {
            icon: 'https://cdn.systemico.net/abc/icons/black/icon-5.svg',
            title: 'Atención gratuita.',
            description: 'Atención gratuita, experta y permanente para resolver tus dudas o sugerencias.'
          },
          {
            icon: 'https://cdn.systemico.net/abc/icons/black/icon-6.svg',
            title: 'Paga solo lo que necesitas.',
            description: 'Paga cada vez que lo requieras y administra tu presupuesto según sea tu capacidad.'
          },
          {
            icon: 'https://cdn.systemico.net/abc/icons/black/icon-9.svg',
            title: 'Control de impacto.',
            description: 'Establece reglas para evitar notificaciones seguidas que afecten tu reputación, o envios directos y masivos.'
          },
          {
            icon: 'https://cdn.systemico.net/abc/icons/black/icon-11.svg',
            title: 'Cola de envio.',
            description: 'Todos los email se encolan para enviarse en paquetes de 1000 por minuto.'
          },
          {
            icon: 'https://cdn.systemico.net/abc/icons/black/icon-13.svg',
            title: 'Personalización.',
            description: 'Personalización según tu necesidad con logos, colores, estructura de mail, remitente, y mucho más.'
          },
          {
            icon: 'https://cdn.systemico.net/abc/icons/black/icon-14.svg',
            title: 'Estadísticas.',
            description: 'Información detallada de envio, apertura, interacción, dados de baja.'
          },
          {
            icon: 'https://cdn.systemico.net/abc/icons/black/icon-7.svg',
            title: 'Múltiples opciones de pago.',
            description: 'Utiliza diferenetes canales para recargar tu cuenta acorde a tus necesidades o capacidades.'
          }
        ]
      }
    }
  }
}
</script>
<style lang="scss" scoped>
.home {

}
</style>
