<template>
  <main class="content-wapp">
     <HeaderSystemico :menuActive="true" country="COLOMBIA" :lang="$props.lang" class="header_nav__active p-0" />
    <navbarSection  style="margin-top: 70px"/>
    <section class="privacy">
      <b-container fluid="lg">
        <b-row>
          <b-col>
            <h2 class="privacy__title">Políticas de privacidad</h2>
            <div class="privacy__line"></div>
          </b-col>
        </b-row>
        <b-row>
          <b-col>
            <h3 class="privacy__subtitle">POLÍTICA DE TRATAMIENTO DE DATOS PERSONALES</h3>
            <p class="privacy__paragraph privacy__paragraph--end">De conformidad con lo dispuesto en la Ley 1581 de 2012, el Decreto 1377 de 2013, el Decreto 1078 de 2015 y la Circular SIC 002 de 2015, se actualiza la Política de Tratamiento de Datos Personales, en los siguientes términos:</p>

            <h3 class="privacy__subtitle">RESPONSABLE DE LOS DATOS PERSONALES</h3>
            <p class="privacy__paragraph">La persona jurídica responsable de los datos personales y por tanto de las bases de datos en la cual se encuentren ubicados los mismos es SYSTEMICO SOFTWARE S.A.S.</p>
            <p class="privacy__paragraph privacy__paragraph--end">Para efectos de la presente Política de Tratamiento de datos “SYSTEMICO” ha designado como Responsable de la atención de peticiones, consultas, reclamos de los usuarios y/o clientes potenciales, así como los requerimientos relacionados con información personal de los trabajadores y extrabajadores de la empresa y los provenientes de contratistas y/o proveedores, a la Dirección Administrativa y Financiera de la empresa, ubicada en la Avenida Altos del Jardín N° 12 de la ciudad de Bucaramanga.</p>

            <h3 class="privacy__subtitle">INFORMACIÓN RECOPILADA</h3>
            <p class="privacy__paragraph">Datos personales de suscriptores, usuarios, clientes potenciales, trabajadores, extrabajadores, contratistas y/o proveedores recaudados por SYSTEMICO durante la suscripción de contratos, su ejecución o aún después de su terminación y/o liquidación, suministrados de manera libre y voluntaria, tales como son: nombre, apellidos, identificación, teléfono fijo y/o móvil, dirección física y electrónica, e incluso aquella considerada como de carácter sensible, vale decir, huella dactilar (física o digital).</p>
            <p class="privacy__paragraph">Adicionalmente, tratándose de trabajadores, información relativa al origen racial o étnico, orientación política, convicciones religiosas, datos biométricos, fotografías o retratos; información de índole socioeconómica, patrimonial, tributaria, composición familiar, referencias comerciales; la relacionada con su salud y demás que se le requiera para el buen desarrollo del vínculo contractual y demás datos necesarios que le sean solicitados al momento de contratar cualquiera de nuestros servicios o celebrar cualquier tipo de contrato o durante la ejecución de los mismos.</p>
            <p class="privacy__paragraph privacy__paragraph--end">SYSTEMICO recolecta la huella que es un dato sensible con la autorización del titular exclusivamente para efectos de evitar suplantaciones de identidad.</p>

            <h3 class="privacy__subtitle">TRATAMIENTO Y FINALIDAD</h3>
            <p class="privacy__paragraph">Los datos recabados por SYSTEMICO serán tratados con el grado de protección adecuado, exigido por la Ley y en este sentido el responsable del tratamiento se compromete a tratar los datos con la finalidad exclusiva para la que fueron recolectados.</p>
            <p class="privacy__paragraph">SYSTEMICO recopila datos de sus usuarios y clientes potenciales con los siguientes fines.</p>
            <ul class="pl-4">
              <li>
                <p class="privacy__paragraph">Identificarlo como usuario de nuestros servicios y adelantar las gestiones necesarias para su mejor prestación.</p>
              </li>
              <li>
                <p class="privacy__paragraph">Ofrecerle información relacionada con nuestros productos, servicios, ofertas, promociones, alianzas, concursos, así como los de nuestras compañías vinculadas o asociadas.</p>
              </li>
              <li>
                <p class="privacy__paragraph">Envío de información correspondiente al estado de su cuenta y obligaciones.</p>
              </li>
              <li>
                <p class="privacy__paragraph">Contactar a cualquier medio que haya registrado para el agendamiento de visitas propias de la prestación del servicio.</p>
              </li>
              <li>
                <p class="privacy__paragraph">Informar sobre nuevos productos o servicios relacionados con el o los servicios contratado(s) o adquirido(s).</p>
              </li>
              <li>
                <p class="privacy__paragraph">Informar sobre cambios de nuestros productos, servicios y tarifas.</p>
              </li>
              <li>
                <p class="privacy__paragraph">Evaluar la calidad del servicio.</p>
              </li>
              <li>
                <p class="privacy__paragraph">Remisión de publicidad, ofertas comerciales y promocionales por parte de SYSTEMICO y sus aliados.</p>
              </li>
            </ul>

            <p class="privacy__paragraph">SYSTEMICO recopila datos de sus trabajadores y/o colaboradores con los siguientes fines:</p>
            <ul class="pl-4">
              <li>
                <p class="privacy__paragraph">Identificarlo como trabajador o extrabajador.</p>
              </li>
              <li>
                <p class="privacy__paragraph">Determinar la idoneidad de sus trabajadores para el desempeño de las funciones asignadas.</p>
              </li>
              <li>
                <p class="privacy__paragraph">Hacer seguimiento a su desempeño laboral y/o académico.</p>
              </li>
              <li>
                <p class="privacy__paragraph">Calificar y evaluar su rendimiento.</p>
              </li>
              <li>
                <p class="privacy__paragraph">Ejecutar y cumplir los contratos.</p>
              </li>
              <li>
                <p class="privacy__paragraph">Garantizar su seguridad y cuidado personal.</p>
              </li>
              <li>
                <p class="privacy__paragraph">Vigilar y evaluar su comportamiento.</p>
              </li>
              <li>
                <p class="privacy__paragraph">Consultar, procesar, solicitar y reportar a cualquier entidad que maneje o administre bases de datos toda la información referente a su condición laboral y/o tributaria.</p>
              </li>
              <li>
                <p class="privacy__paragraph">Mantener el normal desarrollo de la relación contractual.</p>
              </li>
              <li>
                <p class="privacy__paragraph">Cumplir disposiciones legales, reglamentarias y/o regulatorias.</p>
              </li>
            </ul>

            <p class="privacy__paragraph">SYSTEMICO recopila datos de sus contratistas y proveedores con los siguientes fines:</p>
            <ul class="pl-4">
              <li>
                <p class="privacy__paragraph">Pedir cotizaciones de servicios y productos.</p>
              </li>
              <li>
                <p class="privacy__paragraph">Invitarlos a participar en procesos contractuales.</p>
              </li>
              <li>
                <p class="privacy__paragraph">Verificar el envío y recibo de documentos.</p>
              </li>
              <li>
                <p class="privacy__paragraph">Actualizar información.</p>
              </li>
              <li>
                <p class="privacy__paragraph">Pedir aclaraciones a las propuestas.</p>
              </li>
              <li>
                <p class="privacy__paragraph  privacy__paragraph--end">Generar los procesos contractuales.</p>
              </li>
            </ul>

            <h3 class="privacy__subtitle">PRINCIPIOS PARA EL TRATAMIENTO</h3>
            <p class="privacy__paragraph"><strong>Principio de legalidad:</strong> El tratamiento que se da a los datos personales está ajustado a los parámetros establecidos por Ley</p>
            <p class="privacy__paragraph"><strong>Principio de finalidad:</strong> Los datos personales objeto de tratamiento por parte de SYSTEMICO serán utilizados únicamente con los fines anteriormente descritos.</p>
            <p class="privacy__paragraph"><strong>Principio de transparencia:</strong> El Responsable del Tratamiento garantiza los Derechos del titular en cualquier momento y sin restricciones de esta manera es de gran importancia informar claramente los datos que son recolectados y el tratamiento de los mismos.</p>
            <p class="privacy__paragraph"><strong>Principio de seguridad:</strong> La información sujeta a tratamiento por parte de SYSTEMICO cuenta con las medidas exigidas por la Ley según la calidad del dato y a fin de evitar su adulteración, pérdida, consulta, uso o acceso no autorizado o fraudulento.</p>
            <p class="privacy__paragraph"><strong>Principio de confidencialidad</strong>Todas las personas que intervienen en el Tratamiento de datos personales se encuentran obligadas a garantizar la reserva de la información, inclusive después de finalizada su relación con alguna de las labores que comprende el Tratamiento, pudiendo sólo realizar suministro o comunicación de datos personales cuando ello corresponda al desarrollo de las actividades autorizadas por la Ley o por el titular de los datos.</p>
            <p class="privacy__paragraph"><strong>Principio de veracidad o calidad:</strong> La información sujeta a tratamiento deberá ser veraz, completa, exacta, actualizada, comprobable, y comprensible. Se prohíbe el tratamiento de datos parciales, incompletos o fraccionados.</p>
            <p class="privacy__paragraph  privacy__paragraph--end"><strong>Principio de acceso y circulación restringida:</strong> El tratamiento se sujeta a los límites que se derivan de la naturaleza de los datos personales, de las disposiciones de la ley y la constitución política. En este sentido, el tratamiento sólo podrá hacerse por personas autorizadas. Los datos personales, salvo la información pública, no podrán estar disponibles en internet u otros medios de divulgación o comunicación masiva, salvo que el acceso sea técnicamente controlable para brindar un conocimiento restringido sólo a los titulares o terceros autorizados conforme a la ley.</p>

            <h3 class="privacy__subtitle">MEDIDAS DE SEGURIDAD</h3>
            <p class="privacy__paragraph  privacy__paragraph--end">Las medidas de seguridad adoptadas por SYSTEMICO sobre sus bases de datos se ajustan a las previstas en la ley, con el fin de evitar accesos no autorizados, utilización, modificación, supresión, pérdida y/o utilización indebida de los datos personales. Todas las bases de datos, propiedad de SYSTEMICO cuentan con las medidas de seguridad básicas; dichas medidas se incrementan según la calidad del dato.</p>

            <h3 class="privacy__subtitle">DERECHOS DEL TITULAR DE LOS DATOS</h3>
            <p class="privacy__paragraph">Como titular de los datos recolectados por SYSTEMICO, conforme a lo establecido en la Ley 1581 de 2012 y el Decreto 1377 de 2013, toda persona puede hacer uso de los siguientes derechos:</p>
            <ul class="pl-4">
              <li>
                <p class="privacy__paragraph">Derecho de Acceso: Es el derecho que tiene toda persona para conocer si sus datos personales están siendo objeto de tratamiento, se encuentran incluidos en la base de datos, que finalidad y origen tienen estos, así como las cesiones realizadas o previstas a terceros. Para ejercer su derecho podrá presentar una solicitud ante el Responsable del Tratamiento o por medio de los canales de contacto ya especificados.</p>
              </li>
              <li>
                <p class="privacy__paragraph">Es importante resaltar que el titular podrá consultar de forma gratuita sus datos personales al menos una vez cada mes calendario y cada vez que existan modificaciones sustanciales de las Políticas de Tratamiento de datos personales. Cuando la consulta se realice más de una vez cada mes SYSTEMICO, como propietario de la base de datos, podrá cobrar los gastos de envío, reproducción y si se requiere certificación de documentos.</p>
              </li>
              <li>
                <p class="privacy__paragraph">Derecho de Actualización y Rectificación: Es el derecho que tiene cada persona como titular de los datos para que se actualicen y corrijan los datos personales que se encuentran en las bases de datos propiedad de SYSTEMICO, cuando estos hayan cambiado o cuando exista un error, sean inexactos o incompletos. Para ello igualmente se debe presentar una solicitud de información que debe contener, adicionalmente a lo señalado en el punto anterior sobre el acceso a datos personales, las modificaciones a realizarse y aportar la documentación que sustente su petición.</p>
              </li>
              <li>
                <p class="privacy__paragraph">Derecho de Supresión: Es el derecho que tiene cada persona como usuario para que se supriman sus datos personales en las bases de datos propiedad de SYSTEMICO, en los siguientes casos: (i) cuando el tratamiento de los mismos no se ajuste a lo dispuesto por la Ley de Protección de Datos (Ley 1581 de 2012) siempre y cuando la Superintendencia de Industria y Comercio haya determinado que en el Tratamiento el Responsable o Encargado han incurrido en conductas contrarias al ordenamiento y (ii) en virtud de la solicitud libre y voluntaria del Titular del dato, siempre y cuando no exista una obligación legal o contractual que imponga al Titular el deber de permanecer en la referida base de datos. La supresión da lugar al bloqueo de sus datos, esto quiere decir que los datos se conservan únicamente a disposición de autoridades competentes, con fines de seguridad y para atender las posibles responsabilidades nacidas del tratamiento.</p>
              </li>
              <li>
                <p class="privacy__paragraph">Presentación de quejas: Como titular de los datos tiene derecho de presentar ante la Superintendencia de Industria y Comercio quejas por infracciones asociadas al uso de sus datos personales, siempre y cuando haya agotado el trámite de consulta o reclamo ante SYSTEMICO.</p>
              </li>
              <li>
                <p class="privacy__paragraph  privacy__paragraph--end">Presentación de quejas: Como titular de los datos tiene derecho de presentar ante la Superintendencia de Industria y Comercio quejas por infracciones asociadas al uso de sus datos personales, siempre y cuando haya agotado el trámite de consulta o reclamo ante SYSTEMICO.</p>
              </li>
            </ul>

            <h3 class="privacy__subtitle">DEBERES DEL RESPONSABLE Y ENCARGADO DEL TRATAMIENTO</h3>
            <p class="privacy__paragraph">El responsable y los encargados del tratamiento deben cumplir con las siguientes disposiciones previstas en la Ley 1581 de 2012 y el Decreto 1377 de 2013. <strong>Responsable del tratamiento</strong></p>
            <ol class="pl-4">
              <li>
                <p class="privacy__paragraph">Garantizar al Titular, en todo tiempo, el pleno y efectivo ejercicio del derecho de hábeas data;</p>
              </li>
              <li>
                <p class="privacy__paragraph">Solicitar y conservar, en las condiciones previstas en la presente ley, copia de la respectiva autorización otorgada por el Titular;</p>
              </li>
              <li>
                <p class="privacy__paragraph">Informar debidamente al Titular sobre la finalidad de la recolección y los derechos que le asisten por virtud de la autorización otorgada;</p>
              </li>
              <li>
                <p class="privacy__paragraph">Conservar la información bajo las condiciones de seguridad necesarias para impedir su adulteración, pérdida, consulta, uso o acceso no autorizado o fraudulento;</p>
              </li>
              <li>
                <p class="privacy__paragraph">Garantizar que la información que se suministre al Encargado del Tratamiento sea veraz, completa, exacta, actualizada, comprobable y comprensible;</p>
              </li>
              <li>
                <p class="privacy__paragraph">Actualizar la información, comunicando de forma oportuna al Encargado del Tratamiento, todas las novedades respecto de los datos que previamente le haya suministrado y adoptar las demás medidas necesarias para que la información suministrada a este se mantenga actualizada;</p>
              </li>
              <li>
                <p class="privacy__paragraph">Rectificar la información cuando sea incorrecta y comunicar lo pertinente al Encargado del Tratamiento;</p>
              </li>
              <li>
                <p class="privacy__paragraph">Suministrar al Encargado del Tratamiento, según el caso, únicamente datos cuyo Tratamiento esté previamente autorizado de conformidad con lo previsto en la presente ley;</p>
              </li>
              <li>
                <p class="privacy__paragraph">Exigir al Encargado del Tratamiento en todo momento, el respeto a las condiciones de seguridad y privacidad de la información del Titular;</p>
              </li>
              <li>
                <p class="privacy__paragraph">Tramitar las consultas y reclamos formulados en los términos señalados en la presente ley;</p>
              </li>
              <li>
                <p class="privacy__paragraph">Adoptar un manual interno de políticas y procedimientos para garantizar el adecuado cumplimiento de la presente ley y en especial, para la atención de consultas y reclamos;</p>
              </li>
              <li>
                <p class="privacy__paragraph">Informar al Encargado del Tratamiento cuando determinada información se encuentra en discusión por parte del Titular, una vez se haya presentado la reclamación y no haya finalizado el trámite respectivo;</p>
              </li>
              <li>
                <p class="privacy__paragraph">Informar a solicitud del Titular sobre el uso dado a sus datos;</p>
              </li>
              <li>
                <p class="privacy__paragraph">Informar a la autoridad de protección de datos cuando se presenten violaciones a los códigos de seguridad y existan riesgos en la administración de la información de los Titulares.</p>
              </li>
              <li>
                <p class="privacy__paragraph">Cumplir las instrucciones y requerimientos que imparta la Superintendencia de Industria y Comercio.</p>
              </li>
            </ol>

            <p class="privacy__paragraph"><strong>Encargados del tratamiento</strong></p>
            <ol class="pl-4">
              <li>
                <p class="privacy__paragraph">Garantizar al Titular, en todo tiempo, el pleno y efectivo ejercicio del derecho de hábeas data; Conservar la información bajo las condiciones de seguridad necesarias para impedir su adulteración, pérdida, consulta, uso o acceso no autorizado o fraudulento;</p>
              </li>
              <li>
                <p class="privacy__paragraph">Realizar oportunamente la actualización, rectificación o supresión de los datos en los términos de la presente ley;</p>
              </li>
              <li>
                <p class="privacy__paragraph">Actualizar la información reportada por el Responsable del Tratamiento dentro de los cinco (5) días hábiles contados a partir de su recibo;</p>
              </li>
              <li>
                <p class="privacy__paragraph">Tramitar las consultas y los reclamos formulados por los Titulares en los términos señalados en la presente ley;</p>
              </li>
              <li>
                <p class="privacy__paragraph">Abstenerse de circular información que esté siendo controvertida por el Titular y cuyo bloqueo haya sido ordenado por la Superintendencia de Industria y Comercio;</p>
              </li>
              <li>
                <p class="privacy__paragraph">Permitir el acceso a la información únicamente a las personas que pueden tener acceso a ella;</p>
              </li>
              <li>
                <p class="privacy__paragraph">Informar a la Superintendencia de Industria y Comercio cuando se presenten violaciones a los códigos de seguridad y existan riesgos en la administración de la información de los Titulares;</p>
              </li>
              <li>
                <p class="privacy__paragraph">Cumplir las instrucciones y requerimientos que imparta la Superintendencia de Industria y Comercio.</p>
              </li>
              <li>
                <p class="privacy__paragraph  privacy__paragraph--end">Parágrafo.En el evento en que concurran las calidades de Responsable del Tratamiento y Encargado del Tratamiento en la misma persona, le será exigible el cumplimiento de los deberes previstos para cada uno.</p>
              </li>
            </ol>

            <h3 class="privacy__subtitle">OFICIAL DE PROTECCIÓN DE DATOS</h3>
            <p class="privacy__paragraph privacy__paragraph--end">SYSTEMICO ha designado una persona encargada y/o responsable de ejercer el rol de oficial de protección de datos, quien debe realizar sus funciones y ejecutar todas las actividades con apego a la legislación que regula la materia y en los términos que establece la guía para la implementación del principio de responsabilidad demostrada (Accountability), publicada por la Superintendencia de Industria y Comercio.</p>

            <h3 class="privacy__subtitle">FUNCIONES DEL OFICIAL DE PROTECCIÓN DE DATOS</h3>
            <p class="privacy__paragraph">La función principal del oficial de protección de datos será velar por la implementación efectiva de las políticas y procedimiento adoptados por la empresa para cumplir las normas, así como la implementación de buenas prácticas de gestión de datos personales dentro de la Empresa.</p>

            <p class="privacy__paragraph">Adicionalmente ejercerá las siguientes funciones:</p>
            <ul class="pl-4">
              <li>
                <p class="privacy__paragraph">Velar en coordinación con la Dirección Administrativa y Financiera por el trámite efectivo y oportuno a las solicitudes elevadas por los Titulares de los datos.</p>
              </li>
              <li>
                <p class="privacy__paragraph">Establecer las responsabilidades específicas de las áreas de la empresa frente a la recolección, almacenamiento, uso, actualización, circulación y eliminación de los datos.</p>
              </li>
              <li>
                <p class="privacy__paragraph privacy__paragraph--end">Establecer los controles necesarios e implementar su evaluación y revisión permanente.</p>
              </li>
            </ul>

            <h3 class="privacy__subtitle">ATENCIÓN DE PETICIONES, CONSULTAS, RECLAMOS Y PROCEDIMIENTOS</h3>
            <p class="privacy__paragraph">Señor suscriptor, usuario y/o cliente potencial si usted desea hacer uso de los derechos que le asisten puede hacerlo por los siguientes medios:</p>
            <ul class="pl-4 margin-margin-bottom-3">
              <li>
                <p class="privacy__paragraph">Comunicación remitida al Responsable de la atención de peticiones de SYSTEMICO en la Avenida Altos del Jardín N° 12 (Bucaramanga, Santander - Colombia).</p>
              </li>
              <li>
                <p class="privacy__paragraph">A través del correo electrónico <a href="mailto:cao@systemico.co">cao@systemico.co</a></p>
              </li>
            </ul>
            <p class="privacy__paragraph privacy__paragraph--end">Señor trabajador, extrabajador, contratista y/o proveedor si usted desea hacer uso de los derechos que le asisten como titular de la información puede elevar su petición, queja o reclamo mediante comunicación remitida a los Responsables ya establecidos en la presente política para la atención de peticiones, consultas y reclamos de SYSTEMICO en la Sede Administrativa Avenida Altos del Jardín N° 12 o a través del correo <a href="mailto:cao@systemico.co">cao@systemico.co</a></p>

            <h3 class="privacy__subtitle">APLICACIÓN</h3>
            <p class="privacy__paragraph privacy__paragraph--end">Nuestras políticas de privacidad se aplican a todas las bases de datos propiedad de SYSTEMICO.</p>

            <h3 class="privacy__subtitle">VIGENCIA</h3>
            <p class="privacy__paragraph">Las Políticas de Tratamiento aquí establecidas entran en vigencia el día su publicación en página web.</p>
            <p class="privacy__paragraph">Las Bases de datos estarán vigentes durante el tiempo en que SYSTEMICO S.A.S., desarrolle su objeto social.</p>
            <p class="privacy__paragraph">Para mayor información relacionada con las disposiciones legales de protección de datos, y aquellas relacionadas con los procedimientos de reclamo respecto de las mismas, le sugerimos visitar la página web de la Superintendencia de Industria y Comercio (www.sic.gov.co). SYSTEMICO se reserva el derecho a modificar las Políticas de Tratamiento de datos personales en cualquier momento. Toda modificación será comunicada oportunamente a los titulares de los datos personales a través de la página web.</p>
          </b-col>
        </b-row>
      </b-container>
    </section>
    <footerC/>
  </main>
</template>

<script>
import footerC from '../../../../systemico.ui/components/footers/FooterSystemicoComponent.vue'
import navbarSection from '@/components/basic/navbarSection.vue'
import HeaderSystemico from "../../../../systemico.ui/components/headers/HeaderSystemicoComponent.vue";

export default {
  name: 'politics',
  props: {
  },
  components: {
    HeaderSystemico,
    footerC,
    navbarSection
  },
  metaInfo: {
    title: 'Políticas de Protección de Datos - Systemico'
  },
  data: function () {
    return {
    }
  },
  methods: {
  }
}
</script>

<style lang="scss" scoped>
@import '../../../../systemico.ui/variables.scss';
  .privacy {
    font-size: 1rem;
    margin-top: 3rem;
    .content{
      padding: 20px;
    }
    &__title {
      margin: 0 0 2rem;
      font-family: $font-mulish;
      font-size: 2rem;
      font-weight: 600;
      color: $color-black;
      text-align: center;
      position: relative;
      z-index: 5;
    }
    &__subtitle {
      margin: 0 0 2rem;
      font-size: 2rem;
      font-weight: 600;
      color: $color-black;
      position: relative;
      z-index: 5;
    }
    &__paragraph {
      margin: 0 0 2rem;
      font-size: 1rem;
      font-weight: normal;
      color: $color-black;
      text-align: justify;
      position: relative;
      z-index: 5;
      &--end {
        margin-bottom: 6rem;
      }
    }
    &__line {
      width: 9rem;
      height: 0.4rem;
      margin: 0 auto 6rem;
      border-radius: 0.4rem;
      background-color: $color-gray;
      display: block;
    }
  }
</style>
