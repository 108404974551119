<template>
  <!-- COOKIES CONSENT -->
  <div id="consent_box" v-if="consentShow">
      <b-row id="consent_content">
        <b-col md="8" class="p-2">
          <header id="consent_header" class="m-0">
            {{ title[lang] }}
          </header>
          <p class="m-0">
            {{ message[lang] }}
          </p>
        </b-col>
        <b-col md="4" class="border-left" >
          <div class="buttons m-0 p-0 mt-3 text-center">
              <ButtonComponent variant="gray" icon="check" class="mr-1" @click="acceptCookies" :text="buttonLabel[lang]"></ButtonComponent>
              <!--<ButtonComponent variant="secondary" @click="rejectCookies" text="Reject"></ButtonComponent>-->
          </div>
        </b-col>
      </b-row>
  </div>
</template>

<script>
import ButtonComponent from '../buttons/ButtonComponent.vue'

export default {
  name: 'CookieConsent',
  components: { ButtonComponent },
  data: () => ({
    consentShow: true,
    title: {
      EN: 'Allow cookies.',
      ES: 'Permitir cookies.',
      FR: 'Autoriser les cookies.'
    },
    message: {
      EN: 'We use cookies to improve your website and application experience.',
      ES: 'Utilizamos cookies para mejorar tu experiencia web.',
      FR: 'Nous utilisons des cookies pour améliorer votre expérience sur le site.'
    },
    buttonLabel: {
      EN: 'Accept',
      ES: 'Aceptar'
    }
  }),
  beforeMount () {
    this.load()
  },
  props: {
    lang: { default: 'EN', type: String }
  },
  methods: {
    load () {
      if (localStorage.getItem('consent')) {
        this.consentShow = false
      }
    },
    acceptCookies () {
      localStorage.setItem('consent', true)
      this.consentShow = false
    },
    rejectCookies () {
      localStorage.removeItem('consent')
      this.consentShow = true
    }
  }
}
</script>

<style lang="scss" scoped>
  #consent_box {
    position: fixed;
    bottom: 0;
    width: 35%;
    background: #f8f8f8;
    padding: 0.8rem;
    border-radius: 0.5rem;
    box-shadow: 0 15px 15px rgba(0, 0, 0, 0.2);
    text-align: left;
    z-index: 1000;
    border: 1px solid #f0f0f0;
    margin: 1%;
  }

  #consent_box.hide {
      opacity: 0;
      pointer-events: none;
      transform: scale(0.8);
      transition: all 0.3s ease;
      padding: 0;
      margin: 0;
      height: 0;
      min-height: 0;
  }
  ::selection {
      color: #fff;
      background: #229a0f;
  }
  #consent_content p {
      color: #666666;
      font-size: 0.8rem;
      font-weight: 400;
      text-align: left;
  }
  @media screen and (max-width: 768px) {
    #consent_box {
      width:90%;
      margin:5%;
      padding: 1rem;
    }
    #consent_content p {
      font-size: 1rem;
    }
  }
  #consent_content .buttons {
      margin-top: 1.5rem;
      justify-content: right;
  }
  .consent_button,
  .reject_button {
      padding: 12px 30px;
      border: none;
      outline: none;
      color: #fff;
      font-size: 16px;
      font-weight: 500;
      border-radius: 5px;
      cursor: pointer;
      transition: all 0.3s ease;
      border-radius: 25px;
  }
  .consent_button {
      background: #2a910b;
      margin-right: 10px;
  }
  .reject_button {
      color: #111211;
      background: transparent;
      border: 2px solid #099c2c;
      text-decoration: none;
  }
  #consent_box img {
      max-width: 90px;
  }
  #consent_header {
    font-size: 1rem;
    font-weight: 600;
    text-align: left;
    color: #222222;
  }
</style>
