<template>
    <main class="section">
      <!-- SECCIÓN 1 -->
      <section class="content mt-5 pt-5">
              <titleSection :content='content'/>
        <b-container fluid='lg' class="mt-5">
          <b-row>
            <b-col cols='12' md=''>
                          <h2 class="content__title content__title--celeste mb-5">Crece junto a nosotros</h2>
              <p class="content__subtitle">“Mejorar, crecer y cumplir metas es nuestro mantra, quizás hoy buscamos 3M USD pero tenemos la meta clara de 1B USD” <br> <strong>Edwin Ariza</strong> - CEO Systemico</p>
            </b-col>
            <b-col cols='12' md=''>
              <img class="d-block mx-auto" :src="$cdn + 'images/image__work.svg'">
            </b-col>
          </b-row>
        </b-container>
      </section>
		  <!-- seccion 2 -->
      <section class="content__section content--gray py-8">
        <b-container fluid=lg>
          <b-row>
            <b-col cols='12'>
                          <h2 class="content__title text-center margin-bottom-5">Inversores</h2>
            </b-col>
          </b-row>
          <b-row>
            <b-col cols='8' class="mx-auto">
                          <p class="content__paragraph margin-bottom-6">Systemico es una empresa innovadora centrada en la simplificación de negocios a partir de diferentes tecnologías de última generación que ha madurado una metodología para analizar, diseñar, desarrollar, probar y liberar soluciones de tecnología.</p>
            </b-col>
          </b-row>
                  <b-row>
                      <b-col cols='12' md=''>
                          <div class="content__element shadow-none margin-bottom-3">
                              <h3 class="text-center margin-bottom-4 margin-top-3">¿Qué hace Systemico?</h3>
                              <p>Systemico se encarga de acompañar tecnológicamente a startups que quieren lanzar sus plataforma de tecnología al mercado y que requieren del apoyo de una empresa con experiencia en modelos en la nube, aplicaciones móviles, arquitectura de software, entre otros muchos tema para así liberar su producto de manera gradual para así poco a poco lograr su market fit que les permita rentabilizar el negocio.</p>
                              <p>Systemico toma modelos de negocio claros y se involucra directamente con el equipo emprendedor para simplificar procesos y disminuir las etapas requeridas para que un usuario consuma un servicio determinado y todo esto lo transforma en plataformas de tecnología que simplifican la administración del negocio y permiten su extensibilidad gradual dependiendo del avance de cada startup.</p>
                          </div>
                          <div class="content__element shadow-none">
                              <h3 class="text-center margin-bottom-4 margin-top-4">Mercados que nos interesan</h3>
                              <p>Systemico quiere crecer en 20 países con mínimo 10 clientes en cada uno, para esto contará con sus headquarters en Toronto, Canadá y su equipo central de desarrollo en Bucaramanga, Colombia para mantener la estandarización de procesos, componentes, frameworks, etc que puedan ser replicados en los nuevos nodos autogestionados de la empresa que se van a crear en otras ciudades del mundo.</p>
                          </div>
                      </b-col>
                      <b-col cols='12' md=''>
                          <div class="content__element shadow-none margin-bottom-3">
                              <h3 class="text-center margin-bottom-4 margin-top-4">¿Qué buscamos de un inversor?</h3>
                              <p>Para Systemico es claro indicar que buscamos inversores con experiencia en negocios globales que quieran participar activamente en la meta de ser una fábrica de software para startups que quieren generar servicios de talla mundial y apoyar la generación de los próximos unicornios tecnológicos del mundo.</p>
                          </div>
                          <div class="content__element shadow-none">
                              <h3 class="text-center margin-bottom-4 margin-top-4">¿Porqué recibir inversión?</h3>
                              <p>Hemos tomado la decisión de crecer después de años entendiendo la importancia de la simplificación, la productividad y la agilidad en las soluciones a tiempo para aprovechar oportunidades únicas del mercado y es por eso que requerimos la inversión para:</p>
                              <ul>
                                  <li>
                                      <p>Crear nuevos equipos de trabajo en otros países que puedan apoyar directamente a startups con su conocimiento y ganas.</p>
                                  </li>
                                  <li>
                                      <p>Mejorar las condiciones laborales y beneficios de los miembros del equipo, ser los mejores requiere lo mejor.</p>
                                  </li>
                                  <li>
                                      <p>Incrementar exponencialmente la capacidad comercial para superar las metas fijadas.</p>
                                  </li>
                                  <li>
                                      <p>Mejorar la plataforma tecnológica para seguir simplificando y ampliando los servicios e incrementar la estandarización que disminuya los tiempos de desarrollo.</p>
                                  </li>
                                  <li>
                                      <p>Mejorar los canales de comunicación del negocio para hacerlos un actor altamente reconocido ecosistema de emprendimiento global.</p>
                                  </li>
                              </ul>
                          </div>
                      </b-col>
                  </b-row>
                  <b-row>
            <b-col cols='12'>
                          <h2 class="content__title text-center margin-bottom-5 margin-top-5">Si eres nuestro inversor o deseas serlo.</h2>
            </b-col>
          </b-row>
          <b-row>
            <b-col cols='8' class="mx-auto">
                          <p class="content__paragraph margin-bottom-6">Podemos asegurarte que hemos cometido erores, aprendido mucho y estamos listos para crecer y llegar al mercado global de la forma más genial posible, actualmente Systemico se enfoca en la identificación de su ronda de inversión Serie A para escalar el negocio a 1000 clientes en 20 países para lograr esto busca la participación de inversores alineados a la estrategia de apoyar 1000 startups a transformar sus plataforma de tecnologías en negocios altamente funcionales que simplifiquen la vida de los usuarios y le permita a sus founders enfocarse en rentabilizar sus negocios y mantener negociaciones a largo plazo con su aliado Systemico. Solo estamos empezando en esa meta de ser la mejor fábrica de software para startups, un Unicornio Tecnológico en el 2027.</p>
            </b-col>
          </b-row>
                  <b-row>
                      <b-col cols='12' md=''>
                          <div class="content__element content__element--blue shadow-none">
                              <p>“Cada inversor es un miembro activo del equipo, un soñador con propósito, una persona convencida del potencial que tiene Systemico para convertir ideas en negocios de base tecnológica y de alto impacto. Cada día despertamos con el objetivo de generar un ecosistema que simplifica el uso de la tecnología, que llega a donde nadie ha llegado y que da la oportunidad de crecer con sentido a diferentes startups en el mundo. Estamos listos para escalar a nivel mundial, estás convocado a participar.”</p>
                              <p><strong>Edwin Ariza - Co-founder & Systemico’s CEO</strong></p>
                          </div>
                      </b-col>
                      <b-col cols='12' md=''>
                          <div class="content__element h-100 shadow-none">
                              <p>Si te interesa invertir en un negocio de futuro escribe en <a href="mailto:investor@systemico.co">investor@systemico.co</a> y date la oportunidad de participar en este gran sueño.</p>
                              <p>Visita nuestro perfil de inversión en: <a href="https://angel.co/company/systemico" target="_blank" rel="noopener noreferrer">https://angel.co/company/systemico</a></p>
                          </div>
                      </b-col>
                  </b-row>
        </b-container>
      </section>
      <!-- SECCIÓN 3 -->
      <section class="content__section content--gray py-8">
			<b-container fluid=lg>
				<b-row>
					<b-col cols='12'>
            <h2 class="content__title text-center margin-bottom-5">Aceleradoras</h2>
					</b-col>
				</b-row>
        <b-row>
          <b-col>
              <div class="content__element shadow-none">
                  <b-container fluid>
                      <b-row>
                          <b-col cols="12" md="">
                              <div class="wepower margin-top-4">
                                  <img :src="$cdn + 'logos/Logo-WePower.png'" alt="picture person">
                                  <img :src="$cdn + 'logos/Logo-WePower-accelerator-program-2.png'" alt="picture person">
                              </div>
                          </b-col>
                          <b-col cols="12" md="">
                              <h3 class="margin-bottom-4 margin-top-4">Llevando a Systemico al siguiente nivel</h3>
                              <p>Systemico fue seleccionada por la aceleradora WEPOWER de Estados Unidos, como una de las 10 empresas que serán parte de su programa de desarrollo empresarial “<strong>ELEVATE / ELEVAR Accelerator</strong>” cohorte 2022. Esto nos dará una oportunidad única para crecer y globalizar nuestros productos y servicios.</p>
                          </b-col>
                      </b-row>
                  </b-container>
              </div>
          </b-col>
        </b-row>
			</b-container>
		</section>
	</main>
</template>

<script>
import footerC from '../../../../../../systemico.ui/components/footers/FooterSystemicoComponent.vue'
import titleSection from '@/components/ui/titleSection'
import buttonPrimry from '@/components/basic/buttonBasic.vue'
import HeaderSystemico from "../../../../../../systemico.ui/components/headers/HeaderSystemicoComponent.vue";

export default {
    name: "investors",
    components: {
      HeaderSystemico,
      titleSection,
      buttonPrimry,
      footerC
	},
  props: {
    lang: String
  },
	metaInfo: {
		title: 'Inversores - Systemico'
	},
	data: function () {
		return {
			content: {
				title: 'Compañía',
				subtitle: '/ Inversores'
			}
		}
	},
	methods: {
        funcioRedirect: function (link) {
			window.location=link
		}
	}
};
</script>
