<!-- CREATE A COMPONENT TO SELECT THE NUMBER OF EMAILS AND CALCULATE THE  TOTAL FROM A STATIC PRICE -->
<template>
  <section class="p-5 pricing_calculator" style="background: #ff8028">
    <b-container class="container">
      <h2 class="text-center text-white font-weight-bold pb-3" for="emailCount">Paga solo por lo que necesitas.</h2>
      <b-card class="w-100 bg-light" style="margin:auto">
        <b-row>
          <b-col>
            <h3 class="mb-5 text-center font-weight-bold">¿Cuantos envios diarios necesitas?</h3>
            <ol class="mb-4">
              <li><strong>Identifica la cantidad total de mensajes:</strong> Cuenta cuántos mensajes se han enviado en un período determinado (por ejemplo, en una semana o un mes).</li>
              <li><strong>Determina la frecuencia de envío:</strong> Evalúa cuántos mensajes envías por hora o por día. Una mayor frecuencia puede afectar tu reputación.</li>
              <li><strong>Evalúa la interacción:</strong> Si los mensajes están recibiendo respuestas o generando interacciones evalua que genera la relevancia para replicarlo o aprovecharlo más.</li>
              <li><strong>Considera la importancia del contenido:</strong> Si los mensajes contienen información clave o se envían en momentos críticos, su impacto será mayor.</li>
            </ol>
            <div class="note" >
              <b><b-icon icon="info-circle"  /> Recuerda:</b> Una mayor "cantidad" no siempre significa un mayor éxito. La calidad y relevancia de los mensajes es igualmente importante que su cantidad.
            </div>
          </b-col>
          <b-col>
              <b-card footer-bg-variant="success" img-src="https://cdn.systemico.net/systemico/web/headers/header_56.svg" style="margin:auto; font-size: 1.2rem">
                <!-- GENERATE THE CURRENCY SELECTO -->
                <div class="pb-4">
                  <label>1. Selecciona tu moneda:</label>
                  <CurrencySelector :selCurrency="currency" lang="ES" @onChange="currencyChange"/>
                </div>
                <!-- GENERATE SLIDE TO SELECT THE EMAIL COUNT NUMBER -->
                <div>
                  <label>2. Define tu cantidad de emails:</label><br/>
                  <input type="number" style="width: 6rem" v-model="quantity" id="emailCount" min="0" />
                  <span class="lead ml-2">email x {{$props.valueSeed[$props.currency]}} <b-badge variant="success">{{currency}}</b-badge></span>
                </div>
                <div class="mt-2">
                  <input type="range" class="w-100" v-model="quantity" id="emailCount" min="0" max="10000" step="1" :title="quantity" />
                </div>
                <template #footer>
                  <b-row class="text-white">
                    <b-col md="4" class="text-right">
                      <h3>Total</h3>
                    </b-col>
                    <b-col md="8" class="text-right">
                      <h3 class="font-weight-bold">{{ totalPrice | formatNumberMoney($props.currency).slice(0, -3) }} <b-badge variant="success">{{currency}}</b-badge></h3>
                    </b-col>
                  </b-row>
                </template>
        </b-card>
          </b-col>
          <b-col>
            <b-card class="p-1 mt-0 text-dark" >
                <h4 class="font-weight-bold">¿Necesitas mas de 10.000 mensajes diarios?</h4>
                <div>Escríbenos y te ayudaremos a iniciar con un mejor precio y apoyo experto exclusivo.</div>
                <ButtonContactComponent class="mt-2" icon="chat-fill" variant="orange" text="Contactar" />
              </b-card>

              <b-card bg-variant="white" class="p-1 mt-4 text-dark" img-src="https://cdn.systemico.net/systemico/web/headers/images/header_image_21.svg">
                <h4 class="font-weight-bold">Genial ¿no?</h4>
                <h5>Inicia con <b>1000 mensajes gratis</b> y empieza HOY e <b>IMPULSA EL CRECIMIENTO DE TUS VENTAS</b>.</h5>
                <ButtonComponent class="mt-2" icon="gift-fill" variant="warning" text="Empieza Gratis" href="https://gogoals.systemico.net/signup" />
              </b-card>
          </b-col>
        </b-row>
      </b-card>
    </b-container>
  </section>
</template>

<script>
import CurrencySelector from '../input/CurrencySelector.vue';
import ButtonContactComponent from "../buttons/ButtonContactComponent.vue";
import ButtonComponent from "../buttons/ButtonComponent.vue";
export default {
  name: "PricingCalculator",
  components: {
    ButtonComponent,
    ButtonContactComponent,
    CurrencySelector
  },
  props: {
    valueSeed: {
      type: Object,
      default: {
        USD: 0.0012,
        COP: 4,
        CAD: 0.0020,
      }
    },
    currency: {
      type: String,
      default: 'USD'
    }
  },
  data() {
    return {
      quantity: 0
    };
  },
  computed: {
    totalPrice() {
      return (this.quantity * this.valueSeed[this.currency]).toFixed(2);
    }
  },
  methods: {
    currencyChange( currency ){
      this.currency = currency
    }
  }
};
</script>

<style scoped>
@import '../../variables.scss';
.pricing_calculator{
  .container{
    max-width: $page-max-width;
    label{
      font-weight: bold;
      color: #222222;
    }
    .note{
      font-size: 0.8rem;
    }
  }
}
</style>