<template>
  <main class="content-wapp">
    <HeaderSystemico :menuActive="true" country="COLOMBIA" :lang="$props.lang" class="header_nav__active p-0" />
    <navbarSection  style="margin-top: 70px"/>
    <section class="privacy">
      <b-container fluid="lg">
        <b-row>
          <b-col>
            <h2 class="privacy__title">Aviso de privacidad</h2>
            <div class="privacy__line"></div>
          </b-col>
        </b-row>
        <b-row>
          <b-col>
            <p class="privacy__paragraph privacy__paragraph--end">Systemico Software S.A.S, en cumplimiento de la Ley 1581 de 2012 y demás normas concordantes, es responsable del tratamiento de sus datos personales.</p>

            <h3 class="privacy__subtitle">RESPONSABLE DE LOS DATOS PERSONALES</h3>
            <p class="privacy__paragraph">La persona jurídica responsable de los datos personales y por tanto de las bases de datos en la cual se encuentren ubicados los mismos es SYSTEMICO SOFTWARE S.A.S.</p>
            <p class="privacy__paragraph privacy__paragraph--end">Para efectos del presente Aviso de privacidad "SYSTEMICO" ha designado como Responsable de la atención de peticiones, consultas, reclamos de los usuarios y/o clientes potenciales, así como los requerimientos relacionados con información personal de los trabajadores y extrabajadores de la empresa y los provenientes de contratistas y/o proveedores, a la Dirección Administrativa y Financiera de la empresa, ubicada en la Avenida Altos del Jardín N. 12 de la ciudad de Bucaramanga.</p>

            <h3 class="privacy__subtitle">TRATAMIENTO Y FINALIDAD</h3>
            <p class="privacy__paragraph">Los datos recabados por SYSTEMICO serán tratados con el grado de protección adecuado, exigido por la Ley y en este sentido el responsable del tratamiento se compromete a tratar los datos con la finalidad exclusiva para la que fueron recolectados.</p>
            <p class="privacy__paragraph">SYSTEMICO recopila datos de sus usuarios y clientes potenciales con los siguientes fines.</p>
            <ul class="pl-4">
              <li>
                <p class="privacy__paragraph">Identificarlo como usuario de nuestros servicios y adelantar las gestiones necesarias para su mejor prestación.</p>
              </li>
              <li>
                <p class="privacy__paragraph">Ofrecerle información relacionada con nuestros productos, servicios, ofertas, promociones, alianzas, concursos, así como los de nuestras compañas vinculadas o asociadas.</p>
              </li>
              <li>
                <p class="privacy__paragraph">Envío de información correspondiente al estado de su cuenta y obligaciones.</p>
              </li>
              <li>
                <p class="privacy__paragraph">Contactar a cualquier medio que haya registrado para el agendamiento de visitas propias de la prestación del servicio.</p>
              </li>
              <li>
                <p class="privacy__paragraph">Informar sobre nuevos productos o servicios relacionados con el o los servicios contratado(s) o adquirido(s).</p>
              </li>
              <li>
                <p class="privacy__paragraph">Informar sobre cambios de nuestros productos, servicios y tarifas.</p>
              </li>
              <li>
                <p class="privacy__paragraph">Evaluar la calidad del servicio.</p>
              </li>
              <li>
                <p class="privacy__paragraph">Remisión de publicidad, ofertas comerciales y promocionales por parte de SYSTEMICO y sus aliados.</p>
              </li>
            </ul>

            <p class="privacy__paragraph">SYSTEMICO recopila datos de sus trabajadores y/o colaboradores con los siguientes fines:</p>

            <ul class="pl-4">
              <li>
                <p class="privacy__paragraph">Identificarlo como trabajador o extrabajador.</p>
              </li>
              <li>
                <p class="privacy__paragraph">Determinar la idoneidad de sus trabajadores para el desempeño de las funciones asignadas.</p>
              </li>
              <li>
                <p class="privacy__paragraph">Hacer seguimiento a su desempeño laboral y/o académico.</p>
              </li>
              <li>
                <p class="privacy__paragraph">Calificar y evaluar su rendimiento.</p>
              </li>
              <li>
                <p class="privacy__paragraph">Ejecutar y cumplir los contratos.</p>
              </li>
              <li>
                <p class="privacy__paragraph">Garantizar su seguridad y cuidado personal.</p>
              </li>
              <li>
                <p class="privacy__paragraph">Vigilar y evaluar su comportamiento.</p>
              </li>
              <li>
                <p class="privacy__paragraph">Consultar, procesar, solicitar y reportar a cualquier entidad que maneje o administre bases de datos toda la información referente a su condición laboral y/o tributaria.</p>
              </li>
              <li>
                <p class="privacy__paragraph">Mantener el normal desarrollo de la relación contractual.</p>
              </li>
              <li>
                <p class="privacy__paragraph">Cumplir disposiciones legales, reglamentarias y/o regulatorias.</p>
              </li>
            </ul>

            <p class="privacy__paragraph">SYSTEMICO recopila datos de sus contratistas y proveedores con los siguientes fines:</p>

            <ul class="pl-4">
              <li>
                <p class="privacy__paragraph">Pedir cotizaciones de servicios y productos.</p>
              </li>
              <li>
                <p class="privacy__paragraph">Invitarlos a participar en procesos contractuales.</p>
              </li>
              <li>
                <p class="privacy__paragraph">Verificar el envío y recibo de documentos.</p>
              </li>
              <li>
                <p class="privacy__paragraph">Actualizar información.</p>
              </li>
              <li>
                <p class="privacy__paragraph">Pedir aclaraciones a las propuestas.</p>
              </li>
              <li>
                <p class="privacy__paragraph privacy__paragraph--end">Generar los procesos contractuales.</p>
              </li>
            </ul>

            <h3 class="privacy__subtitle">DERECHOS DEL TITULAR DE LOS DATOS</h3>
            <p class="privacy__paragraph">Como titular de los datos recolectados por SYSTEMICO, conforme a lo establecido en la Ley 1581 de 2012 y el Decreto 1377 de 2013, toda persona puede hacer uso de los siguientes derechos:</p>
            <ul class="pl-4">
              <li>
                <p class="privacy__paragraph">Derecho de Acceso: Es el derecho que tiene toda persona para conocer si sus datos personales están siendo objeto de tratamiento, se encuentran incluidos en la base de datos, que finalidad y origen tienen estos, así como las cesiones realizadas o previstas a terceros. Para ejercer su derecho podrá presentar una solicitud ante el Responsable del Tratamiento o por medio de los canales de contacto ya especificados.</p>
              </li>
              <li>
                <p class="privacy__paragraph">Es importante resaltar que el titular podrá consultar de forma gratuita sus datos personales al menos una vez cada mes calendario y cada vez que existan modificaciones sustanciales de las Polóticas de Tratamiento de datos personales. Cuando la consulta se realice más de una vez cada mes SYSTEMICO, como propietario de la base de datos, podrá cobrar los gastos de envío, reproducción y si se requiere certificación de documentos.</p>
              </li>
              <li>
                <p class="privacy__paragraph">Derecho de Actualización y Rectificación: Es el derecho que tiene cada persona como titular de los datos para que se actualicen y corrijan los datos personales que se encuentran en las bases de datos propiedad de SYSTEMICO, cuando estos hayan cambiado o cuando exista un error, sean inexactos o incompletos. Para ello igualmente se debe presentar una solicitud de información que debe contener, adicionalmente a lo señalado en el punto anterior sobre el acceso a datos personales, las modificaciones a realizarse y aportar la documentación que sustente su petición.</p>
              </li>
              <li>
                <p class="privacy__paragraph">Derecho de Supresión: Es el derecho que tiene cada persona como usuario para que se supriman sus datos personales en las bases de datos propiedad de SYSTEMICO, en los siguientes casos: (i) cuando el tratamiento de los mismos no se ajuste a lo dispuesto por la Ley de Protección de Datos (Ley 1581 de 2012) siempre y cuando la Superintendencia de Industria y Comercio haya determinado que en el Tratamiento el Responsable o Encargado han incurrido en conductas contrarias al ordenamiento y (ii) en virtud de la solicitud libre y voluntaria del Titular del dato, siempre y cuando no exista una obligación legal o contractual que imponga al Titular el deber de permanecer en la referida base de datos. La supresión da lugar al bloqueo de sus datos, esto quiere decir que los datos se conservan únicamente a disposición de autoridades competentes, con fines de seguridad y para atender las posibles responsabilidades nacidas del tratamiento.</p>
              </li>
              <li>
                <p class="privacy__paragraph privacy__paragraph--end">Presentación de quejas: Como titular de los datos tiene derecho de presentar ante la Superintendencia de Industria y Comercio quejas por infracciones asociadas al uso de sus datos personales, siempre y cuando haya agotado el trámite de consulta o reclamo ante SYSTEMICO.</p>
              </li>
            </ul>

            <h3 class="privacy__subtitle">ATENCIÓN DE PETICIONES, CONSULTAS, RECLAMOS Y PROCEDIMIENTOS</h3>
            <p class="privacy__paragraph">Señor suscriptor, usuario y/o cliente potencial si usted desea hacer uso de los derechos que le asisten puede hacerlo por los siguientes medios:</p>
            <ul class="pl-4 margin-margin-bottom-3">
              <li>
                <p class="privacy__paragraph">Comunicación remitida al Responsable de la atención de peticiones de SYSTEMICO en la Avenida Altos del Jardín N° 12 (Bucaramanga, Santander - Colombia).</p>
              </li>
              <li>
                <p class="privacy__paragraph">A través del correo electrónico <a href="mailto:cao@systemico.co">cao@systemico.co</a></p>
              </li>
            </ul>
            <p class="privacy__paragraph privacy__paragraph--end">Señor trabajador, extrabajador, contratista y/o proveedor si usted desea hacer uso de los derechos que le asisten como titular de la información puede elevar su petición, queja o reclamo mediante comunicación remitida a los Responsables ya establecidos en la presente política para la atención de peticiones, consultas y reclamos de SYSTEMICO en la Sede Administrativa Avenida Altos del Jardín N° 12 o a través del correo <a href="mailto:cao@systemico.co">cao@systemico.co</a></p>

            <h3 class="privacy__subtitle">APLICACIÓN</h3>
            <p class="privacy__paragraph privacy__paragraph--end">Nuestras políticas de privacidad se aplican a todas las bases de datos propiedad de SYSTEMICO.</p>

            <h3 class="privacy__subtitle">VIGENCIA</h3>
            <p class="privacy__paragraph">El aviso de privacidad aquí establecido entra en vigencia el día su publicación en página web.</p>
            <p class="privacy__paragraph">Las Bases de datos estarán vigentes durante el tiempo en que SYSTEMICO S.A.S., desarrolle su objeto social.</p>
            <p class="privacy__paragraph">Para mayor información relacionada con las disposiciones legales de protección de datos, y aquellas relacionadas con los procedimientos de reclamo respecto de las mismas, le sugerimos visitar la página web de la Superintendencia de Industria y Comercio (www.sic.gov.co). SYSTEMICO se reserva el derecho a modificar las Políticas de Tratamiento de datos personales en cualquier momento. Toda modificación será comunicada oportunamente a los titulares de los datos personales a través de la página web.</p>
          </b-col>
        </b-row>
      </b-container>
    </section>
    <footerC/>
  </main>
</template>

<script>
import footerC from '../../../../systemico.ui/components/footers/FooterSystemicoComponent.vue'
import navbarSection from '@/components/basic/navbarSection.vue'
import HeaderSystemico from "../../../../systemico.ui/components/headers/HeaderSystemicoComponent.vue";

export default {
  name: 'terms',
  props: {
  },
  components: {
    HeaderSystemico,
    footerC,
    navbarSection
  },
  metaInfo: {
    title: 'Aviso de privacidad.'
  },
  props: {
    lang: String
  },
  data: function () {
    return {
    }
  },
  methods: {
  }
}
</script>

<style lang="scss" scoped>
@import '../../../../systemico.ui/variables.scss';
  .privacy {
    font-size: 1rem;
    margin-top: 3rem;
    .content{
      padding: 20px;
    }
    &__title {
      margin: 0 0 2rem;
      font-family: $font-mulish;
      font-size: 2rem;
      font-weight: 600;
      color: $color-black;
      text-align: center;
      position: relative;
      z-index: 5;
    }
    &__subtitle {
      margin: 0 0 2rem;
      font-size: 2rem;
      font-weight: 600;
      color: $color-black;
      position: relative;
      z-index: 5;
    }
    &__paragraph {
      margin: 0 0 2rem;
      font-size: 1rem;
      font-weight: normal;
      color: $color-black;
      text-align: justify;
      position: relative;
      z-index: 5;
      &--end {
        margin-bottom: 6rem;
      }
    }
    &__line {
      width: 9rem;
      height: 0.4rem;
      margin: 0 auto 6rem;
      border-radius: 0.4rem;
      background-color: $color-gray;
      display: block;
    }
  }
</style>
