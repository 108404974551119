<template>
  <b-nav class="footer" fixed="bottom">
      <b-nav-item class="footer-item" :href="$props.url"><b>{{$props.name}}</b> - {{$props.slogan}}</b-nav-item>
  </b-nav>
</template>

<script>
  export default {
    name: 'FooterComponent',
    props: {
      name: String,
      url:String,
      slogan:String
    }
  }
</script>
<style lang="scss" scoped>
.footer {
  border-top: 1px solid #dddddd;
  color: #222222;
  font-size: 1em;
  .footer-item {
    font-size: 0.8rem;
    .nav-link {
      color: #777777;
    }
  }
  .footer-item:hover {
    color: #444444;
  }
}
</style>