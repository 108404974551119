<template>
  <main class="section">
    <articlec/>
  </main>
</template>

<script>
import articlec from '@/components/landing/article.vue'

export default {
  name: "article",
  components: {
    articlec
  },
  metaInfo: {
    title: 'Systemico: Acerca de nosotras.',
  },
  data: function () {
    return {
    };
  },
  methods: {},
};
</script>
