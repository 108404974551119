<template>
  <section>
    <b-nav align="center">
      <b-nav-item href="#what">{{ items[$props.lang][0].name }}</b-nav-item>
      <b-nav-item href="#benefits">{{ items[$props.lang][1].name }}</b-nav-item>
      <b-nav-item href="#features">{{ items[$props.lang][2].name }}</b-nav-item>
      <b-nav-item href="#plans">{{ items[$props.lang][3].name }}</b-nav-item>
    </b-nav>
  </section>
</template>
<script>
export default {
  name: 'ProductNavComponent',
  components: {
  },
  props: {
    lang: {
      default: 'EN',
      type: String
    },
    background:{
      default: '#fefefe',
      type: String
    },
    textColor: {
      default: '#222222',
      type: String
    }
  },
  beforeMount() {
    this.lang = this.lang.toUpperCase()
  },
  data: function () {
    return {
      items: {
        ES: [
          { id: 1, name: 'Descripción' },
          { id: 2, name: 'Beneficios' },
          { id: 3, name: 'Características' },
          { id: 4, name: 'Planes y Precios' }
        ],
        EN: [
          { id: 1, name: 'Description' },
          { id: 2, name: 'Benefits' },
          { id: 3, name: 'Features' },
          { id: 4, name: 'Pricing' }
        ]
      }
    }
  }
}
</script>
<style lang="scss" scoped>
  .nav{
    background-color: v-bind(background);
    position: sticky;
    top: 0;
    border-bottom-right-radius: 25%;
    border-bottom-left-radius: 25%;
  }
  .nav-link{
    font-size: 1.3rem;
    color:v-bind(textColor);
    font-weight:400;
    margin-left:1em;
    border-right: 2px dotted v-bind(textColor);
  }
  .nav-link:hover{
    font-size: 1.3rem;
    text-decoration: underline;
  }
</style>
