<template>
  <section class="products_description" id="features" :style="'background:'+$props.background">
    <h2 class="title pt-3 text-center display-3" v-html="title" ></h2>
    <div :style="'background:'+$props.background" v-for="characteristic in $props.characteristics" :key="characteristic.id">
      <div class="p-5 text-right"  v-if="characteristic.id % 2 == 0">
        <b-row cols="12">
          <b-col md="6" sm="12">
            <h2 class="subtitle" :style="'color:'+$props.subtitleColor" v-html="characteristic.title"></h2>
            <p class="description" :style="'color:'+$props.contentColor" v-html="characteristic.description"></p>
          </b-col>
          <b-col md="6" sm="12">
            <b-img class="img-fluid" :style="$props.imgStyle" :src="characteristic.image"></b-img>
          </b-col>
        </b-row>

      </div>
      <div class="p-5 text-left" :style="'background:'+$props.backgroundAdditional" v-else>
        <b-row cols="12">
          <b-col md="6" sm="12">
            <b-img class="img-fluid" :style="$props.imgStyle" :src="characteristic.image"></b-img>
          </b-col>
          <b-col md="6" sm="12">
            <h2 class="subtitle" :style="'color:'+$props.subtitleColor" v-html="characteristic.title"></h2>
            <p class="description" :style="'color:'+$props.contentColor" v-html="characteristic.description"></p>
          </b-col>
        </b-row>
      </div>
    </div>
  </section>
</template>
<script>
export default {
  name: 'ProductDescriptionComponent',
  props: {
    characteristics: Array,
    title: String,
    background: {
      default: '#fefefe',
      type: String
    },
    backgroundAdditional: {
      default: '#f8f8f8',
      type: String
    },
    titleColor:{
      default: '#0b1c98',
      type: String
    },
    subtitleColor:{
      default: '#222222',
      type: String
    },
    contentColor: {
      default: '#222222',
      type: String
    },
    imgStyle: {
      default: 'border-radius:20px;overflow:hidden;border:5px solid #fefefe;',
      type: String
    }
  },
  beforeMount() {
    if (this.title === undefined){
      this.title = 'Product Description.'
    }
  }
}
</script>
<style lang="scss" scoped>
  @import "../../variables.scss";
  .products_description{
    /**border-bottom: 2px solid #f0f0f0;**/
    .title{
      font-size: 2.5rem;
      line-height: 2.7rem;
      text-align: center;
      color:v-bind(titleColor);
      font-weight:bolder;
      margin-bottom: 1rem;
      padding-left: 10%;
      padding-right: 10%;
    }
    .subtitle{
      font-size:3rem;
      text-align: center;
      margin-top:2rem;
      font-weight:bold;
    }
    .description{
      font-size:1.5rem;
      text-align: center;
      margin-top:2rem;
    }
  }


  @media (max-width: 768px) {
    .products_description{
      .title{
        font-size:2.5rem;
      }
      .subtitle{
        font-size:2rem;
      }
    }
  }
</style>
