<template>
  <!-- CURRENCY SELECTOR -->
  <b-form-select v-model="$props.selCurrency" @change="selectCurrency">
    <b-form-select-option v-for="currency in $props.currencies" :key="currency.value" :value="currency.value" v-html="currency.text[$props.lang]"></b-form-select-option>
  </b-form-select>
</template>
<script>
export default {
  name: 'CurrencySelector',
  props:{
    selCurrency: {
      type: String,
      default: 'USD'
    },
    lang: {
      type: String,
      default: 'EN'
    },
    currencies: {
      type: Array,
      default: [
          {
            value: 'USD',
            text: {
              EN: 'USD - United States Dollar',
              ES: 'USD - Dólar Estadounidense',
              FR: 'USD - Dollar des États-Unis'
            }
          },
          {
            value: 'COP',
            text: {
              EN: 'COP - Colombian Peso',
              ES: 'COP - Peso Colombiano',
              FR: 'COP - Peso Colombien'
            }
          },
          {
            value: 'CAD',
            text: {
              EN: 'CAD - Canadian Dollar',
              ES: 'CAD - Dólar Canadiense',
              FR: 'CAD - Dollar Canadien'
            }
          }
        ]
    }
  },
  data(){
    return {

    }
  },
  methods: {
    selectCurrency(){
      this.$emit('onChange', this.selCurrency)
    }
  }
}
</script>