<template>
  <div class="home pt-5">
    <BannerMultiProductComponent :items="banner_items[$props.lang]" :lang="$props.lang"  />
    <products :lang="$props.lang" currency="USD" alone="products"/>
    <!--<clients lang="es"/>-->
    <!--<testimonials lang="es"/>
    <contact lang="es"/>
    <modalTranslate @changeLanguage="changeLanguage"/>
    <modalPopup />-->
  </div>
</template>

<script>
import products from '@/components/landing/products.vue'
import BannerMultiProductComponent from '@/../../systemico.ui/components/banners/BannerMultiProductComponent.vue'
/**import clients from '@/components/landing/clients.vue'
import testimonials from '@/components/landing/testimonials.vue'
import contact from '@/components/landing/contact.vue'
import footerC from '@/../../systemico.ui/components/footers/FooterSystemicoComponent.vue'
import modalTranslate from '@/components/modals/modalTranslate.vue'
import modalPopup from '@/components/modals/modalPopup.vue'
import HeaderSystemicoComponent from '../../../systemico.ui/components/headers/HeaderSystemicoComponent.vue'**/

export default {
  name: 'ProductsView',
  props:{
    lang: {
      type: String,
      default: 'EN'
    }
  },
  data: function () {
    return {
      headerFixed: false,
      banner_items: {
        ES: [
          {
            id: 1,
            title: 'Soluciones de software para transformar tu equipo y empresa',
            subtitle: 'Software para simplificar y acelerar procesos',
            image: 'https://cdn.systemico.net/systemico/web/headers/header_15.svg',
            backgroundImage: '',
            buttons: [
              { id: 1, text: 'Contactactar Agente', variant: 'orange', type: 'contact' }
            ],
            show: true
          }
        ],
        EN: [
          {
            id: 1,
            title: '<span class="text-secondary">Software solutions</span> to <span class="text-dark">transform your team and business</span>',
            subtitle: 'Software to improve, simplify and accelerate processes.',
            image: 'https://cdn.systemico.net/systemico/web/headers/header_15.svg',
            backgroundImage: '',
            buttons: [
              { id: 1, text: 'Contact Agent', variant: 'orange', type: 'contact' }
            ],
            show: true
          }
        ],
        FR: [
          {
            id: 1,
            title: "Solutions pour optimiser votre entreprise.",
            subtitle: "Logiciels pour simplifier et accélérer les processus.",
            image: "https://cdn.systemico.net/systemico/web/headers/header_15.svg",
            backgroundImage: "",
            buttons: [
              { id: 1, text: "Contacter l'agent", variant: "secondary", link: "/products", type: "meeting" }
            ],
            show: true
          }
        ]
      },
      benefits: [
        { id: 1, title: 'Soporte Permanente', description: 'Equipo experto que garantiza el cumplimiento de las metas del servicio.', icon: 'https://cdn.systemico.net/systemico/icons/icon--soporte.svg' },
        { id: 2, title: 'Experiencia', description: '17 años en el mercado con errores y aprendizajes que fortalecieron nuestro enfoque, resiliencia y visión.', icon: 'https://cdn.systemico.net/systemico/icons/icon-code.svg' },
        { id: 3, title: 'Empatía', description: 'Orgullosamente Colombianos que vive y enfrentan los retos de nuestro entorno y economía.', icon: 'https://cdn.systemico.net/systemico/icons/icon--cool.svg' },
        { id: 4, title: 'Confianza', description: 'Clientes y aliados que garantizan nuestra experiencia y motivación.', icon: 'https://cdn.systemico.net/systemico/icons/icon-check--orange.svg' }
      ],
      availableLangs: [
        {id:1, lang: 'EN', name: 'English', path:'/products'},
        {id:2, lang: 'ES', name: 'Español', path:'/products/es'},
        {id:3, lang: 'FR', name: 'Francais', path:'/products/fr'}
      ]
    }
  },
  components: {
    BannerMultiProductComponent,
    products,
  },
  beforeMount () {
    this.$emit('changeLang', this.lang)
    this.$emit('showLang', true)
    this.$emit('setAvailableLanguages', this.availableLangs)
  },
  methods: {}
}
</script>
<style>
@import "../../../systemico.ui/basic.scss";
</style>
