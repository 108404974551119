<template>
  <section class="contact" id="contact">
    <b-container fluid="xl">
      <b-row>
        <b-col cols="12" md="6">
          <h2 class="contact__title">
            {{ title[$props.lang] }}
            <div class="line"></div>
          </h2>
          <h3 class="contact__subtitle">
            {{ subtitle[$props.lang] }}
          </h3>

          <contactForm :lang="$props.lang"/>

          </div>
        </b-col>
        <b-col cols="12" md="6">
          <div class="contact__image">
            <img :src="$cdn + 'images/image-map--xl.png'" alt="image section" />
          </div>
        </b-col>
      </b-row>
    </b-container>
  </section>
</template>

<script>
import buttonPrimary from "@/components/basic/buttonBasic.vue";
import contactForm from "@/../../systemico.ui/components/formContact.vue"
export default {
  name: "contact",
  props: {
    lang: String
  },
  beforeMount(){
    if(this.lang === undefined){
      this.lang='es'
    }
  },
  components: {
    buttonPrimary,
    contactForm
  },

  data: function () {
    return {
      title: {es: "Contacta con nosotros", en: "Contact us"},
      subtitle: {es: "Dinos todo lo que necesitas.", en: "How can we help you?"},
      description:
        {
          es: "Estamos para ayudarte y cumplir con tu necesidad en el menor tiempo posible.",
          en: "We are here to help you and fulfill your needs in the shortest time possible."
        },
      buttons: [
        {
          text: {
            es: "Cotizar",
            en:"Quotes"
          },
          class: "button--secundary",
          link: "/support/quote" },
        {
          text: {
            es: "Contáctanos",
            en:"Contact Us"
          },
          class: "button--secundary",
          link: "/support/registro",
        },
        {
          text: {
            es: "Soporte",
            en: "Support"
          },
          class: "button--secundary",
          link: "/support" },
      ],

      whatsapp: {
        text: "+1 (314) 988-3020",
        class: "button--whatsapp",
        link: "",
      },

      email: {
        text: "hello@systemico.net",
        class: "button--black",
        link: "",
      }
    };
  },
  methods: {
    funcioRedirect: function (link) {
      window.location = link;
    },
  },
};
</script>
<style lang="scss" scoped>
@import "../../../../systemico.ui/variables.scss";
.contact {
    padding: 4rem 0;
    background-image: url(https://cdn.systemico.net/systemico/images/image-grid.svg);

    a:hover {
        text-decoration: none;
    }

    &__title {
        margin: 0 0 0.5rem;
        font-family: $font-quicksand;
        font-size: 2.2rem;
        font-weight: bold;
        color: $color-black;
        line-height: 2.4rem;
        text-align: center;
        text-transform: capitalize;

        .line {
            width: 2rem;
            height: 0.4rem;
            border-radius: 0.4rem;
            background-color: $color-orange;
            display: inline-block;
            position: relative;
            left: -0.5rem;
        }
    }

    &__subtitle {
        margin: 0 0 2rem;
        font-family: $font-quicksand;
        font-size: 2.2rem;
        font-weight: normal;
        color: $color-celeste;
        line-height: 3rem;
        text-align: center;
    }

    &__description {
        margin: 0 0 3rem;
        font-family: $font-mulish;
        font-size: 2rem;
        font-weight: bold;
        color: $color-black;
        line-height: 1.8rem;
        text-align: justify;
    }

    &__image {
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;

        img {
            width: 100%;
            max-width: 45rem;
            border-radius: 0 2rem;
        }
    }

    &__whatsapp {
        display: flex;
        align-items: center;

        .icon {
            width: 4rem;
            max-width: 4rem;
            height: 4rem;
            margin-right: 2rem;
            border-radius: 50%;
            // background-color: #00D189;
            display: flex;
            align-items: center;
            justify-content: center;

            img {
                width: 100%;
                height: 100%;
            }
        }
    }

    &__email {
        display: flex;
        align-items: center;

        .icon {
            width: 4rem;
            max-width: 4rem;
            height: 4rem;
            margin-right: 2rem;
            border-radius: 50%;
            background-color: black;
            display: flex;
            align-items: center;
            justify-content: center;

            img {
                width: 2.4rem;
            }
        }
    }

    .button--secundary {
        width: 100%;
        margin-bottom: 3rem;
    }

    // responsive para dispositivos de tablets en adelante
    @media (min-width: $breakpoint-tablet) {
        padding: 5rem 0 6rem;

        &__title {
            font-size: 2.2rem;
            line-height: 2.4rem;
            text-align: left;
        }

        &__subtitle {
            font-size: 2.1rem;
            line-height: 2.3rem;
            text-align: left;
        }

        .button--secundary {
            width: max-content;
            margin-right: 2rem;
            display: inline-block;
        }
    }
}
</style>
