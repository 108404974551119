<template>
  <main class="section">
    <!-- SECCIÓN 1 -->
		<section class="content content--blue mt-5 pt-5">
			<b-container fluid=lg>
				<b-row>
					<b-col>
						<titleSection :content='content'/>
					</b-col>
				</b-row>
				<b-row>
					<b-col cols='12' md=''></b-col>
					<b-col cols='12' md='10'>
						<div class="d-flex justify-content-center align-items-center mb-4">
							<img class="mr-5" :src="$cdn + 'icons/icon-design.svg'" alt="icon card">
							<h2 class="content__title content__title--white">Diseño y Modelado <br> de Software</h2>
						</div>
						<h3 class="content__subtitle content__subtitle--celeste text-center">Arquitectura de software y prototipado</h3>
						<p class="content__description content__description--white text-center">Systemico implementa varios aspectos para realizar el modelado de software, con el objetivo de simplificar la organización de la información y experiencia que el usuario va a tener con la tecnología.</p>
					</b-col>
					<b-col cols='12' md=''></b-col>
				</b-row>
			</b-container>
		</section>
		<!-- SECCIÓN 2 -->
		<div class="content content--gray pb-0">
			<b-container fluid=lg>
				<b-row>
					<b-col cols='12' md='6' lg='4'>
						<div class="content__card">
							<h3>Modelado de Procesos</h3>
							<p>Busca establecer de manera clara el paso a paso que se debe cumplir para que se cumplan las metas de negocio asociadas a cada solución a desarrollar. Para esto utilizamos el modelo SIPOC para detallar (Proveedores, Entradas, Procesos, Salidas y Clientes ).</p>
                            <a href="/enConstruccion" target="_blank" rel="noopener noreferrer">Ver gráfica SIPOC ></a>
						</div>
					</b-col>
					<b-col cols='12' md='6' lg='4'>
						<div class="content__card">
							<h3>Modelado de Datos</h3>
							<p>Systemico trabaja de manera detallada con estructuras de datos relacionales para lo cual utiliza diagramas relacionales que permitan inventariar todos los datos asociados a la solución.</p>
                            <a href="/enConstruccion" target="_blank" rel="noopener noreferrer">Ver diagrama relacional ></a>
						</div>
                        <div class="content__card--slogan">
							<p>Nuestros Servicios son soluciones innovadoras para potenciar tu negocio.</p>
						</div>
					</b-col>
					<b-col cols='12' md='6' lg='4'>
						<div class="content__card">
							<h3>Prototipado de la solución</h3>
							<p>Con esto se busca acercar a cada cliente con la solución final para evaluar así el cumplimiento de la expectativa. Se prototipa vista a vista la aplicación para mostrar la experiencia final y facilitar la experiencia de cada uno de los usuarios.</p>
                            <a href="/enConstruccion" target="_blank" rel="noopener noreferrer">Ver un diseño genial ></a>
						</div>
					</b-col>
				</b-row>
			</b-container>
		</div>
		<!-- SECCIÓN 3 -->
    <section class="content">
			<b-container fluid=lg>
				<b-row>
					<b-col cols='12'>
                        <h2 class="content__title text-center my-5">Principios de Diseño</h2>
					</b-col>
				</b-row>
				<b-row>
					<b-col cols='12' md='4'>
						<div class="content__card mt-5">
							<div class="icon">
								<img :src="$cdn + 'icons/icon__desing-1.svg'" alt="icon card">
							</div>
							<h3 class="text-center">Diseñar con Sentido</h3>
							<p>Es necesario entender el entorno donde va a funcionar la solución, quién la va a utilizar y qué busca mejorar en el día a día de cada usuario.</p>
						</div>
					</b-col>
					<b-col cols='12' md='4'>
						<div class="content__card mt-5">
							<div class="icon">
								<img :src="$cdn + 'icons/icon__desing-2.svg'" alt="icon card">
							</div>
							<h3 class="text-center">Simplifica la Comunicación</h3>
							<p>Un diseño claro enseña más que mil palabras, transmitir con colores, fuentes, fotos, ilustraciones, entre otros elementos harán que cada interacción deje un mensaje claro.</p>
						</div>
					</b-col>
					<b-col cols='12' md='4'>
						<div class="content__card mt-5">
							<div class="icon">
								<img :src="$cdn + 'icons/icon__desing-3.svg'" alt="icon card">
							</div>
							<h3 class="text-center">Experiencias Apropiadas</h3>
							<p>Orientar, enseñar y simplificar el uso del producto permite alcanzar metas de negocio con mayor agilidad y contribuir con la fidelización.</p>
						</div>
					</b-col>
					<b-col cols='12' md='4'>
						<div class="content__card mt-5">
							<div class="icon">
								<img :src="$cdn + 'icons/icon__desing-4.svg'" alt="icon card">
							</div>
							<h3 class="text-center">Accesibilidad</h3>
							<p>Aportar ideas que lleven a que más usuarios sin importar sus limitaciones accedan a la solución es un reto que se perfecciona con cada nueva iteración.</p>
						</div>
					</b-col>
				</b-row>
			</b-container>
		</section>
		<!-- SECCIÓN 4 -->
		<section class="content content--blue">
			<b-container fluid=lg>
				<b-row>
					<b-col cols='12' md=''></b-col>
					<b-col cols='12' md='10' lg='8'>
						<div class="content__develop">
							<h2>Desarrollamos el crecimiento de tu empresa!</h2>
							<p>Systemico es un aliado estratégico enfocado en hacer las cosas simples y efectivas, que a cada paso se agregue valor y que la inversión se ejecute lo más eficientemente posible.</p>
							<div class="opcions">
								<template v-for='(item, index) in buttons'>
									<buttonPrimry :configButton='item' :key='index' @funcionBtn="funcioRedirect(item.link)"/>
								</template>
							</div>
						</div>
					</b-col>
					<b-col cols='12' md=''></b-col>
				</b-row>
			</b-container>
		</section>
	</main>
</template>

<script>
import headerC from '../../../../../../systemico.ui/components/header.vue'
import titleSection from '@/components/ui/titleSection'
import footerC from '../../../../../../systemico.ui/components/footer.vue'


import buttonPrimry from '@/components/basic/buttonBasic.vue'

export default {
  name: "design",
  components: {
      titleSection,
      buttonPrimry
	},
	metaInfo: {
		title: 'Systemico: Servicio de diseño de aplicaciones web y móviles.'
	},
	data: function () {
		return {
			content: {
				title: 'Servicios',
				subtitle: '/ Diseño y Modelado de Software'
			},
			buttons: [
				{ text: 'Cotizar proyecto', class: 'button--secundary', link: '/support' },
				{ text: 'Solicitar servicio', class: 'button--secundary', link: '/support/registro' },
				{ text: 'Contáctanos', class: 'button--secundary', link: '/support' }
			]
		}
	},
	methods: {
		funcioRedirect: function (link) {
			window.location=link
		}
	}
};
</script>
