import { render, staticRenderFns } from "./ContactComponent.vue?vue&type=template&id=522b597a&scoped=true"
import script from "./ContactComponent.vue?vue&type=script&lang=js"
export * from "./ContactComponent.vue?vue&type=script&lang=js"
import style0 from "./ContactComponent.vue?vue&type=style&index=0&id=522b597a&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../src/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "522b597a",
  null
  
)

export default component.exports