<template>
    <main class="section">
    <!-- seccion 1 -->
		<section class="content content--blue mt-5 pt-5">
			<b-container fluid=lg>
				<b-row>
					<b-col>
						<titleSection :content='content'/>
					</b-col>
				</b-row>
				<b-row>
					<b-col cols='12' md=''></b-col>
					<b-col cols='12' md='10'>
						<div class="d-flex justify-content-center align-items-center mb-4">
							<img class="mr-5" :src="$cdn + 'icons/icon-software.svg'" alt="icon card">
							<h2 class="content__title content__title--white">Pruebas <br> de Software</h2>
						</div>
						<h3 class="content__subtitle content__subtitle--celeste text-center">Validaciones y cumplimiento funcional del software</h3>
						<p class="content__description content__description--white text-center">Realizar pruebas es igual de importante que implementar el software, acorde a esto Systemico ha apropiado diferentes modelos de prueba que incrementan la calidad de cada iteración para mitigar los riesgos e incrementar la viabilidad del proyecto; para esto se realizan diferentes acciones asociadas a partir de diferentes técnicas y herramientas.</p>
					</b-col>
					<b-col cols='12' md=''></b-col>
				</b-row>
			</b-container>
		</section>
		<!-- index -->
		<div class="content__index">
			<a href="#Description">Descripción</a>
			<a href="#Prices">Precios</a>
		</div>
		<!-- seccion 2 -->
		<div class="content content--gray pb-0" id="Description">
			<b-container fluid=lg>
				<b-row>
					<b-col cols='12' md='6'>
						<div class="content__card">
							<h3>Pruebas Funcionales</h3>
							<p>Las pruebas funcionales se definen teniendo como fuente los requerimientos del sistema, estas pruebas validan y verifican que el producto cumple con lo especificado y hace lo que debe y cómo lo tiene que hacer dando también una idea del grado de calidad del software.</p>
							<p class="text-orange">Pruebas de Humo</p>
							<p>Este tipo de pruebas es una revisión rápida inicial de la versión de software entregada por desarrollo donde se verificará de forma general sin entrar en detalle las principales funcionalidades del mismo y se asegurará que no tiene defectos que interrumpa el funcionamiento básico del mismo, para que el equipo de testing pueda seguir probando entrando más en detalle.</p>
							<p class="text-orange">Pruebas Estructurales </p>
							<p>Se realizan para ejecutar la estructura interna del producto intentando ejecutar todos los caminos posibles del código.</p>
							<p class="text-orange">Pruebas de Regresión</p>
							<p>Es decir, evita que al introducir nuevos cambios en un software se obtengan comportamientos no deseados o defectos en otros módulos no modificados.</p>
							<p class="text-orange">Pruebas de Sanidad</p>
							<p>Se refieren a un conjunto de pruebas que se ejecutan para comprobar que todo funciona correctamente después de alguna intervención o modificación.</p>
							<p class="text-orange">Pruebas de Mono</p>
							<p>Son las pruebas que se hacen sin atender mucho al funcionamiento teórico del producto, simplemente consiste en navegar por los distintos caminos del software sin un orden determinado e intentando ejecutar todas las opciones posibles.</p>
							<p class="text-orange">Pruebas de aceptación</p>
							<p>Estas pruebas se realizan en jornadas conjuntas entre el equipo técnico y el cliente para revisar uno a uno los requerimientos y verificar que la entrega está dentro los parámetros acordados en el documento de los requerimientos.</p>
						</div>
					</b-col>
					<b-col cols='12' md='6'>
						<div class="content__card">
							<h3>Pruebas no Funcionales</h3>
							<p class="text-orange">Pruebas de Carga</p>
							<p>Verifica el comportamiento del sistema frente a un crecimiento de carga de consultas, accesos, etc.</p>
							<p class="text-orange">Pruebas de Usabilidad</p>
							<p>Una prueba de usabilidad es una serie de prácticas y validaciones que se le hacen a una aplicación o un sitio web con el fin de comprobar la comodidad, facilidad o complejidad con la que se maneja.</p>
							<p class="text-orange">Pruebas de Rendimiento</p>
							<p>La prueba de rendimiento es una técnica de verificación de software no funcional que determina cómo la estabilidad, la velocidad, la escalabilidad y la capacidad de respuesta de una aplicación se mantiene bajo una determinada carga de trabajo.</p>
							<p class="text-orange">Pruebas de Accesibilidad</p>
							<p>Las pruebas de accesibilidad web son un subgrupo de las pruebas de usabilidad en las que los usuarios que se tienen en cuenta tienen discapacidades que afectan a su manera de utilizarlo. El objetivo final, tanto con respecto a la usabilidad como a la accesibilidad, es descubrir la facilidad con la que se puede utilizar un sitio web y utilizar esta información para mejorar futuros diseños e implementaciones. Tomado de: <a href="https://mosaic.uoc.edu/ac/le/es/m5/ud2/index.html" target="_blank" rel="noopener noreferrer">https://mosaic.uoc.edu/ac/le/es/m5/ud2/index.html</a></p>
							<p class="text-orange">Pruebas de Compatibilidad</p>
							<p>Las pruebas de compatibilidad son en las que se ejecuta el mismo producto en diferentes entornos, para chequear que funcionalmente se comportan igual.</p>
							<p class="text-orange">Pruebas de Seguridad</p>
							<p>Las pruebas de seguridad permiten obtener una visión externa del estado de seguridad del sistemas sin el conocimiento previo del software.</p>
							<p class="text-orange">Pruebas de Instalación</p>
							<p>Se verifica todo lo relacionado con el despliegue del producto: documentación, instalación de software, configuración pos-instalación, etc.</p>
							<p class="text-orange">Pruebas de Configuración</p>
							<p>Consiste en la realización de pruebas sobre el producto frente a diferentes configuraciones HW o SW para comprobar que su comportamiento es el esperado.</p>
							<a href="/login" target="_blank" rel="noopener noreferrer">Solicitar un servicio de pruebas ></a>
						</div>
					</b-col>
					<b-col cols='12' md='6' lg='4'>
						<div class="content__card">
							<h3>Pruebas Básicas</h3>
							<p>Son incluidas de manera obligatoria en cada proyecto y son las mínimas que se recomiendan.</p>
							<ul>
								<li>Prueba Funcional de Humo</li>
								<li>APrueba Funcional de Aceptación</li>
							</ul>
						</div>
					</b-col>
					<b-col cols='12' md='6' lg='4'>
						<div class="content__slogan">
							<p>Nuestros Servicios son soluciones innovadoras para potenciar tu negocio.</p>
						</div>
					</b-col>
					<b-col cols='12' md='6' lg='4'>
						<div class="content__card">
							<h3>Pruebas Adicionales</h3>
							<p>Requieren de la vinculación de recursos adicionales al proyecto por lo cuál se generan costos adicionales asociados al proyecto, toda prueba no incluidas en el listado de pruebas básicas se considera adicional.</p>
							<a href="/login" target="_blank" rel="noopener noreferrer">Solicitar un servicio de pruebas ></a>
						</div>
					</b-col>
				</b-row>
			</b-container>
		</div>
		<!-- seccion 5  -->
		<section class="content content--gray" id="Prices">
			<b-container>
				<b-row>
					<b-col>
						<div class="support__content">
							<b-table class="support__table" :items="pruebas" :fields="titulos"></b-table>
						</div>
					</b-col>
				</b-row>
			</b-container>
		</section>
		<!-- seccion 4 -->
        <section class="content content__slogan content__slogan--blue">
			<b-container>
				<b-row>
					<b-col>
						<p class="m-0">Vincula testers por demanda para tu proyecto y valida el producto con un público similar al objetivo.</p>
						<buttonPrimry class="mx-auto mt-5" :configButton='configButton' @funcionBtn="funcioRedirect('/service_support')"/>
					</b-col>
				</b-row>
			</b-container>
		</section>
		<!-- seccion 5 -->
		<section class="content content--blue">
			<b-container fluid=lg>
				<b-row>
					<b-col cols='12' md=''></b-col>
					<b-col cols='12' md='10' lg='8'>
						<div class="content__develop">
							<h2>Desarrollamos el crecimiento de tu empresa!</h2>
							<p>Systemico es un aliado estratégico enfocado en hacer las cosas simples y efectivas, que a cada paso se agregue valor y que la inversión se ejecute lo más eficientemente posible.</p>
							<div class="opcions">
								<template v-for='(item, index) in buttons'>
									<buttonPrimry :configButton='item' :key='index' @funcionBtn="funcioRedirect(item.link)"/>
								</template>
							</div>
						</div>
					</b-col>
					<b-col cols='12' md=''></b-col>
				</b-row>
			</b-container>
		</section>
	</main>
</template>

<script>
import headerC from '../../../../../../systemico.ui/components/header.vue'
import titleSection from '@/components/ui/titleSection'
import footerC from '../../../../../../systemico.ui/components/footer.vue'


import buttonPrimry from '@/components/basic/buttonBasic.vue'

export default {
  name: "testing",
  components: {
  titleSection,
  buttonPrimry
  },
  metaInfo: {
    title: 'Systemico: Testing de software y soluciones.'
  },
	data: function () {
		return {
			content: {
				title: 'Servicios',
				subtitle: '/ Pruebas de Software'
			},
			titulos: [
				{
					key: 'tipo',
					label: 'Plan'
				},
				{
					key: 'tiempo',
					label: 'Horas Mínimas'
				},
				{
					key: 'valor',
					label: 'Valor Hora'
				}
			],
			pruebas: [
				{ tipo: 'Pruebas de Carga', tiempo: '30', valor: '1000 USD' },
				{ tipo: 'Pruebas de Usabilidad', tiempo: '20', valor: '1000 USD' },
				{ tipo: 'Pruebas de Rendimiento', tiempo: '30', valor: '1000 USD' },
				{ tipo: 'Pruebas de Accesibilidad', tiempo: '30', valor: '2000 USD' },
				{ tipo: 'Pruebas de Compatibilidad', tiempo: '30', valor: '1000 USD' },
				{ tipo: 'Pruebas de Instalación', tiempo: '20', valor: '700 USD' },
				{ tipo: 'Pruebas de Configuración', tiempo: '30', valor: '700 USD' },
				{ tipo: 'Pruebas de Recuperación', tiempo: '30', valor: '700 USD' }
				// { tipo: '', tiempo: '', valor: '' }
			],
			configButton: { text: 'Saber más', class: 'button--secundary' },
			buttons: [
				{ text: 'Cotizar proyecto', class: 'button--secundary', link: '/support' },
				{ text: 'Solicitar servicio', class: 'button--secundary', link: '/support/registro' },
				{ text: 'Contáctanos', class: 'button--secundary', link: '/support' }
			]
		}
	},
	methods: {
		funcioRedirect: function (link) {
			window.location=link
		}
	}
};
</script>
