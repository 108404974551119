<template>
  <div class="landing"  @scroll="headerReact()" >
    <div class="mt-5  pt-5">
      <products/>
    </div>
    <testimonials/>
    <modalTranslate @changeLanguage="changeLanguage"/>
    <modalPopup />
  </div>
</template>

<script>
import banner from '@/components/landing/banner.vue'
import products from '@/components/landing/products.vue'
import software from '@/components/landing/software.vue'
import services from '@/components/landing/services.vue'
import methodology from '@/components/landing/methodology.vue'
import rocket from '@/components/landing/rocket.vue'
import team from '@/components/landing/team.vue'
import ceo from '@/components/landing/ceo.vue'
import clients from '@/components/landing/clients.vue'
import news from '@/components/landing/news.vue'
import testimonials from '@/components/landing/testimonials.vue'
import allies from '@/components/landing/allies.vue'
import contact from '@/components/landing/contact.vue'
import pradek from '@/components/landing/pradek.vue'
import modalTranslate from '@/components/modals/modalTranslate.vue'
import modalPopup from '@/components/modals/modalPopup.vue'
import social from '../../../../systemico.ui/components/social.vue'

export default {
  name: 'landing',
  data: function () {
    return {
      headerFixed: false
    }
  },
  components: {
    banner,
    products,
    software,
    services,
    methodology,
    rocket,
    team,
    ceo,
    clients,
    news,
    testimonials,
    allies,
    contact,
    pradek,
    modalTranslate,
    modalPopup,
    social
  },
  beforeMount() {},
  methods: {
    headerReact: function () {},
    typing: function () {
      this.$refs.banner.traduccion()
    },
    changeLanguage: function (idioma) {
      this.$refs.header.changeLanguage(idioma)
    }
  }
}
</script>
