import { render, staticRenderFns } from "./ButtonContactComponent.vue?vue&type=template&id=0f5ed8f4&scoped=true"
import script from "./ButtonContactComponent.vue?vue&type=script&lang=js"
export * from "./ButtonContactComponent.vue?vue&type=script&lang=js"
import style0 from "./ButtonContactComponent.vue?vue&type=style&index=0&id=0f5ed8f4&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../../../systemico.web/src/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0f5ed8f4",
  null
  
)

export default component.exports