<template>
    <section class="testimonials">
        <b-container fluid='md'>
            <b-row>
                <b-col>
                    <h2 class="testimonials__title">
                        {{ title[$props.lang] }}
                        <div class="line"></div>
                    </h2>
                </b-col>
            </b-row>
            <b-row>
                <b-col  cols='12' md='3' v-for='(item, index) in items' :key="index" class="p-4">
                    <div class="testimonials__card">
                        <img class="testimonials__icon" :src="$cdn + 'icons/'+ item.icon" alt="icon card">
                        <p class="testimonials__description" v-html="item.description[$props.lang]"></p>
                        <h3 class="testimonials__user">
                            {{ item.user }}
                        </h3>
                    </div>
                </b-col>
            </b-row>
        </b-container>
    </section>
</template>

<script>

export default {
  name: 'testimonials',
  props: {
    lang: String
  },
  data: function () {
    return {
      title: { es: 'Testimonios', en: 'Testimonials'},
      items: [
        {
          icon: 'icon-men--white.svg',
          description: {
            en: 'Systemico delivers high-quality code. They always use a good selection of tools and create products with great UX.',
            es: 'Systemico entrega un c&oacute;digo de alta calidad. Siempre utilizan diferentes herramientas que les permite crear soluciones con una experiencia de usuario genial.',
            fr: 'Systemico fournit un code de haute qualité. Ils utilisent toujours une bonne sélection d\'outils et créent des produits avec une excellente UX.'
          },
          user: 'Juan Barajas - Gerente General - CDA ITG'
        },
        {
          icon: 'icon-woman--white.svg',
          description: {
            es: 'Son innovadores, r&aacute;pidos al dar soporte y proactivos. Siempre nos estamos aprendiendo cosas nuevas con ellos.',
            en: 'They are innovative, supportive, and proactive. We always learn new things with them.',
            fr: 'Ils sont innovants, rapides à fournir un support et proactifs. Nous apprenons toujours de nouvelles choses avec eux.'
          },
          user: 'Rebeca Mejía - Gerente de Tecnología - Cajasan'
        },
        {
          icon: 'icon-woman--white.svg',
          description: {
            es: 'Systemico logra ajustar sus servicios y herramientas a las necesidades del cliente entregando una solución a la medida, con soporte agil, fácil de mantener y lo más importante, que ayuda a potenciar y acelerar procesos de la empresa para aumentar su productividad.',
            en: 'Systemico manages to adjust its services and tools to the client\'s needs, delivering a tailored solution, with agile support, easy to maintain, and most importantly, helping to enhance and accelerate the company\'s processes to increase productivity.',
            fr: 'Systemico parvient à ajuster ses services et outils aux besoins du client, en fournissant une solution sur mesure, avec un support agile, facile à maintenir et surtout, en aidant à améliorer et accélérer les processus de l\'entreprise pour augmenter la productivité.'
          },
          user: 'Laura Galvis-Carreño - PhD. Electrical & Computer Engineering - DeepScience'
        },
        {
          icon: 'icon-men--white.svg',
          description: {
            es: 'Son más de 12 años los que llevamos en funcionamiento con la colaboración de un grupo de personas y empresas maravillosas que nos han ayudado en el crecimiento de nuestra compañía, de manera explícita destacamos el gran y valioso aporte de la empresa Systemico que con su ardua labor y empeño han hecho que lo que alguna vez fue un sueño ahora es realidad, que con sus conocimientos en software nos han permitido estar a la vanguardia de este mundo tan cambiante. Gracias por ayudar a forjar nuestro éxito.',
            en: 'It has been more than 12 years since we have been in operation with the collaboration of a group of wonderful people and companies that have helped us grow our company. We explicitly highlight the great and valuable contribution of the company Systemico, which with its hard work and dedication has made what was once a dream now a reality, that with its knowledge in software has allowed us to be at the forefront of this ever-changing world. Thank you for helping to forge our success.',
            fr: 'Cela fait plus de 12 ans que nous sommes en activité avec la collaboration d\'un groupe de personnes et d\'entreprises merveilleuses qui nous ont aidés à faire croître notre entreprise. Nous soulignons explicitement la grande et précieuse contribution de l\'entreprise Systemico, qui, avec son travail acharné et son dévouement, a fait de ce qui était autrefois un rêve une réalité, qu\'avec ses connaissances en logiciels, nous a permis d\'être à la pointe de ce monde en constante évolution. Merci de nous avoir aidés à forger notre succès.'
          },
          user: 'Edison Calderon - Gerente General - CDA la Isla'
        },

      ]
    }
  }
}
</script>
