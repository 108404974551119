<template>
  <main class="section">
    <!-- seccion 1 -->
    <section class="content pt-5 mt-5">
      <titleSection :content="content" />
      <b-container fluid="lg" class="mt-5">
        <b-row>
          <b-col cols="12" md="5">
            <h2 class="content__title content__title--celeste mb-5">
              Una mirada a nuestra trayectoria
            </h2>
            <p class="content__subtitle">
              Systemico fue fundada el 17 de Octubre de 2006, como una empresa
              de 6 amigos con ganas de cambiar el mundo.
            </p>
          </b-col>
          <b-col cols="12" md="">
            <img
              class="d-block mx-auto w-50"
              :src="$cdn + 'history/construction.png'"
            />
          </b-col>
        </b-row>
      </b-container>
    </section>
    <!-- seccion 2 -->
    <section class="content content--gray">
      <b-container fluid="lg">
        <b-row>
          <b-col>
            <p class="content__description text-center mt-5">
              Nuestra Historia
            </p>
          </b-col>
        </b-row>

        <b-row>
          <b-col cols="12" md="3">
            <p
              class="
                content__description content__description--orange
                mt-5
                text-center
              "
            >
              2006 - 2007
            </p>
          </b-col>
        </b-row>
        <b-row>
          <b-col cols="12" md="">
            <div class="content__line">
              <div class="circle"></div>
              <div class="line"></div>
            </div>
          </b-col>
          <b-col cols="12" md="8">
            <div class="content__element w-100 mb-5">
              <div>
                <h3>Iniciamos nuestra aventura de software</h3>
                <p><i>¡Fácil, Rápido y Sencillo!..</i> Nuestro primer lema.</p>
              </div>
              <div class="img">
                <img :src="$cdn + 'history/Logo-systemico-2006.png'" />
              </div>
            </div>

            <div class="content__element w-100 mb-5">
              <div>
                <h3>Proyecto EFI</h3>
                <p>
                  La empresa Inversiones Díaz tiene la necesidad de una
                  herramienta que le ayude a gestionar las entradas, el
                  inventario y la facturación para su modelo de negocio.
                </p>
              </div>
              <div class="img">
                <img :src="$cdn + 'history/Software-EFI.png'" />
              </div>
            </div>

            <div class="content__element w-100 mb-5">
              <div>
                <h3>Proyecto MegaCDA</h3>
                <p>
                  En convenio con la empresa Soluciones Practicas de Ingeniería
                  - SPI LTDA desarrollamos una herramienta de revisiones técnico
                  mecánicas, que permite verificar y certificar el estado de
                  vehículos públicos y particulares.
                </p>
              </div>
              <div class="img">
                <img :src="$cdn + 'history/Img-MegaCDA.jpg'" />
              </div>
            </div>
          </b-col>
          <b-col cols="12" md=""></b-col>
        </b-row>

        <b-row>
          <b-col cols="12" md="3">
            <p
              class="
                content__description content__description--orange
                mt-5
                text-center
              "
            >
              2008 - 2009
            </p>
          </b-col>
        </b-row>
        <b-row>
          <b-col cols="12" md="">
            <div class="content__line">
              <div class="circle"></div>
              <div class="line"></div>
            </div>
          </b-col>
          <b-col cols="12" md="8">
            <div class="content__element w-100 mb-5">
              <div>
                <h3>Un retoque a nuestra identidad visual</h3>
                <p><i>Innovación + Estrategia</i></p>
              </div>
              <div class="img">
                <img :src="$cdn + 'history/Logo-systemico-2008.png'" />
              </div>
            </div>

            <div class="content__element w-100 mb-5">
              <div>
                <h3>Framework Jumíchica</h3>
                <p>
                  Inicia el desarrollo de un framework para acelerar el
                  desarrollo de aplicaciones web. Da sus primeros pasos
                  Jumíchica, por medio de un desarrollo importante para la
                  gestión del negocio de la empresa PROES INGENIEROS CONSULTORES
                  S.A.S.
                </p>
              </div>
              <div class="img small">
                <img :src="$cdn + 'history/Logo-Jumichica.jpg'" />
              </div>
            </div>

            <div class="content__element w-100 mb-5">
              <div>
                <h3>MAAT GKNOWLEDGE</h3>
                <p>
                  Se apoya a la empresa española MAAT GKNOWLEDGE en el
                  desarrollo de proyectos para empresas públicas y privadas
                  ibéricas en modelo de outsourcing; aportando personal,
                  modelos, experticia y conocimiento.
                </p>
              </div>
              <div class="img small">
                <img :src="$cdn + 'history/Logo-maat.png'" />
              </div>
            </div>
          </b-col>
          <b-col cols="12" md=""></b-col>
        </b-row>

        <b-row>
          <b-col cols="12" md="3">
            <p
              class="
                content__description content__description--orange
                mt-5
                text-center
              "
            >
              2010 - 2011
            </p>
          </b-col>
        </b-row>
        <b-row>
          <b-col cols="12" md="">
            <div class="content__line">
              <div class="circle"></div>
              <div class="line"></div>
            </div>
          </b-col>
          <b-col cols="12" md="8">
            <div class="content__element w-100 mb-5">
              <div>
                <h3>ArteColombia</h3>
                <p>
                  Se implementa la red de artesanos ArteColombia con foco
                  inicial en la Provincia Guanentina.
                </p>
              </div>
              <div class="img">
                <img :src="$cdn + 'history/Logo_artecolombia.jpg'" />
              </div>
            </div>

            <div class="content__element w-100 mb-5">
              <div>
                <h3>Unidad Systemico I+D+I</h3>
                <p>
                  Systemico gana la convocatoria para organizarse como unidad
                  I+D+i (Investigación, Desarrollo e innovación) con apoyo del
                  SENA y con asesorías de Inventta. Hacemos parte en la misión
                  Tecnológica a Silicon Valley - Estados Unidos para análisis de
                  tendencias de los ecosistemas de innovación.
                </p>
              </div>
              <div class="img">
                <img :src="$cdn + 'history/Img-solutions-2.jpg'" />
              </div>
            </div>
            <div class="content__element w-100 mb-5">
              <div>
                <h3>UNAB</h3>
                <p>
                  Se formalizan las alianzas con la Universidad Autónoma de
                  Bucaramanga (UNAB) para apoyar los procesos de consultoría
                  tecnológica.
                </p>
              </div>
              <div class="img">
                <img :src="$cdn + 'history/Logo-UNAB.png'" />
              </div>
            </div>

            <div class="content__element w-100 mb-5">
              <div>
                <h3>Grupo de Investigación Prisma</h3>
                <p>
                  Concretamos la alianza con el Grupo de Investigación Prisma
                  para el desarrollo de proyectos conjuntos.
                </p>
              </div>
              <div class="img">
                <img :src="$cdn + 'history/Img-solutions-2.jpg'" />
              </div>
            </div>

            <div class="content__element w-100 mb-5">
              <div>
                <h3>CETICS</h3>
                <p>
                  Participamos como miembros de CETICS -Clúster de Empresas de
                  Tecnología y Comunicaciones de Santander.
                </p>
              </div>
              <div class="img">
                <img :src="$cdn + 'history/logo-cetics.svg'" />
              </div>
            </div>
            <div class="content__element w-100 mb-5">
              <div>
                <h3>Midder</h3>
                <p>
                  Systemico crea su unidad estratégica enfocada en proyectos y
                  tecnología llamada SYSTEMICO Technology, hoy Midder.
                </p>
              </div>
              <div class="img small">
                <img :src="$cdn + 'history/logo-midder.png'" />
              </div>
            </div>
          </b-col>
          <b-col cols="12" md=""></b-col>
        </b-row>

        <b-row>
          <b-col cols="12" md="3">
            <p
              class="
                content__description content__description--orange
                mt-5
                text-center
              "
            >
              2012 - 2013
            </p>
          </b-col>
        </b-row>
        <b-row>
          <b-col cols="12" md="">
            <div class="content__line">
              <div class="circle"></div>
              <div class="line"></div>
            </div>
          </b-col>
          <b-col cols="12" md="8">
            <div class="content__element w-100 mb-5">
              <div>
                <h3>Proyecto My World</h3>
                <p>
                  Iniciamos el proceso de investigación para el desarrollo de
                  tecnologías para discapacitados, presentamos en el BETT SHOW
                  en Londres nuestro proyecto My World, plataforma de para
                  impulsar el aprendizaje de niños con discapacidad cognitiva
                  específicamente con síndrome de asperger.
                </p>
              </div>
              <div class="img">
                <img :src="$cdn + 'history/Logo-MyWorld.png'" />
              </div>
            </div>
            <div class="content__element w-100 mb-5">
              <div>
                <h3>JumiCloud</h3>
                <p>
                  Lanzamiento formal del JumiCloud “Tu empresa en las nubes con
                  manos libres” que establece un modelo de negocio basado en
                  tecnología que permite trabajar desde cualquier lugar de forma
                  confiable, ágil y segura.
                </p>
              </div>
              <div class="img small">
                <img :src="$cdn + 'history/Logo-Jumichica.jpg'" />
              </div>
            </div>
            <div class="content__element w-100 mb-5">
              <div>
                <h3>CETICS</h3>
                <p>
                  Hacemos parte de la Junta Directiva y el comité de proyectos
                  de innovación de CETICS.
                </p>
              </div>
              <div class="img">
                <img :src="$cdn + 'history/logo-cetics.svg'" />
              </div>
            </div>
            <div class="content__element w-100 mb-5">
              <div>
                <h3>Proyecto Vistrategy</h3>
                <p>
                  Desarrollo del proyecto Vistrategy para la planificación
                  estratégica en convenio con Power in Consulting Services Group
                  LTDA.
                </p>
              </div>
              <div class="img">
                <img :src="$cdn + 'history/Img-solutions-2.jpg'" />
              </div>
            </div>
            <div class="content__element w-100 mb-5">
              <div>
                <h3>LikeParrot</h3>
                <p>
                  Lanzamos al mercado nuestra primera versión de nuestra
                  plataforma LikeParrot, un servicio inteligente de atención en
                  línea para empresas que quieren fidelizar clientes desde su
                  sitio web.
                </p>
              </div>
            </div>
            <div class="content__element w-100 mb-5">
              <div>
                <h3>Plataforma KAPOK</h3>
                <p>
                  Desarrollo de KAPOK, plataforma para la gestión de la
                  arquitectura empresarial para el cliente Superintendencia de
                  Notariado y Registro –SNR en convenio con la firma Golden
                  Knowledge Integrated Solutions S.A.S.
                </p>
              </div>
              <div class="img small">
                <img :src="$cdn + 'history/Logo-SNR.png'" />
              </div>
            </div>
          </b-col>
          <b-col cols="12" md=""></b-col>
        </b-row>

        <b-row>
          <b-col cols="12" md="3">
            <p
              class="
                content__description content__description--orange
                mt-5
                text-center
              "
            >
              2014 - 2015
            </p>
          </b-col>
        </b-row>
        <b-row>
          <b-col cols="12" md="">
            <div class="content__line">
              <div class="circle"></div>
              <div class="line"></div>
            </div>
          </b-col>
          <b-col cols="12" md="8">
            <div class="content__element w-100 mb-5">
              <div>
                <h3>Certificación Internacional IT MARK</h3>
                <p>
                  Ejecutamos un proceso de rediseño empresarial basado en
                  servicios por demanda, alineando todo a nuestra planeación
                  estratégica al 2020. Nos certificamos como fbrica de software
                  gracias a la certificación internacional IT MARK otorgada por
                  el Instituto Europeo de Software. La certificación demuestra a
                  los clientes, proveedores, competidores, empleados y socios
                  que la empresa emplea las mejores prácticas reconocidas en su
                  sector. Esta enfocada a las PYME evaluando las empresas en
                  tres puntos importantes: • Gestión de negocio. • Ingeniería de
                  software, sistemas y servicios. • Gestión de seguridad.
                </p>
              </div>
              <div class="img small">
                <img :src="$cdn + 'history/logo-it-mark.svg'" />
              </div>
            </div>
            <div class="content__element w-100 mb-5">
              <div>
                <h3>Marca País TI</h3>
                <p>
                  Recibimos el reconocimiento de Marca TI País por parte del
                  Ministerio de Tecnologías de la Información y las
                  Comunicaciones de Colombia (MINTIC).
                </p>
                <p>
                  La Marca País TI busca representar de forma dinámica,
                  innovadora y competitiva a la industria Colombiana en el
                  ámbito internacional. Esta Marca es el esfuerzo conjunto del
                  Mintic, Marca País, Proexport, FEDESOFT y del sector privado
                  para mostrarle al mundo la importancia del sector TI
                  colombiano tanto en el mercado local como global.
                </p>
              </div>
              <div class="img small">
                <img :src="$cdn + 'history/Logo-marca-pais-ti.png'" />
              </div>
            </div>
            <div class="content__element w-100 mb-5">
              <div>
                <h3>Plataforma Cobrando</h3>
                <p>
                  Lanzamos al mercado nuestra plataforma Cobrando, enfocada en
                  la recuperación automática de cartera a partir de mensajes
                  SMS, Email y Voz como solución para las Pymes Latam.
                </p>
              </div>
              <div class="img small">
                <img :src="$cdn + 'history/Logo-cobrando.png'" />
              </div>
            </div>
          </b-col>
          <b-col cols="12" md=""></b-col>
        </b-row>

        <b-row>
          <b-col cols="12" md="3">
            <p
              class="
                content__description content__description--orange
                mt-5
                text-center
              "
            >
              2016 - 2017
            </p>
          </b-col>
        </b-row>
        <b-row>
          <b-col cols="12" md="">
            <div class="content__line">
              <div class="circle"></div>
              <div class="line"></div>
            </div>
          </b-col>
          <b-col cols="12" md="8">
            <div class="content__element w-100 mb-5">
              <div>
                <h3>Cambios estratégicos</h3>
                <p>
                  Cambiamos nuestra identidad institucional redefiniendo y
                  simplificando nuestro logo, colores, redes sociales y página
                  web. Realizamos una reestructuración empresarial para mejorar
                  la capacidad de desarrollo de software. Iniciamos el proceso
                  para el registro de las marcas Systemico y Focusmeet en
                  Colombia, Perú, Estados Unidos y Europa.
                </p>
              </div>
              <div class="img small">
                <img :src="$cdn + 'history/Logo-Systemico-2016.png'" />
              </div>
            </div>
            <div class="content__element w-100 mb-5">
              <div>
                <h3>GrumpyTurtle</h3>
                <p>
                  Lanzamiento de la plataforma GrumpyTurtle para el manejo
                  efectivo de los proyectos.
                </p>
                <p>
                  Reconocimiento de Facebook y su fondo de inversión FB-Start
                  para LikeParrot como una herramienta con alto potencial de
                  crecimiento.
                </p>
              </div>
              <div class="img">
                <img :src="$cdn + 'history/Logo-grumpy.svg'" />
              </div>
            </div>
            <div class="content__element w-100 mb-5">
              <div>
                <h3>Procolombia</h3>
                <p>
                  Se establece la alianza con Procolombia para hacer parte de
                  Marca País representando a Colombia y posicionándolo ante el
                  mundo como un lugar estratégico que ofrece soluciones. Su
                  objetivo es posicionar la imagen positiva del país en el
                  extranjero.
                </p>
              </div>
              <div class="img small">
                <img :src="$cdn + 'history/Logo-marca-pais-secundario.png'" />
              </div>
            </div>
            <div class="content__element w-100 mb-5">
              <div>
                <h3>Focusmeet</h3>
                <p>
                  Celebración del Convenio especial de cooperación N° 0258 de
                  2016 entre el SENA, el Centro de Productividad y
                  Competitividad del Oriente CPC y SYSTEMICO para el desarrollo
                  de FOCUSMEET.
                </p>
              </div>
              <div class="img">
                <img :src="$cdn + 'history/logo-focusmeet.svg'" />
              </div>
            </div>
            <div class="content__element w-100 mb-5">
              <div>
                <h3>Perú</h3>
                <p>
                  Realizamos la inmersión de negocios en Perú para interactuar
                  directamente con el mercado.
                </p>
              </div>
              <div class="img">
                <img :src="$cdn + 'history/Bandera-Peru.png'" />
              </div>
            </div>
          </b-col>
          <b-col cols="12" md=""></b-col>
        </b-row>

        <b-row>
          <b-col cols="12" md="3">
            <p
              class="
                content__description content__description--orange
                mt-5
                text-center
              "
            >
              2018
            </p>
          </b-col>
        </b-row>
        <b-row>
          <b-col cols="12" md="">
            <div class="content__line">
              <div class="circle"></div>
              <div class="line"></div>
            </div>
          </b-col>
          <b-col cols="12" md="8">
            <div class="content__element w-100 mb-5">
              <div>
                <h3>Proyectos</h3>
                <p>
                  • Iniciamos el proceso para la modernización tecnológica de
                  Cooperativa de Transportadores Del Sur – Cotrasur. <br />
                  • Nos convertimos en la fábrica de software de ISL S.A. <br />
                  • Recibimos a nuestro cliente DIT Social S.A.S., empresa
                  Social Fintech. <br />
                  • Arrancamos la optimización tecnológica de Concremovil S.A.S.
                  <br />
                  • Impulsamos nuestra unidad de I+D+i con el apoyo de
                  Colciencias. <br />
                  • Iniciamos la implementación del servicio de facturación
                  electrónica de Cajasan.
                </p>
              </div>
              <div class="img">
                <img :src="$cdn + 'history/Img-solutions-2.jpg'" />
              </div>
            </div>
          </b-col>
          <b-col cols="12" md=""></b-col>
        </b-row>

        <b-row>
          <b-col cols="12" md="3">
            <p
              class="
                content__description content__description--orange
                mt-5
                text-center
              "
            >
              2019
            </p>
          </b-col>
        </b-row>
        <b-row>
          <b-col cols="12" md="">
            <div class="content__line">
              <div class="circle"></div>
              <div class="line"></div>
            </div>
          </b-col>
          <b-col cols="12" md="8">
            <div class="content__element w-100 mb-5">
              <div>
                <h3>GrumpyTurtle</h3>
                <p>
                  Systemico gana la convocatoria línea de fomento a la
                  innovación y desarrollo tecnológico en las empresas - 2019 con
                  apoyo del SENA desarrolla GrumpyTurtle plataforma de
                  tecnología la gestión de proyectos de tecnología.
                </p>
              </div>
              <div class="img">
                <img :src="$cdn + 'history/Logo-grumpy.svg'" />
              </div>
            </div>
          </b-col>
          <b-col cols="12" md=""></b-col>
        </b-row>

        <b-row>
          <b-col cols="12" md="3">
            <p
              class="
                content__description content__description--orange
                mt-5
                text-center
              "
            >
              2020
            </p>
          </b-col>
        </b-row>
        <b-row>
          <b-col cols="12" md="">
            <div class="content__line">
              <div class="circle"></div>
              <div class="line"></div>
            </div>
          </b-col>
          <b-col cols="12" md="8">
            <div class="content__element w-100 mb-5">
              <div>
                <h3>Pradek</h3>
                <p>
                  Systemico crea su unidad estratégica enfocada en desarrollar
                  soluciones tecnológicas para aumentar la productividad en las
                  empresas, llamada Pradek.
                </p>
              </div>
              <div class="img small">
                <img :src="$cdn + 'history/Logo-Pradek-2021-vector.svg'" />
              </div>
            </div>
          </b-col>
          <b-col cols="12" md=""></b-col>
        </b-row>
      </b-container>
    </section>
    <!-- seccion 3 -->
    <section class="content content--blue">
      <b-container fluid="lg">
        <b-row>
          <b-col cols="12" md=""></b-col>
          <b-col cols="12" md="10" lg="8">
            <div class="content__develop">
              <h2>Desarrollamos el crecimiento de tu empresa!</h2>
              <p>
                Systemico es un aliado estratégico enfocado en hacer las cosas
                simples y efectivas, que a cada paso se agregue valor y que la
                inversión se ejecute lo más eficientemente posible.
              </p>
              <div class="opcions">
                <template v-for="(item, index) in buttons">
                  <buttonPrimry
                    :configButton="item"
                    :key="index"
                    @funcionBtn="funcionBtn(item.click)"
                  />
                </template>
              </div>
            </div>
          </b-col>
          <b-col cols="12" md=""></b-col>
        </b-row>
      </b-container>
    </section>
  </main>
</template>

<script>
import titleSection from "@/components/ui/titleSection";
import buttonPrimry from "@/components/basic/buttonBasic.vue";
import footerC from "../../../../../../systemico.ui/components/footers/FooterSystemicoComponent.vue";
import HeaderSystemico from "../../../../../../systemico.ui/components/headers/HeaderSystemicoComponent.vue";

export default {
  name: "team",
  components: {
    HeaderSystemico,
    titleSection,
    buttonPrimry,
    footerC,
  },
  metaInfo: {
    title: "Historia - Systemico",
  },
  props: {
    lang: String,
  },
  data: function () {
    return {
      content: {
        title: "Compañía",
        subtitle: "/ Nuestra Historia",
      },
      buttons: [
        {
          text: "Cotizar proyecto",
          class: "button--secundary",
          click: "sinContenido",
        },
        {
          text: "Solicitar servicio",
          class: "button--secundary",
          click: "sinContenido",
        },
        {
          text: "Contáctanos",
          class: "button--secundary",
          click: "sinContenido",
        },
      ],
    };
  },
  methods: {},
};
</script>
