<template>
    <section class="footer">
        <b-container fluid='xl'>
            <b-row>
                <template v-for='(col, index) in cols'>
                    <b-col cols='12' md='6' lg='3' :key='index'>
                        <h2 class="footer__subtitle">{{ col.subtitle[$props.lang] }}</h2>
                        <template v-for='(link, index) in col.links'>
                          <a class="footer__link" :href="($props.urlbase?$props.urlbase+link.link:link.link)"  rel="noopener noreferrer" :key='index' v-if="link.link">
                            {{ link.name[$props.lang] }}
                          </a>
                          <a class="footer__link" href="javascript:void(0);" rel="noopener noreferrer" :key='index' v-else-if="link.event" :onclick="link.event">
                            {{ link.name[$props.lang] }}
                          </a>
                          <a class="footer__link" href="/enConstruccion" rel="noopener noreferrer" :key='index' v-else>
                            {{ link.name[$props.lang] }}
                          </a>
                        </template>
                    </b-col>
                </template>
            </b-row>
            <b-row class="mt-md-5">
                <b-col cols='12' lg='6'>
                    <div>
                      <img class="footer__logo" :src="$cdn + 'logos/systemico_full_black.svg'" alt="logo systemico">
                    </div>
                </b-col>
                <b-col sm="12" cols='12' lg='6' class="d-none d-md-block">
                  <div class="footer__clutch">
                    <img v-if="idioma == 'es-ES'" :src="$cdn + 'logos/logo_cln.png'" alt="logo cln">
                    <a href="https://www.crunchbase.com/organization/systemico" target="_blank" rel="noopener noreferrer">
                      <img :src="$cdn + 'logos/logo_crunchbase.svg'" alt="logo runchbase" class="img2">
                    </a>
                    <div class="clutch-widget" data-nofollow="true" data-url="https://widget.clutch.co" data-widget-type="14" data-height="50" data-clutchcompany-id="1822619"></div>
                  </div>
                </b-col>
            </b-row>
            <b-row class="footer__line">
              <b-col cols='12' md='8' class="p-0 m-0">
                <span class="footer__firm">
                  <!--<strong>Systemico®</strong> {{ fecha }}&nbsp;|&nbsp;-->
                  Countries:
                  <a href="/en-us" class="text-white font-weight-bold">United States</a>&nbsp;|&nbsp;
                  <a href="/es-co" class="text-white font-weight-bold">Colombia</a>&nbsp;|&nbsp;
                  <a href="/en-ca" class="text-white font-weight-bold">Canada</a>
                </span>
                <div class="text-white">
                  Follow us:
                  <a v-for='(network, index) in networks' style="display: inline" class="footer__button" :href="network.link" rel="noopener noreferrer" :key='index'>
                      <img class="mr-2" style="display: inline;height:20px;" :src="$cdn + 'icons/' + network.icon1" alt="network. networks">
                      <!--<img style="display: inline" :src="$cdn + 'icons/' + network.icon2" alt="icon networks">-->
                  </a>
                </div>
              </b-col>
              <b-col cols='12' md='4' class="p-0 m-0">
                <div class="footer__legal">
                  <a class="font-weight-bold" href="/terms">Legal</a>
                  <!-- <a href="javascript:void(0);" rel="noopener noreferrer" v-on:click="sinContenido">Privacidad</a> -->
                </div>
              </b-col>
            </b-row>
        </b-container>
    </section>
</template>

<script>

export default {
  name: 'FooterSystemicoComponent',
  props:{
    urlbase: String,
    lang: {
      type: String,
      default: 'ES'
    }
  },
  beforeMount () {
    if (this.lang === undefined) {
      this.lang = 'ES'
    } else {
      this.lang = this.lang.toUpperCase()
    }
  },
  components: {

  },
  data: function () {
    return {
      fecha: '',
      cols: [
        {
          subtitle: {
            ES: 'Servicios',
            EN: 'Services'
          },
          links: [
            {
              name: {
                ES: 'Desarrollo de Software',
                EN: 'Software Development'
              },
              link: '/development' },
            {
              name: {
                ES: 'Desarrollo API',
                EN: 'API Development'
              },
              link: '/development/api' },
            {
              name: {
                ES: 'Desarrollo de Bots',
                EN: 'Bots Development'
              },
              link: '/development/bots'
            },
            {
              name: {
                ES: 'Desarrollo de Dasboards',
                EN: 'Dashboards Development'
              },
              link: '/development/dashboards'
            },
            {
              name: {
                ES: 'Servicios Recurrentes',
                EN: 'Cron Services'
              },
              link: '/development/cron'
            }
          ]
        },
        {
          subtitle: {
            ES: 'Acerca de',
            EN: 'About Us'
          },
          links: [
            { name: {
              ES: 'Compañía',
              EN: 'Company'
              },
              link: '/company_about'
            },
            {
              name: {
                ES: 'Políticas de la empresa',
                EN: 'Company Policy',
              },
              link: '/politics'
            },
            {
              name: {
                ES: 'Metodología de trabajo',
                EN: 'Work Methodology'
              },
              link: '/company_methodology' },
            {
              name: {
                ES: 'Clientes',
                EN: 'Clients',
              },
              link: '/company_clients'
            }
          ]
        },
        {
          subtitle: {
            ES: 'Atención',
            EN: 'Attention'
          },
          links: [
            {
              name: {
                ES: 'Soporte',
                EN: 'Support'
              },
              link: 'https://systemico.net/support'
            },
            //{ name: 'Contáctanos', link: 'https://systemico.net/support/registro' },
            {
              name: {
                ES: 'Contáctanos',
                EN: 'Contact Us',
              },
              link: '/contact'
              //event: 'const collection = document.getElementsByClassName("pradek_widget__button");(collection[0]).click();'
            },
            // { name: 'Cotizaciones' },
            {
              name: {
                ES: 'Blog',
                EN: 'Blog'
              },
              link: 'https://medium.com/@systemico'
            }
          ]
        },
        {
          subtitle: {
            ES: 'Extensión',
            EN: 'Extension'
          },
          links: [
            {
              name: {
                ES: 'Inversores',
                EN: 'Investors'
              },
              link: '/company_investors'
            },
            {
              name: {
                ES: 'Trabaje con nosotros',
                EN: 'Join us'
              },
              link: '/company_work'
            },
            {
              name: {
                ES: 'Equipo de trabajo',
                EN: 'Teamwork'
              },
              link: '/company_team' }
          ]
        }
      ],

      networks: [
        {
          icon1: 'icon-facebook--hover.svg',
          icon2: 'icon-facebook.svg',
          link: 'https://www.facebook.com/Systemico/'
        },
        {
          icon1: 'icon-linked--hover.svg',
          icon2: 'icon-linked.svg',
          link: 'https://www.linkedin.com/company/systemico/mycompany/'
        },
        {
          icon1: 'icon-youtube--hover.svg',
          icon2: 'icon-youtube.svg',
          link: 'https://www.youtube.com/channel/UCdG-G3O4cLs99C5lP2ye8QQ'
        },
        {
          icon1: 'icon-twitter--hover.svg',
          icon2: 'icon-twitter.svg',
          link: 'https://twitter.com/systemico?lang=es'
        },
        // {
        //   icon1: 'icon-git--hover.svg',
        //   icon2: 'icon-git.svg',
        //   link: ''
        // },
        {
          icon1: 'icon-blog--hover.svg',
          icon2: 'icon-blog.svg',
          link: 'https://medium.com/@systemico'
        },
      ],
      idioma: ''
    }
  },
  mounted() {
    this.fecha = new Date().getFullYear();
    this.idioma = window.navigator.idioma;
  },
  methods: {
    sinContenido: function () {
      const pathname = location.pathname

      if (pathname === '/enConstruccion') {
        window.scroll({
          top: 0,
          left: 0,
          behavior: 'smooth'
        })
      } else {
        this.$router.push({
          name: 'enConstruccion'
        })
      }
    },
    reDirect: function (view) {
      this.$router.push({
        name: view
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.footer {
    padding: 2rem 0;
    background-color: #222222;
    border-top:4px solid #ff8028;
    font-size: 1rem;
    &__subtitle {
        margin: 0 0 3rem;
        font-size: 1.2rem;
        font-weight: 600;
        color: #f8f8f8;
        line-height: 1.2rem;
    }
    &__link {
        margin: 0 0 1.5rem;
        font-size: 1rem;
        font-weight: normal;
        color: #fefefe;
        line-height: 1rem;
        display: block;
        &:hover {
          color: #ff8028;
          text-decoration: none;
        }
    }
    &__logo {
      width: 12rem;
      display: block;
      margin: 0 3rem 0 0;
    }
    &__networks {
        margin: 0;
        display: flex;
    }
    &__button {
        width: 1rem;
        min-width: 3rem;
        height: 3rem;
        margin: 0 0.1rem;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;

        & > :first-child {
            display: block;
        }

        & > :last-child {
            display: none;
        }

        &:hover {
            & > :first-child {
                display: none;
            }

            & > :last-child {
                display: block;
            }
        }
    }

    &__firm {
        font-size: 0.8rem;
        font-weight: normal;
        color: #fefefe;
        line-height: 1rem;
        text-align: left;
    }

    &__line {
        border-top: 0.1rem solid #7D8AA7;
        margin: 0;
        padding-top: 1rem;
    }

    &__legal {
        padding: 0;
        display: flex;

        a {
            margin: 0;
            font-family: 'Mulish', sans-serif;
            font-size: 1rem;
            font-weight: normal;
            color: #fefefe;
            line-height: 1rem;
            display: block;

            &:hover {
                color: #171E44;
            }
        }
    }

    &__clutch {
        height: 100%;
        padding-bottom:1rem;
        & > :nth-child(1) {
            width: 10rem;
            min-width: 15rem;
            margin: 0;
            display: block;
        }

        & > :nth-child(2) {
            width: 10rem;
            min-width: 16rem;
            margin: 0;
            display: block;
        }
    }

    // responsive para dispositivos de tablets en adelante
    @media (max-width: 768px) {
        padding: 2rem;
        &__subtitle {
            font-size: 1.2rem;
            line-height: 2.3rem;
        }

        &__link {
            font-size: 1rem;
            line-height: 1rem;
        }

        &__logo {
            width: 10rem;
            margin: 0 3rem 0 0;
        }

        &__networks {
            margin: 0;
            display: block;
        }

        &__firm {
            font-size: 0.7rem;
            line-height: 1rem;
        }

        &__legal {
            padding: 0;
            justify-content: flex-end;

            a {
                font-size: 0.7rem;
                line-height: 0.6rem;
            }
        }

        &__items {
            height: 100%;
            display: flex;
            align-items: center;
        }

        &__clutch {
            display: flex;
            align-items: center;
            justify-content: flex-end;

            & > :nth-child(1n) {
                margin: 0 1rem;
            }
        }
    }
}
</style>
