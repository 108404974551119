<template>
  <section class="products" id="products">
    <b-container fluid class="content">
      <b-row>
        <b-col>
          <h2 class="products__title">
            {{$props.title[$props.lang] }}
            <span class="line"></span>
          </h2>
          <p class="products__description">
            {{$props.description[$props.lang] }}
          </p>
          <div v-if="$props.alone === '' || $props.alone === undefined " class="products__options" name="more_products" id="more_products"  >
            <button
              :class="state == 1 ? 'active' : ''"
              v-on:click="changeOption(1)"
            >
              {{subtitle_1[$props.lang]}}
            </button>
            <button
              :class="state == 2 ? 'active' : ''"
              v-on:click="changeOption(2)"
              id="quick_services"
            >
              {{subtitle_2[$props.lang]}}
            </button>
            <button
              :class="state == 3 ? 'active' : ''"
              v-on:click="changeOption(3)"
            >
              {{subtitle_3[$props.lang]}}
            </button>
          </div>
        </b-col>
      </b-row>
      <b-row v-show="state === 1 && ($props.alone=='products' || $props.alone===undefined)">
        <template v-for="(item, index) in products">
          <b-col cols="12" md="6" lg="4" :key="index" v-show="item[$props.lang].show">
            <div class="products__card">
              <header class="m-3">
                <div class="type">{{subtitle_1[$props.lang]}}</div>
              </header>
              <h3 class="align-items-center">
                <img
                  :src="$cdn + 'icons/products/' + item[$props.lang].icon"
                  alt="icon card"
                />
                {{ item[$props.lang].title }}
              </h3>
              <p v-html="item[$props.lang].description"></p>
              <div class="price">
                <p>
                  <!--{{from[$props.lang]}} <span>{{ item[$props.lang].price[$props.currency] }}
                  </span><span class="currency">{{$props.currency}}</span>
                  / {{ item[$props.lang].period }}-->
                  <ButtonComponent  variant="warning" :text="free_account[$props.lang]" :href="item[$props.lang].signup" icon="gift"/>
                </p>
                <ButtonComponent  variant="secondary" :text="more[$props.lang]" :href="item[$props.lang].link" icon="plus" />
              </div>
            </div>
          </b-col>
        </template>
      </b-row>
      <b-row v-show="state == 2 && ($props.alone=='quick' || $props.alone===undefined)">
        <template v-for="(item, index) in services">
          <b-col cols="12" md="6" lg="4" :key="index" v-show="item[$props.lang].show && (item[$props.lang].location == $props.country || item[$props.lang].location === 'global'  )" >
            <div class="products__card">
              <header>
                <div class="location text-capitalize">
                  {{ item[$props.lang].location }} <b-icon icon="globe"></b-icon>
                </div>
                <div class="type">{{subtitle_2[$props.lang]}}</div>
              </header>
              <h3 class="align-items-start">
                <img
                  :src="$cdn + 'icons/services/' + item[$props.lang].icon"
                  alt="icon card"
                />
                {{ item[$props.lang].title }}
              </h3>
              <p>{{ item[$props.lang].description }}</p>
              <div class="price">
                <p>
                  <span>{{ item[$props.lang].price[$props.currency] }}</span> / pago único
                </p>
                <buttonPrimry
                  v-if="item[$props.lang].link"
                  :configButton="configButton2"
                  @funcionBtn="funcioRedirect(item[$props.lang].link)"
                />
                <buttonPrimry v-else :configButton="configButton3" />
              </div>
            </div>
          </b-col>
        </template>
      </b-row>
      <b-row v-show="state == 3 && ( $props.alone=='services' || $props.alone===undefined)">
        <template v-for="(item, index) in software">
          <b-col cols="12" md="6" lg="4" :key="index">
            <div class="products__card">
              <header class="m-3">
                <div class="type">{{ subtitle_3[$props.lang] }}</div>
              </header>
              <h3>
                <img :src="$cdn + 'icons/' + item[$props.lang].icon" alt="icon card" />
                {{ item[$props.lang].title }}
              </h3>
              <p>
                {{ item[$props.lang].description }}
                <span class="badge badge-secondary">{{ item[$props.lang].limit }}</span>
              </p>
              <div class="price">
                <p>
                  {{from[$props.lang]}} <span>{{ item[$props.lang].price[$props.currency] }}
                  </span><span class="currency">{{$props.currency}}</span>
                  / {{ item[$props.lang].period }}
                </p>
                <ButtonComponent  variant="secondary" :text="more[$props.lang]" :href="item[$props.lang].link" icon="plus"/>
              </div>
            </div>
          </b-col>
        </template>
      </b-row>
    </b-container>
  </section>
</template>

<script>
import buttonPrimry from '../basic/buttonBasic.vue'
import ButtonComponent from '../../../../systemico.ui/components/buttons/ButtonComponent.vue';
export default {
  name: 'Products',
  beforeMount() {
    this.lang = this.lang.toUpperCase()
    if (this.alone === 'services'){
      this.state = 3
    }
    if (this.alone === 'quick'){
      this.state = 1
    }
    if (this.alone === 'products'){
      this.state = 1
    }
    // RANDOM ORDER PRODUCTS
    //this.products = this.products.sort(() => Math.random() - 0.5)

  },
  components: {
    buttonPrimry,
    ButtonComponent
  },
  props: {
    lang: {
      type: String,
      default: 'EN'
    },
    currency: String,
    country: String,
    alone: String,
    background: {
      type: String,
      default: '#222222'
    },
    textColor: {
      type: String,
      default: '#fefefe'
    },
    title: {
      type: Object,
      default: {
        ES: 'Productos y Servicios',
        EN: 'Products and Services'
      }
    },
    description: {
      type: Object,
      default: {
        ES: 'Soluciones reales que te ayudaran a iniciar o potenciar tu negocio.',
        EN: 'Real software for you and your business.'
      }
    }
  },
  data: function () {
    return {
      state: 1,
      subtitle_1: { ES: 'Productos', EN: 'Products' },
      subtitle_2: { ES: 'Servicios Rápidos', EN: 'Quick Services' },
      subtitle_3: { ES: 'Desarrollo', EN: 'Development' },
      more: { ES: 'm&aacute;s', EN: 'more' },
      free_account: { ES: '<b>CUENTA GRATIS</b>', EN: 'FREE ACCOUNT' },
      from: { ES: 'Desde', EN: 'From' },
      products: [
        {
          ES: {
            icon: 'icon-suppmail.svg',
            title: 'Suppmail',
            description: '<strong>Gestiona, organiza y automatiza PQRS</strong>. Cumple con las expectativas de tus clientes de forma rápida y sencilla. <u>Motiva la recompra al garantizar tu  servicio al cliente</u>.',
            price: { cop: '$50.000', usd: '$10', cad: '$15' },
            link: '/products/suppmail',
            signup: 'https://suppmail.systemico.net',
            period: 'mes',
            show: true
          },
          EN: {
            icon: 'icon-suppmail.svg',
            title: 'Suppmail',
            description: 'Email marketing to <strong>growth your sales</strong>. Transform your business with automate email marketing and show your products and services.',
            price: { cop: '$50.000', usd: '$10', cad: '$15' },
            link: '/products/suppmail',
            signup: 'https://suppmail.systemico.net',
            period: 'month',
            show: true
          }
        },
        {
          ES: {
            icon: 'icon-wabot.svg',
            title: 'Wabot',
            description: '<strong>ChatBot para WhatsApp, SMS y Facebook Messenger</strong>. Automatiza 24/7 la atención de tus clientes actuales o potenciales. <u>Vende mientras duermes</u>.',
            price: { cop: '$100.000', usd: '$20', cad: '$30' },
            link: '/products/wabot',
            signup: 'https://wabot.systemico.net',
            period: 'mes',
            show: true
          },
          EN: {
            icon: 'icon-wabot.svg',
            title: 'Wabot',
            description: 'ChatBot for WhatsApp, SMS and Facebook Messenger. Automate 24/7 the attention of your current or potential customers. <u>Sell while you sleep</u>.',
            price: { cop: '$100.000', usd: '$20', cad: '$30' },
            link: '/products/wabot',
            signup: 'https://wabot.systemico.net',
            period: 'month',
            show: false
          }
        },
        {
          ES: {
            icon: 'icon-grumpyturtle.svg',
            title: 'Grumpyturtle',
            description: '<strong>Gestor de proyectos que motiva el cumplimiento</strong>. Organiza tu equipo remoto o presencial con un enfoque en los resultados. <u>Sé altamente productivo e impulsa tu rentabilidad</u>.',
            price: { cop: '$50.000', usd: '$10', cad: '$15' },
            link: '/products/grumpyturtle',
            signup: 'https://grumpyturtle.systemico.net',
            period: 'mes',
            show: true
          },
          EN: {
            icon: 'icon-grumpyturtle.svg',
            title: 'Grumpyturtle',
            description: '<strong>Project manager that motivates compliance</strong>. Organize your remote or on-site team with a focus on results. <u>Be highly productive and boost your profitability</u>.',
            price: { cop: '$50.000', usd: '$10', cad: '$15' },
            link: '/products/grumpyturtle',
            signup: 'https://grumpyturtle.systemico.net',
            period: 'month',
            show: false
          }
        },
        {
          ES: {
            icon: 'icon-proposals.svg',
            title: 'Proposals',
            description: '<strong>Cotizaciones inteligentes y autogestionadas</strong>. Genera cotizaciones rápido y monitorea la interacción del cliente en tiempo real. <u>A más cotizaciones mayor probabilidad de venta</u>.',
            price: { cop: '$50.000', usd: '$10', cad: '$15' },
            link: '/products/proposals',
            signup: 'https://proposals.systemico.net',
            period: 'mes',
            show: true
          },
          EN: {
            icon: 'icon-proposals.svg',
            title: 'Proposals',
            description: '<strong>Smart, self-managed quotes</strong>. Generate quotes quickly and monitor customer interaction in real time. <u>More quotes, greater likelihood of sale</u>.',
            price: { cop: '$50.000', usd: '$10', cad: '$15' },
            link: '/products/proposals',
            signup: 'https://proposals.systemico.net',
            period: 'month',
            show: true
          }
        },
        {
          ES: {
            icon: 'icon-provedur.svg',
            title: 'Provedur',
            description: '<strong>Portal de proveedores y contratos</strong>. Agiliza tus compras integrando a tus proveedores en tu proceso operativo. <u>Gestionar tus proveedores es la clave para disminuir costos y crecer</u>.',
            price: { cop: '$50.000', usd: '$10', cad: '$15' },
            link: '/products/provedur',
            signup: 'https://provedur.systemico.net',
            period: 'mes',
            show: true
          },
          EN: {
            icon: 'icon-provedur.svg',
            title: 'Provedur',
            description: '<strong>Supplier and contract portal</strong>. Streamline your purchasing by integrating your suppliers into your operational process. <u>Managing your suppliers is the key to reducing costs and growing</u>.',
            price: { cop: '$50.000', usd: '$10', cad: '$15' },
            link: '/products/provedur',
            signup: 'https://provedur.systemico.net',
            period: 'month',
            show: false
          }
        },
        {
          ES: {
            icon: 'icon-transpur.svg',
            title: 'Transpur',
            description: '<strong>Sistema de control de flota y mantenimiento</strong>. Simplifica la gestión de tus vehículos, conductores, documentos y mantenimientos. <u>Evita sanciones y multas gestionando tu empresa de transporte</u>.',
            price: { cop: '$50.000', usd: '$10', cad: '$15' },
            link: '/products/transpur',
            signup: 'https://transpur.systemico.net',
            period: 'mes',
            show: true
          },
          EN: {
            icon: 'icon-transpur.svg',
            title: 'Transpur',
            description: '<strong>Fleet control and maintenance system</strong>. Simplify the management of your vehicles, drivers, documents and maintenance. <u>Avoid penalties and fines by managing your transport company</u>.',
            price: { cop: '$50.000', usd: '$10', cad: '$15' },
            link: '/products/transpur',
            signup: 'https://transpur.systemico.net',
            period: 'month',
            show: false
          }
        },
        {
          ES: {
            icon: "icon-gogoals.svg",
            title: "Gogoals",
            description: "<strong>Email marketing para acelerar tus ventas</strong>. Automatiza emails con promociones y novedades a tu nicho de clientes. <u>Genera recordación de tu marca en el momento oportuno</u>.",
            price: { cop: '$50.000', usd: '$10', cad: '$15' },
            link: '/products/gogoals',
            signup: 'https://gogoals.systemico.net',
            period: 'mes',
            show: true
          },
          EN: {
            icon: "icon-gogoals.svg",
            title: "Gogoals",
            description: "<strong>Email marketing to accelerate your sales</strong>. Automate emails with promotions and news for your niche customers. <u>Generate awareness of your brand at the right time</u>.",
            price: { cop: '$50.000', usd: '$10', cad: '$15' },
            link: '/products/gogoals',
            signup: 'https://gogoals.systemico.net',
            period: 'month',
            show: true
          }

        },
        {
          ES: {
            icon: "icon-focusmeet.svg",
            title: "Focusmeet",
            description: "<strong>Gestor de reuniones y compromisos</strong>. Incrementa el impacto de tus reuniones con temas y metas claras que motiven el cumplimiento. <u>No pierdas tiempo en reuniones sin sentido</u>.",
            price: { cop: '$50.000', usd: '$10', cad: '$15' },
            link: '/products/focusmeet',
            signup: 'https://focusmeet.systemico.net',
            period: 'mes',
            show: true
          },
          EN: {
            icon: "icon-focusmeet.svg",
            title: "Focusmeet",
            description: "<strong>Meeting and engagement manager</strong>. Increase the impact of your meetings with clear topics and goals that motivate compliance. <u>Don't waste time on meaningless meetings</u>.",
            price: { cop: '$50.000', usd: '$10', cad: '$15' },
            link: '/products/focusmeet',
            signup: 'https://focusmeet.systemico.net',
            period: 'month',
            show: true
          }
        },
        {
          ES: {
            icon: "icon-chatter.svg",
            title: "Chatter",
            description: "<strong>Chat de trabajo y Live Chat</strong>. Comunícate por chat con tus clientes, proveedores, equipo y visitantes. <u>Comunicaciones instantáneas para triunfar</u>.",
            price: { cop: '$50.000', usd: '$10', cad: '$15' },
            link: '/products/chatter',
            signup: 'https://chatter.systemico.net',
            period: 'mes',
            show: true
          },
          EN: {
            icon: "icon-chatter.svg",
            title: "Chatter",
            description: "<strong>Work Chat and Live Chat</strong>. Communicate via chat with your clients, suppliers, team and visitors. <u>Instant communications to succeed</u>.",
            price: { cop: '$50.000', usd: '$10', cad: '$15' },
            link: '/products/chatter',
            signup: 'https://chatter.systemico.net',
            period: 'month',
            show: true
          }
        },
        {
          ES: {
            icon: "icon-ducksite.svg",
            title: "Ducksites",
            description: "<strong>Tu sitio web profesional</strong>. Constructor de sitios web para detallar lo que haces. <u>Integra tu marketing digital con el contenido apropiado en tu sitio web.</u>",
            price: { cop: '$100.000', usd: '$25', cad: '$30' },
            link: '/products/ducksites',
            signup: 'https://ducksites.systemico.net',
            period: 'mes',
            show: true
          },
          EN: {
            icon: "icon-ducksite.svg",
            title: "Ducksites",
            description: "<strong>Your professional website</strong>. Website builder to detail what you do. <u>Integrate your digital marketing with the appropriate content on your website.</u>",
            price: { cop: '$100.000', usd: '$25', cad: '$30' },
            link: '/products/ducksites',
            signup: 'https://ducksites.systemico.net',
            period: 'month',
            show: true
          }
        },
        {
          ES: {
            icon: "icon-pugobox.svg",
            title: "PugoBox",
            description: "<strong>Gestión documental y flujos de trabajo</strong>. Potencia tus procesos gestionando tus documentos de manera eficiente. <u>Evita situaciones legales por errores documentales</u>.",
            price: { cop: '$100.000', usd: '$25', cad: '$30' },
            link: '/products/pugobox',
            signup: 'https://pugobox.systemico.net',
            period: 'mes',
            show: true
          },
          EN: {
            icon: "icon-pugobox.svg",
            title: "PugoBox",
            description: "<strong>Document management and workflows</strong>. Boost your processes by managing your documents efficiently. <u>Avoid legal situations due to document errors</u>.",
            price: { cop: '$100.000', usd: '$25', cad: '$30' },
            link: '/products/pugobox',
            signup: 'https://pugobox.systemico.net',
            period: 'month',
            show: true
          }
        },
        {
          ES: {
            icon: "icon-collectio.png",
            title: "Collectio",
            description: "<strong>Sistema de Cobros y Pagos.</strong> Cobranza preventiva y reactiva, con automatizaciones e historial. <u>Establece un tono apropiado para recuperar tu cartera</u>.",
            price: { cop: '$50.000', usd: '$10', cad: '$15' },
            link: '/products/collectio',
            signup: 'https://collectio.systemico.net',
            period: 'mes',
            show: true
          },
          EN: {
            icon: "icon-collectio.png",
            title: "Collectio",
            description: "<strong>Collection and Payment System.</strong> Preventive and reactive collection, with automation and history. <u>Set an appropriate tone to recover your portfolio.</u>",
            price: { cop: '$50.000', usd: '$10', cad: '$15' },
            link: '/products/collectio',
            signup: 'https://collectio.systemico.net',
            period: 'month',
            show: true
          }
        }
      ],
      services: [
        {
          ES: {
            icon: 'icon-service-2.svg',
            title: 'Login con Google - Flask',
            description: 'Simplifica el proceso de autenticación de tus clientes con un botón de Google para que los usuarios accedan con su cuenta de Gmail sin tener que memorizar una nueva clave.',
            price: { cop: '$1.000.000', usd: '$250', cad: '$300' },
            link: '/services/?id=7',
            location: 'global',
            show: true
          },
          EN: {
            icon: 'icon-service-2.svg',
            title: 'Login con Google - Flask',
            description: 'Login authentication module to use on flask applications to simplify the login process for each customer.',
            price: { cop: '$1.000.000', usd: '$250', cad: '$300' },
            link: '/services/?id=7',
            location: 'global',
            show: true
          }
        },
        {
          ES: {
            location: 'global',
            icon: 'icon-service-2.svg',
            title: 'Habilitación del Login con Outlook',
            description:
              'Simplifica el proceso de autenticación de tus clientes con un botón de Outlook para que los usuarios accedan con su cuenta de Gmail sin tener que memorizar una nueva clave.',
            price: { cop: '$1.000.000', usd: '$250', cad: '$300' },
            link: '/services/?id=8',
            show: true
          },
          EN: {
            icon: 'icon-service-2.svg',
            title: 'Habilitación del Login con Outlook',
            description:
              'Simplifica el proceso de autenticación de tus clientes con un botón de Outlook para que los usuarios accedan con su cuenta de Gmail sin tener que memorizar una nueva clave.',
            price: { cop: '$1.000.000', usd: '$250', cad: '$300' },
            link: '/services/?id=8',
            location: 'global',
            show: true
          }
        },
        {
          ES: {
            location: 'global',
            icon: 'icon-service-2.svg',
            title: 'Habilitación del Login con Linkedin',
            description: 'Simplifica el proceso de autenticación de tus clientes con un botón de Linkedin para que los usuarios accedan con su cuenta de Gmail sin tener que memorizar una nueva clave.',
            price: { cop: '$1.000.000', usd: '$250', cad: '$300' },
            link: '/services/?id=9',
            show: true
          },
          EN: {
            location: 'global',
            icon: 'icon-service-2.svg',
            title: 'Habilitación del Login con Linkedin',
            description: 'Simplifica el proceso de autenticación de tus clientes con un botón de Linkedin para que los usuarios accedan con su cuenta de Gmail sin tener que memorizar una nueva clave.',
            price: { cop: '$1.000.000', usd: '$250', cad: '$300' },
            link: '/services/?id=9',
            location: 'global',
            show: true
          }
        },
        {
          ES: {
            location: 'global',
            icon: 'icon-service-3.svg',
            title: 'Habilitar un módulo de notificaciones (SMS, EMAIL y PUSH)',
            description: 'Gestión automatizada de notificaciones SMS, EMAIL y PUSH para su aplicación.',
            price: { cop: '$1.000.000', usd: '$250', cad: '$300' },
            link: '/services/?id=10',
            show: true
          },
          EN: {
            location: 'global',
            icon: 'icon-service-3.svg',
            title: 'Habilitar un módulo de notificaciones (SMS, EMAIL y PUSH)',
            description: 'Gestión automatizada de notificaciones SMS, EMAIL y PUSH para su aplicación.',
            price: { cop: '$1.000.000', usd: '$250', cad: '$300' },
            link: '/services/?id=10',
            show: true
          }
        },
        {
          ES: {
            location: 'global',
            icon: 'icon-service-6.svg',
            title: 'Habilitación del monitor de las aplicaciones',
            description: 'Monitoreo de LOGS y ACCESOS para identificar inconvenientes o fallas potenciales.',
            price: { cop: '$1.000.000', usd: '$250', cad: '$300' },
            link: '/services/?id=11',
            show: true
          },
          EN: {
            location: 'global',
            icon: 'icon-service-6.svg',
            title: 'Habilitación del monitor de las aplicaciones',
            description: 'Monitoreo de LOGS y ACCESOS para identificar inconvenientes o fallas potenciales.',
            price: { cop: '$1.000.000', usd: '$250', cad: '$300' },
            link: '/services/?id=11',
            show: true
          }
        },
        {
          ES: {
            location: 'colombia',
            icon: 'icon-service-5.svg',
            title: 'Emisión de documentos soporte',
            description: 'Servicio para automatizar la remisión de los documentos soporte a la DIAN a partir del proveedor tecnológico Carvajal ó Factura 1.',
            price: { cop: '$1.000.000', usd: '$250', cad: '$300' },
            link: '/services/?id=12',
            show: true
          },
          EN: {
            location: 'colombia',
            icon: 'icon-service-5.svg',
            title: 'Emisión de documentos soporte',
            description: 'Servicio para automatizar la remisión de los documentos soporte a la DIAN a partir del proveedor tecnológico Carvajal ó Factura 1.',
            price: { cop: '$1.000.000', usd: '$250', cad: '$300' },
            link: '/services/?id=12',
            show: true
          }
        },
        {
          ES: {
            location: 'colombia',
            icon: 'icon-service-5.svg',
            title: 'Inbox de Facturación Electrónica',
            description: 'Servicio para automatizar las facturas emitidas por proveedores y registro al software contable o ERP. 1. Carvajal a Seven. 2. Factura 1 a Seven.',
            price: { cop: '$1.000.000', usd: '$250', cad: '$300' },
            link: '/services/?id=13',
            show: true
          },
          EN: {
            location: 'colombia',
            icon: 'icon-service-5.svg',
            title: 'Inbox de Facturación Electrónica',
            description: 'Servicio para automatizar las facturas emitidas por proveedores y registro al software contable o ERP. 1. Carvajal a Seven. 2. Factura 1 a Seven.',
            price: { cop: '$1.000.000', usd: '$250', cad: '$300' },
            link: '/services/?id=13',
            show: true
          }
        }
      ],
      configButton1_es: { text: 'Probar', class: 'button--secundary' },
      configButton2_en: { text: 'Try it', class: 'button--secundary' },
      configButton2: { text: { ES: 'Comprar', EN: 'Buy' }, class: 'button--secundary' },
      configButton3_es: { text: 'Saber más', class: 'button--secundary' },
      configButton3_en: { text: 'More', class: 'button--secundary' },
      software: [
        {
          ES: {
            icon: 'icon-requirements.svg',
            title: 'API (REST / SOAP)',
            description:
              'Desarrollamos API REST con Python, PHP y Java para simplificar la integraciones de servicios e incluir opciones de datos abiertos.',
            price: { cop: '$1.500.000', usd: '$400', cad: '$500' },
            link: '/development/api',
            limit: '10h',
            period: 'mes',
            show: true
          },
          EN: {
            icon: 'icon-requirements.svg',
            title: 'API (REST / SOAP).',
            description:
              'Develop an API REST Microservice with Python, PHP or Java. Use OpenAPI to integrate diferent applications, customers and services managing the business rules from an unique place.',
            price: { cop: '$1.500.000', usd: '$400', cad: '$500' },
            link: '/services/api',
            limit: '10h',
            period: 'month',
            show: true
          }
        },
        {
          ES: {
            icon: 'icon-requirements.svg',
            title: 'Bots.',
            description:
              'Desarrollo de bots en Python para automatizar la atención al cliente, pagos, cobros, notificaciones y más.',
            price: { cop: '$1.500.000', usd: '$400', cad: '$500' },
            link: '/development/bots',
            limit: '10h',
            period: 'mes',
            show: true
          },
          EN: {
            icon: 'icon-requirements.svg',
            title: 'Bots.',
            description:
              'Bot development in Python to automate customer attention, payments, collect, notifications and more.',
            price: { cop: '$1.500.000', usd: '$400', cad: '$500' },
            link: '/development/bots',
            limit: '10h',
            period: 'month',
            show: true
          }
        },
        {
          ES: {
            icon: 'icon-requirements.svg',
            title: 'Dashboards.',
            description:
              'Dashboards personalizados con datos en tiempo real, interfaces intuitivas y alto rendimiento. Perfecto para monitorear métricas clave y tomar decisiones rápidas.',
            price: { cop: '$1.500.000', usd: '$500', cad: '$500' },
            link: '/development/dashboards',
            limit: '10h',
            period: 'mes',
            show: true
          },
          EN: {
            icon: 'icon-requirements.svg',
            title: 'Dashboards.',
            description:
              'Custom dashboards with real-time data, intuitive interfaces, and high performance. Perfect for monitoring key metrics and making quick decisions.',
            price: { cop: '$1.500.000', usd: '$500', cad: '$500' },
            link: '/development/dashboards',
            limit: '10h',
            period: 'month',
            show: true
          }
        },
        {
          ES: {
            icon: 'icon-requirements.svg',
            title: 'Workflows.',
            description:
              'Automatiza tus procesos con pipelines personalizados en Python. Diseñamos soluciones para gestionar cargas de trabajo y ejecutar tareas periódicas (mensuales, semanales, anuales) con precisión y eficiencia. Ideal para ahorrar tiempo y reducir errores operativos.',
            price: { cop: '$1.500.000', usd: '$500', cad: '$500' },
            link: '/development/cron',
            limit: '10h',
            period: 'mes',
            show: true
          },
          EN: {
            icon: 'icon-requirements.svg',
            title: 'Workflows.',
            description:
              'Optimize and automate your processes with custom Python pipelines. We design solutions to manage workloads and execute periodic tasks (monthly, weekly, annually) with precision and efficiency. Perfect for saving time and reducing operational errors.',
            price: { cop: '$1.500.000', usd: '$500', cad: '$500' },
            link: '/services/cron',
            limit: '10h',
            period: 'month',
            show: true
          }
        }
      ],
      configButton4: { text: 'Próximamente', class: 'button--gray' }
    }
  },
  methods: {
    changeOption: function (option) {
      this.state = option
    },
    funcioRedirect: function (link) {
      window.location = link
    }
  }
}
</script>
<style lang="scss" scoped>
@import '../../assets/styles/variables.scss';
  .content{
    max-width:1280px;
  }
  .products {
    /**background-image: url(https://cdn.systemico.net/systemico/images/productivity/banner-3.jpg);**/
    background-size: 100vw 100vh;
    background-position: top;
    background-repeat: no-repeat;
    background-attachment: fixed;
    position: relative;
    padding-top:0;
    background: v-bind(background);

    &--background-1 {
        background-image: url(https://cdn.systemico.net/systemico/images/productivity/banner-2.jpg);
    }

    &--background-2 {
        background-image: url(https://cdn.systemico.net/systemico/images/productivity/banner-3.jpg);
    }

    &__background {
        width: 100%;
        height: 100%;
        background-color: #006DF0;
        opacity: 0.6;
        backdrop-filter: blur(50px);
        position: absolute;
        top: 0;
        left: 0;
    }

    &__title {
        margin: 0 0 0.8rem;
        font-size: 2.2rem;
        font-weight: 500;
        color: v-bind(textColor);
        text-align: center;
        line-height: 2.4rem;

        .line {
            width: 0.5rem;
            height: 2rem;
            border-radius: 0.4rem;
            background-color: $color-orange;
            display: inline-block;
            transform: rotate(90deg);
            position: relative;
            top: 0.8rem;
            right: -0.5rem;
        }
    }

    &__description {
        margin: 0 0 1rem;
        font-size: 1.5rem;
        font-weight: normal;
        color: v-bind(textColor);
        text-align: center;
    }

    &__options {
        button {
            &.active {
                background-color: $color-orange;
            }

            width: 100%;
            height: 4rem;
            margin: 0 0 3rem;
            border: 0;
            border-radius: 5rem;
            background-color: #7D8AA7;
            font-family: $font-mulish;
            font-size: 1.3rem;
            font-weight: normal;
            color: $color-white;
            display: flex;
            align-items: center;
            justify-content: center;

            &:hover {
                background-color: $color-orange;
            }
        }
    }

    &__card {
        width: 100%;
        height: calc(100% - 3rem);
        margin: 0 0 3rem;
        padding: 0.5rem;
        border: 0.3rem solid transparent;
        border-radius: 0px 20px;
        background-color: $color-white;
        box-shadow: 0px 3px 10px #4D576F26;
        position: relative;
        transition: all 0.3s;

        &:hover {
            border: 0.3rem solid $color-orange;
        }

        &--green {
            background-color: #EBFFE0;
        }

        &--dark {
            background-color: $color-black;

            & > h3, & > p, .price p {
                color: $color-white!important;
            }
        }

        header {
            margin: 0 0 1rem;
            display: flex;
            align-items: center;
        }

        .location {
            width: max-content;
            padding: 0;
            font-family: $font-mulish;
            font-size: 1.2rem;
            font-weight: 500;
            color: #171E44;
            display: flex;
            align-items: center;
            justify-content: center;

            svg {
                margin: 0 0 0 1rem;
                font-size: 1.5rem;
            }
        }

        .type {
            width: max-content;
            margin: 0 0 0 auto;
            padding: 0 1rem;
            border: 0.1rem solid #7D8AA7;
            border-radius: 5rem;
            background: transparent;
            font-size: 1rem;
            font-weight: 500;
            color: #7D8AA7;
            display: flex;
            align-items: self-end;
            justify-content: center;
        }

        & > h3 {
            margin: -45px 0 1rem;
            font-size: 1.2rem;
            font-weight: 500;
            color: $color-black;
            text-align: left;
            display: flex;

            img {
                max-width: 2rem;
                margin: 0 1rem 0 0;
            }
        }

        & > p {
            min-height: 6.4rem;
            margin: 0 0 1rem;
            font-size: 1rem;
            font-weight: normal;
            color: $color-black;
            text-align: left;
            -webkit-line-clamp: 4;
            display: -webkit-box;
            -webkit-box-orient: vertical;
            text-overflow: ellipsis;
            white-space: normal;
            overflow: hidden;
        }

        .price {
            display: flex;
            align-items: center;
            justify-content: space-between;

            p {
                margin: 0;
                font-size: 0.8rem;
                font-weight: normal;
                color: $color-black;

                span {
                    font-size: 1.5rem;
                    color: $color-blue;
                }
                .currency{
                    font-size:0.8em;
                    color:#fefefe;
                    background: $color-celeste;
                    padding:2px;
                    border-radius:0.2em;
                    text-transform:uppercase;
                }
            }
        }
    }

     // responsive para dispositivos de tablets en adelante
     @media (min-width: $breakpoint-tablet) {
        &__options {
            display: flex;
            align-items: center;
            justify-content: center;
            button {
                max-width: 15rem;
                height: auto;
                margin: 0 1rem 1.5rem;
            }
        }
    }
}
</style>
