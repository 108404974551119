<template>
    <main class="section">
		  <section class="content pt-5 mt-5">
            <titleSection :content='content'/>
			<b-container fluid='lg' class="mt-5">
				<b-row>
					<b-col cols='12' md=''>
                        <h2 class="content__title content__title--celeste mb-5">Tenemos un espacio reservado para ti.</h2>
						<p class="content__subtitle">Queremos ayudarte a modernizar tu modelo de negocio o crear nuevas oportunidades en innovación. Queremos trabajar con tigo y conocer de tus proyectos.</p>
					</b-col>
					<b-col cols='12' md=''>
					</b-col>
				</b-row>
			</b-container>
		</section>
		<!-- seccion 2 -->
        <section class="content__section content--gray py-8">
			<b-container fluid=lg>
				<b-row>
					<b-col cols='12'>
                        <h2 class="content__title text-center margin-bottom-6">¿Por qué ser nuestro cliente?</h2>
					</b-col>
				</b-row>
                <b-row>
                    <b-col cols='12' md=''>
                        <div class="content__element shadow-none">
							<img class="d-block mx-auto" style="width: 10rem" :src="$cdn + 'icons/icon-systemico--color.svg'" alt="logo systemico">
                            <h3 class="text-center margin-bottom-4 margin-top-3">Experiencia, estrategia e innovación.</h3>
                            <p>La experiencia permite asegurar la más alta calidad en soluciones a la medida en cada tipo de industria. Incorporando las últimas metodologías, herramientas y tecnologías para lograr los mejores resultados.</p>
                            <p>En la era de la transformación digital las empresas deben estar en constante innovación y Systemico es el aliado tecnológico que ayudará a impulsar con tecnología las metas de crecimiento, productividad y rentabilidad del negocio.</p>
                        </div>
                    </b-col>
                    <b-col cols='12' md=''>
                        <div class="content__element h-100 shadow-none">
                            <h3 class="text-center text-blue margin-bottom-4 margin-top-4">Empresas que confíaron en nosotros.</h3>
                            <b-container fluid>
                                <b-row>
                                    <template v-for='(item, index) in clients'>
                                        <b-col cols='12' md='6' :key='index'>
                                            <h3 class="text-center margin-bottom-4">{{ item.name }}</h3>
                                        </b-col>
                                    </template>
                                </b-row>
                            </b-container>
                        </div>
                    </b-col>
                </b-row>
			</b-container>
		</section>
        <section class="content p-0">
			<p class="content__slogan">Nos encantan los retos y estamos dispuestos a aceptarlos.</p>
		</section>
		<!-- seccion 4 -->
		<section class="content content--blue">
			<b-container fluid=lg>
				<b-row>
					<b-col cols='12' md=''></b-col>
					<b-col cols='12' md='10' lg='8'>
						<div class="content__develop">
							<h2>Desarrollamos el crecimiento de tu empresa!</h2>
							<p>Systemico es un aliado estratégico enfocado en hacer las cosas simples y efectivas, que a cada paso se agregue valor y que la inversión se ejecute lo más eficientemente posible.</p>
							<div class="opcions">
								<template v-for='(item, index) in buttons'>
									<buttonPrimry :configButton='item' :key='index' @funcionBtn="funcionBtn(item.click)"/>
								</template>
							</div>
						</div>
					</b-col>
					<b-col cols='12' md=''></b-col>
				</b-row>
			</b-container>
		</section>
	</main>
</template>

<script>
  import titleSection from '@/components/ui/titleSection'
  import buttonPrimry from '@/components/basic/buttonBasic.vue'

  export default {
      name: "clients",
      components: {
      titleSection,
      buttonPrimry
    },
    metaInfo: {
      title: 'Nuestros Clientes - Systemico'
    },
    props: {
      lang: String
    },
    data: function () {
      return {
        content: {
          title: 'Compañía',
          subtitle: '/ ¿Por qué ser nuestro cliente?'
        },
              buttonSection: { text: 'Aplicar', class: 'button--secundary', click: 'mailto: cao@systemico.co' },
              buttonSection2: { text: 'Aplicar', class: 'button--secundary', click: 'https://www.eteamer.co/' },
        buttons: [
          { text: 'Cotizar proyecto', class: 'button--secundary', click: 'sinContenido' },
          { text: 'Solicitar servicio', class: 'button--secundary', click: 'sinContenido' },
          { text: 'Contáctanos', class: 'button--secundary', click: 'sinContenido' }
        ],
              clients: [
                  { img: '', name: 'CAJASAN' },
                  { img: '', name: 'COTRASUR' },
                  { img: '', name: 'FINANCIERA COMULTRASAN' },
                  { img: '', name: 'CONCREMOVIL' },
                  { img: '', name: 'FONDO DE GARANTÍAS' },
                  { img: '', name: 'FUNDACIÓN DE LA MUJER' },
                  { img: '', name: 'PHARMASAN' },
                  { img: '', name: 'COINVERSIONES' }
                  // { img: '', name: '' }
              ]
      }
    },
    methods: {
          funcioRedirect: function (link) {
        window.location=link
      }
    }
  };
</script>
