<template>
  <div class="page"  @scroll="headerReact()">
    <section class="enConstruccion">
        <div class="enConstruccion__content">
            <h2 class="enConstruccion__title">
                {{ title }}
                <div class="line"></div>
            </h2>
            <img class="enConstruccion__logo" :src="$cdn + 'images/Seccion-en-construccion-Systemico.png'" alt="logo pradek">
            <buttonPrimry class="mx-auto" :configButton='configButton' @funcionBtn="home"/>
        </div>
    </section>
  </div>
</template>

<script>
import buttonPrimry from '@/components/basic/buttonBasic.vue'

export default {
  name: 'enConstruccion',
  components: {
    buttonPrimry
  },
  metaInfo: {
    title: 'Página en construcción.'
  },

  data: function () {
    return {
      title: 'Systemico',
      configButton: { text: 'Ir a Inicio', class: 'button--secundary' }
    }
  },
  methods: {
    home: function () {
      window.location = '/'
    }
  }
}
</script>
