<template>
    <section class="clients">
        <b-container fluid='lg'>
            <b-row>
                <b-col cols='12' md='12'>
                    <h2 class="clients__title">
                        {{ title[$props.lang] }}
                        <div class="line"></div>
                    </h2>
                    <h3 class="clients__subtitle">
                        {{ subtitle[$props.lang] }}
                    </h3>
                    <p class="clients__description">
                        {{ description[$props.lang] }}
                    </p>
                </b-col>
            </b-row>
            <b-row>
              <b-col md="3" v-for="client in clients" :key="client.id" >
                <b-card
                  :img-alt="client.name"
                  img-top
                  tag="client"
                  style="max-width: 28rem;height:auto;border:none;border-radius:1rem;"
                  class="mb-2 border"
                >
                  <b-card-text >
                    <div class="text-center" >
                      <b-img style="margin: auto;width:60%" target="_blank" :src="client.logo" fluid></b-img>
                    </div>
                    <div class="clients__inner_description pt-2">
                    {{client.description[$props.lang]}}<br><br>
                    <ButtonComponent :text="clink[$props.lang]" variant="gray" :href="client.url"/>
                    </div>
                  </b-card-text>
                </b-card>
              </b-col>
            </b-row>
            <div class="text-center pt-4">
              <ButtonComponent :text="glink[$props.lang]" variant="secondary" href="/company_clients"/>
              <div class="note font-weight-lighter mt-3" style="font-size:1rem;">{{ note[$props.lang] }}</div>
            </div>
        </b-container>
    </section>
</template>

<script>
import buttonPrimry from '@/components/basic/buttonBasic.vue'
import ButtonComponent from '@/../../systemico.ui/components/buttons/ButtonComponent.vue';
export default {
  name: 'clients',
  components: {
    buttonPrimry,
    ButtonComponent
  },
  props: {
    lang: String
  },
  beforeMount(){
    if(this.lang === undefined){
      this.lang='es'
    }
  },
  data: function () {
    return {
      title: { es: 'Clientes con resultados éxitosos', en: 'Clients with successful results' },
      subtitle: {es: '¿Por qué ser nuestro cliente?', en: 'Why become our customer?'},
      note: {
        es: 'Aprende de otros clientes y sus casos de éxito.',
        en: 'Learn about other clients and their successful cases.'
      },
      description: {
        es: 'Nuestra experiencia es la clave para modernizar modelos de negocio o crear nuevas oportunidades en innovación. Queremos trabajar con tigo y conocer de tus proyectos. Tenemos un espacio reservado para ti.',
        en: 'Our experience is a key to improve your business and create new opportunities. We have a spot reserved for you.'
      },
      clink: { es:'Visitar Cliente >', en: 'Visit Client' },
      glink: { es:'Saber más >', en: 'Read more' },
      clients: [
        {
          id: 1, name: 'Cotrasur', logo: 'https://cdn.systemico.net/systemico/web/clients/client_1.png',
          description: {
            es: 'Empresa de Transporte de carga masiva en el ámbito Nacional e Internacional.',
            en: 'Cargo shipment company at the national and international level.'
          },
          url: 'https://cotrasur.com.co/'
        },
        {
          id: 2, name: 'Camacol', logo: 'https://cdn.systemico.net/systemico/web/clients/client_2.png',
          description: {
            es: 'Gremio que representa los intereses de la cadena de valor de la construcción, incidiendo eficazmente en las decisiones que conciernen al desarrollo y crecimiento del sector.',
            en: 'A union that represents the interests of the construction value chain, successfully influencing decisions that concern the development and growth of the sector.'
          },
          url: 'https://camacol.co/'
        },
        { id: 3, name: 'Fundación de la Mujer', logo: 'https://cdn.systemico.net/systemico/web/clients/client_3.png',
          description: {
            es: 'Organización comprometida con impulsar el liderazgo de las mujeres empresarias de la microempresa de bajo ingresos, facilitando el acceso a productos y servicios microfinancieros.',
            en: 'Organization committed to promoting the leadership of low-income women entrepreneurs in microbusinesses, facilitating access to microfinance products and services.'
          },
          url: 'https://portales.fundaciondelamujer.com/'
        },
        { id: 4, name: 'Cajasan', logo: 'https://cdn.systemico.net/systemico/web/clients/client_4.png',
          description: {
            es: 'Primera caja de compensación familiar en Santander (Colombia); una entidad sin ánimo de lucro que ofrece servicios y beneficios a empleadores y trabajadores, enfocados en satisfacer las necesidades de sus familias.',
            en: 'First family compensation fund in Santander (Colombia); a non-profit entity that offers services and benefits to employers and workers, focused on satisfying the needs of their families.'
          },
          url: 'https://cajasan.com' }
      ],
      configButton: { text: 'Crear cuenta gratis', class: 'button--primary' },

      configButton2: { text: 'Ingresar', class: 'button--secundary' }
    }
  },
  methods: {
    sinContenido: function () {
      this.$router.push({
        name: 'enConstruccion'
      })
    },
    ingresar: function () {
      window.location.href = '/login/'
    }
  }
}
</script>
<style lang="scss" scoped>
@import "../../../../systemico.ui/basic.scss";
.clients {
    width: 100%;
    padding: 2rem 0;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    background-color: #f8f8f8;
    overflow-x: hidden;
    min-height: 40rem;
    overflow: hidden;

    &__title {
        margin: 0 0 2rem;
        font-size: 2rem;
        font-weight: bold;
        color: $color-black;
        line-height: 2.1rem;
        text-align: center;
        text-transform: capitalize;

        .line {
            width: 2rem;
            height: 0.4rem;
            border-radius: 0.4rem;
            background-color: $color-orange;
            display: inline-block;
            position: relative;
            left: -0.5rem;
        }
    }

    &__subtitle {
        margin: 0 0 2rem;
        font-size: 2.2rem;
        font-weight: normal;
        color: $color-celeste;
        line-height: 2.3rem;
        text-align: center;
    }

    &__description {
        margin: 0 0 2rem;
        font-size: 1rem;
        font-weight: normal;
        color: $color-black;
        line-height: 2rem;
        text-align: center;
    }

    &__link {
        margin: 0;
        font-size: 1.4rem;
        font-weight: bold;
        color: $color-blue;
        line-height: 1.6rem;
        text-align: center;
    }

    &__opcions {
        margin-top: 1rem;

        & > :first-child {
            margin: 0 0 1rem 0;
        }
    }
    &__inner_description{
          font-size:1em;
          text-align: center;
        }
    // responsive para dispositivos de tablets en adelante
    @media (min-width: $breakpoint-tablet) {
        &__title {
            margin: 0 0 1rem;
            font-size: 2rem;
            line-height: 2.2rem;
            text-align: center;
        }

        &__subtitle {
            margin: 0 0 1rem;
            font-size: 1.5rem;
            line-height: 2rem;
            text-align: center;
        }

        &__description {
            margin: 0 0 3rem;
            font-size: 1.4rem;
            line-height: 1;
            text-align: center;
        }
        &__link {
            font-size: 1.3rem;
            line-height: 3;
            text-align: center;
        }
        &__opcions {
            display: flex;
            & > :first-child {
                margin: 0 2rem 0 0;
            }
        }
    }
}
</style>
